export enum KeyboardKey {
  Delete = "Delete",
  Backspace = "Backspace",
  Comma = ",",
  Enter = "Enter",
  ArrowUp = "ArrowUp",
  ArrowDown = "ArrowDown",
  Esc = "Esc", // for older browsers
  Escape = "Escape",
  Tab = "Tab",
}
