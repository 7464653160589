import { computed } from "mobx";
import moment from "moment";
import { DateTime } from "luxon";

import Entity from "lib/models/Entity";
import WorkingHour from "contexts/profile/models/WorkingHour";

type MapWorkingHours = {
  sunday?: WorkingHour;
  monday?: WorkingHour;
  tuesday?: WorkingHour;
  wednesday?: WorkingHour;
  thursday?: WorkingHour;
  friday?: WorkingHour;
  saturday?: WorkingHour;
};

export default class Profile extends Entity<ResProfile | null> {
  @computed get lawyerId() {
    return this.source?.lawyerId;
  }

  @computed get workingHours() {
    const { source } = this;
    if (!source) return {};
    const workingHours = source.workingHours;
    const offset = source.timezoneOffset;
    return ["sun", "mon", "tues", "wednes", "thurs", "fri", "satur"].reduce<
      MapWorkingHours
    >((acc, x) => {
      if (workingHours[`${x}dayStart`] && workingHours[`${x}dayEnd`]) {
        return {
          ...acc,
          [`${x}day`]: new WorkingHour(
            offset,
            parseHour(workingHours[`${x}dayStart`]),
            parseHour(workingHours[`${x}dayEnd`]),
          ),
        };
      }
      return acc;
    }, {});
  }

  @computed get todaySchedule(): WorkingHour | null {
    const day = moment()
      .utcOffset(this.source?.timezoneOffset || 0)
      .format("dddd");
    return this.workingHours[day.toLocaleLowerCase()] || null;
  }

  @computed get email() {
    return this.source?.email || "";
  }

  @computed get name() {
    const { source } = this;
    return {
      firstName: source?.firstName || "",
      middleName: source?.middleName || "",
      lastName: source?.lastName || "",
      fullName: [
        source?.firstName || "",
        source?.middleName || "",
        source?.lastName || "",
      ]
        .filter(Boolean)
        .join(" "),
    };
  }

  @computed get urlSlug() {
    const { source } = this;
    return source?.urlSlug || "";
  }

  @computed get profileImageUrl() {
    return this.source?.profileImageUrl || "";
  }

  @computed get originalProfileImageUrl() {
    return this.source?.originalProfileImageUrl || "";
  }

  @computed get phoneNumber() {
    const { source } = this;
    return {
      countryCode: source?.mobileCountry || "",
      subscriberNumber: source?.mobileNumber || "",
    };
  }

  @computed get firm() {
    const { source } = this;
    return {
      firmName: source?.firmName || "",
      addressLine1: source?.address.address1 || "",
      addressLine2: source?.address.address2 || "",
      city: source?.address.city || "",
      state: source?.address.state || "",
      zip: source?.address.zipcode || "",
      phoneNumber: source?.officeNumber || "",
    };
  }

  @computed get practiceAreas() {
    return this.source?.specialityTypes?.map(x => x.typeId) || [];
  }

  /**
   * Fee for a 15-min session.
   * NOTE: Server sends fee for an hour.
   */
  @computed get fee() {
    return String((this.source?.hourlyRate || 0) / 4);
  }

  @computed get timeZoneName() {
    return this.source?.timezoneName || "";
  }

  @computed get timeZoneOffset() {
    return this.source?.timezoneOffset || 0;
  }

  /**
   * Does lawyer's saved offset match current offset?
   */
  @computed get isTimeZoneOffsetCorrect() {
    const currentOffset =
      DateTime.local().setZone(this.timeZoneName).offset / 60;
    return this.timeZoneOffset === currentOffset;
  }

  @computed get licenses() {
    return (
      this.source?.licences.map(license => ({
        state: license.state,
        status: license.status,
        acquired: license.acquiredYear,
        updatedDate: license.updatedDate.split("-")[0],
      })) || []
    );
  }

  @computed get schools() {
    return (
      this.source?.schools.map(school => ({
        name: school.schoolName,
        degree: school.degree,
        graduation: school.graduateYear,
      })) || []
    );
  }

  @computed get careers() {
    return (
      this.source?.careers.map(career => {
        const start = moment(career.startDate);
        const end = moment(career.endDate);
        return {
          name: career.companyName,
          title: career.title,
          from: { year: start.year(), month: start.month() },
          to: career.endDate
            ? { year: end.year(), month: end.month() }
            : null,
          present: career.isPresent,
        };
      }) || []
    );
  }

  @computed get website() {
    return this.source?.website || "";
  }

  @computed get languages() {
    return this.source?.languages || [];
  }

  @computed get aboutYou() {
    return this.source?.aboutYou || "";
  }
}

/**
 * Parses given hour string.
 *   - Assume given hour string starts with `HH` format: 02, 11, 24.
 *
 * @param hour String that starts with 2 numbers.
 * @example
 * parseHour("05:00"); // => 5
 */
function parseHour(hour: string) {
  return Number(hour.slice(0, 2));
}
