import { action, observable, autorun } from "mobx";

import RootStore from "app/RootStore";
import Store from "lib/models/Store";
import { PaginationConfigRequest } from "lib/models/LoadablePageableData";
import { PushStreamType } from "lib/enums";

import NotificationService from "./NotificationService";
import { LoadablePageableNotificationList } from "./models";

export default class NotificationStore extends Store {
  private notificationService: NotificationService;
  @observable
  public dataListNotificationLatest = new LoadablePageableNotificationList({
    items: [],
    pageNumber: 1,
    pageSize: 5,
    totalCount: 0,
  });
  @observable
  public dataListNotification = new LoadablePageableNotificationList({
    items: [],
    pageNumber: 1,
    pageSize: 6,
    totalCount: 0,
  });
  @observable public dataNumUnreadNotification: number;
  @observable public latestPushNotification: ResNotification | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.notificationService = new NotificationService(rootStore.authStore);
    autorun(async () => {
      if (this.rootStore.authStore.isLoggedIn) {
        // FETCH UNREAD NOTIFICATION WHEN TOKEN IS VALID
        this.syncNotificationLatest();
      }

      // DETECT PUSH FROM SERVER
      if (this.rootStore.streamStore.latestStream) {
        if (
          this.rootStore.streamStore.latestStream.eventType ===
          PushStreamType.NewNotification
        ) {
          await this.syncNotificationLatest();
          const latestPushNotification = this.dataListNotificationLatest
            .data[0];
          this.latestPushNotification = latestPushNotification;
          // CREATE A NEW BROWSER NOTIFICATION
          this.createNewBrowserNotification("New Notification", {
            body: latestPushNotification.contents,
            icon: "/assets/favicon-32x32.png",
          });
        }
      }
    });
  }

  /**
   * Requests permission for browser Notification, only when permission is set to "default"
   *
   * @memberof NotificationStore
   */
  public async requestBrowserNotificationPermission() {
    if (!("Notification" in window)) {
      // NOTIFICATION NOT SUPPORTED
    } else if (Notification.permission === "granted") {
    } else if (Notification.permission !== "denied") {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        new Notification("Notification Enabled", {
          body:
            "Thank you for enabling Notifications. We will notify you of any new notifications that may require your attention.",
          icon: "/assets/favicon-32x32.png",
        });
      }
    }
  }

  /**
   * Creates a simple browser notification that focuses the window when clicked.
   *
   * @param {string} title
   * @param {NotificationOptions} options
   * @memberof NotificationStore
   */
  public createNewBrowserNotification(
    title: string,
    options: NotificationOptions,
  ) {
    if (Notification.permission === "granted") {
      var notification = new Notification(title, options);
      notification.onclick = () => {
        window.focus();
      };
    }
  }

  /**
   * Sync client's notification UI's with the latest data from the server
   *
   * @memberof NotificationStore
   */
  @action.bound
  public async syncNotificationLatest() {
    // FETCH THE LATEST NOTIFICATION FOR THE POPUP
    await this.readDataListNotificationLatest();
    // FETCH THE LATEST NUMBER OF UNREAD NOTIFICATIONs FOR THE POPUP BADGE
    await this.readDataNumUnreadNotification();
  }

  @action.bound
  public async updateSingleNotificationRead(notificationId: string) {
    try {
      await this.notificationService.postNotificationRead(notificationId);
      // TODO: SIMPLY RE-FETCHING FOR NOW. THERE IS ROOM FOR OPTIMIZATION
      this.syncNotificationLatest();
    } catch (e) {}
  }

  @action.bound
  public async updateAllNotificationRead() {
    try {
      await this.notificationService.postNotificationReadAll();
      // TODO: SIMPLY RE-FETCHING FOR NOW. THERE IS ROOM FOR OPTIMIZATION
      this.readDataListNotification();
      // TODO: IS THIS SAFE?
      this.dataNumUnreadNotification = 0;
    } catch (e) {}
  }

  /**
   * Retrieve the latest number of unread notifications
   *
   * @memberof NotificationStore
   */
  @action.bound
  public async readDataNumUnreadNotification() {
    try {
      const data = await this.notificationService.getListNotification({
        pageNumber: 1,
        pageSize: 1,
        unRead: true,
      });
      this.dataNumUnreadNotification = data.totalCount;
    } catch (e) {}
  }

  @action.bound
  public async readDataListNotification(
    config: ReqNotificationList = {
      pageSize: this.dataListNotification.currentConfig.pageSize,
      pageNumber: this.dataListNotification.currentConfig.pageNumber,
    },
  ) {
    try {
      this.dataListNotification.setIsLoading();
      const data = await this.notificationService.getListNotification(config);
      if (config.unRead) {
        this.dataNumUnreadNotification = data.totalCount;
      }
      this.dataListNotification.updatePageableData(data);
    } catch (e) {}
  }

  // FETCH DATA FOR THE NOTIFICATION POPUP VIEW
  @action.bound
  public async readDataListNotificationLatest(
    config: PaginationConfigRequest = {
      pageSize: this.dataListNotificationLatest.currentConfig.pageSize,
      pageNumber: this.dataListNotificationLatest.currentConfig.pageNumber,
    },
  ) {
    try {
      this.dataListNotificationLatest.setIsLoading();
      const data = await this.notificationService.getListNotification(config);
      this.dataListNotificationLatest.updatePageableData(data);
    } catch (e) {}
  }
}
