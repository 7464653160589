import React, { useState, useEffect, useCallback } from "react";

import { useStores } from "app";
import { PageLoading } from "components/Layout";

/**
 * Blocks rendering while determining user authentication state.
 */
const CheckAuth: React.FC = ({ children }) => {
  const { authStore } = useStores();
  const [checked, setChecked] = useState(false);

  const authenticate = useCallback(async () => {
    const user = localStorage.getItem("user");
    if (user) {
      const { token }: ResUser = JSON.parse(user);
      await authStore.loginWithToken(token);
    }
    setChecked(true);
  }, []);

  useEffect(() => {
    authenticate();
  }, []);

  if (!checked) {
    return <PageLoading />;
  }

  return <>{children}</>;
};

export default CheckAuth;
