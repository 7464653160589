/**
 * Case Inquiry Inquiry Type
 *
 * @export
 * @enum {number}
 */
export enum InquiryType {
  CaseNormalProcessingTime = "Outside Normal Processing Time",
  DidNotReceiveNoticeByMail = "Non-Delivery of Notice",
  DidNotReceiveCardByMail = "Non-Delivery of Card",
}
