import { camelCase, snakeCase } from "change-case";
import { action, autorun, computed, extendObservable, observe } from "mobx";

import { SortDirection } from "lib/enums";

export interface ValidationHelpers {
  [key: string]: (v: any) => Promise<boolean>;
}

export default class Form {
  [key: string]: any;

  public fields: { [key: string]: string };
  public rules: { [key: string]: Array<(v: any, f?: any) => Promise<string>> };
  public errors: { [key: string]: string | undefined };
  public touched: { [key: string]: boolean };
  public debounces: { [key: string]: number };
  public validationHelpers?: ValidationHelpers;
  public searchableFields: string[] = [];
  public sortableFields: string[] = [];

  // store default values
  private defaultValues: { [key: string]: any };

  constructor(defaultValues?: any, validationHelpers?: ValidationHelpers) {
    this.errors = {};
    this.touched = {};
    this.validationHelpers = validationHelpers;

    /*
     *   1. this.defaultValues
     *      contains values initialized by @defaultValue decorator
     *
     *   2. defaultValues
     *      contains values supplied as argument during contruction
     *
     *   for our purpose
     *   we merge the two instances to produce
     *   final default values instance stored at
     *   this.defaultValues
     */
    this.defaultValues = {
      ...(this.defaultValues || {}),
      ...(defaultValues || {}),
    };

    for (const key of Object.keys(this.fields)) {
      const debounceTime = this.debounces ? this.debounces[key] : 0;
      extendObservable(this, {
        [key]: undefined,
      });
      extendObservable(this.errors, {
        [key]: undefined,
      });
      extendObservable(this.touched, {
        [key]: false,
      });
      autorun(
        () => {
          this.validateField(key).then(x => this.updateError(key, x));
        },
        { delay: debounceTime },
      );
    }

    // Update form fields with default values
    if (!!this.defaultValues) {
      this.update(this.defaultValues);
    }

    for (const key of Object.keys(this.fields)) {
      observe(this, key, () => this.touch(key));
    }
  }

  public validate(): Promise<boolean> {
    const promises = [];
    for (const key of Object.keys(this.fields)) {
      this.touch(key);
      promises.push(
        this.validateField(key).then(x => this.updateError(key, x)),
      );
    }
    return Promise.all(promises).then(() => {
      for (const key of Object.keys(this.fields)) {
        if (this.errors[key] !== undefined) {
          return false;
        }
      }
      return true;
    });
  }

  @computed
  public get hintType(): { [key: string]: "positive" | "negative" } {
    const getType = (
      touched: boolean,
      error: string | undefined,
      isEmpty: boolean,
    ) => {
      if (!touched) {
        return undefined;
      }
      if (!error && isEmpty) {
        return undefined;
      }
      return error ? "negative" : "positive";
    };
    return Object.keys(this.errors).reduce(
      (prev, key) => ({
        ...prev,
        [key]: getType(
          this.touched[key],
          this.errors[key],
          this[key] === undefined ||
            this[key] === null ||
            this[key] === "" ||
            this[key] === [],
        ),
      }),
      {},
    );
  }

  @computed
  public get hintText(): { [key: string]: string } {
    return Object.keys(this.errors).reduce(
      (prev, key) => ({
        ...prev,
        [key]:
          this.errors[key] ||
          (this[key] === undefined ||
          this[key] === null ||
          this[key] === "" ||
          this[key] === []
            ? ""
            : `올바른 ${this.fields[key]}입니다.`),
      }),
      {},
    );
  }

  @computed
  public get invalidFieldNames(): string {
    return Object.keys(this.errors)
      .filter(key => this.errors[key])
      .map(key => this.fields[key])
      .join(", ");
  }

  @computed
  public get values() {
    return Object.keys(this.fields).reduce(
      (prev, field) => ({ ...prev, [field]: this[field] }),
      {},
    );
  }

  @computed
  get dirty(): boolean {
    return Object.values(this.touched).filter(field => field).length > 0;
  }

  @action.bound
  public update(changes: any): void {
    for (const key of Object.keys(changes)) {
      if (this.fields[key] === undefined) {
        throw new Error(`Cannot find the key in the form: ${key}`);
      }
      this[key] = changes[key];
    }
  }

  @action.bound
  public touchAll(): void {
    Object.keys(this.touched)
      .filter(field => !this.touched[field])
      .forEach(field => (this.touched[field] = true));
  }

  @action.bound
  public initialize(): void {
    Object.keys(this.touched)
      .filter(field => this.touched[field])
      .forEach(field => (this.touched[field] = false));
  }

  /**
   * Resets form to its default values
   *
   * @memberof Form
   */
  @action.bound
  public reset(defaultValues?: { [key: string]: any }): void {
    this.defaultValues = {
      ...(this.defaultValues || {}),
      ...(defaultValues || {}),
    };
    Object.keys(this.fields).forEach(
      field => (this[field] = this.defaultValues[field] || undefined),
    );
    this.initialize();
  }

  @action.bound
  public initializeField(key: string): void {
    if (this.fields[key] === undefined) {
      throw new Error(`Cannot find the key in the form: ${key}`);
    }
    this[key] = undefined;
    this.touched[key] = false;
  }

  @action.bound
  public updateFields(fields: Array<{ key: string; sortable?: boolean }>) {
    this.searchableFields = fields.map(({ key }) => key);
    this.sortableFields = fields
      .filter(({ sortable }) => !!sortable)
      .map(({ key }) => key);
  }

  private validateField(key: string): Promise<string | undefined> {
    const promises = [];
    if (this.rules && this.rules[key]) {
      const rules = this.rules[key];
      for (const rule of rules) {
        promises.push(rule(this[key], this));
      }
    }
    return Promise.all(promises).then(values => {
      for (const v of values) {
        if (v !== undefined) {
          return v;
        }
      }
      return undefined;
    });
  }

  @action.bound
  private updateError(key: string, error?: string): void {
    this.errors[key] = error;
  }

  @action.bound
  private touch(key: string): void {
    this.touched[key] = true;
  }

  @computed
  get query() {
    const { sortBy, sortDirection, ...rest } = this.fields;
    const queries = {
      sort: this.sortQuery,
    };
    Object.keys(rest).forEach(key => !!this[key] && (queries[key] = this[key]));
    return queries;
  }

  @computed
  get sortQuery(): string {
    return this.sortBy
      ? [
          snakeCase(this.sortBy),
          snakeCase(SortDirection[this.sortDirection]),
        ].join(".")
      : "";
  }
}
