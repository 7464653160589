const withPostposition = (x: string): string => {
  const code = x.charCodeAt(x.length - 1);

  if (0xac00 <= code && code <= 0xd7a3) {
    if ((code - 0xac00) % 28 > 0) {
      return `${x}은`;
    }
    return `${x}는`;
  }
  return x;
};

export default function factory(
  defaultMessage: string,
  customMessage: string | undefined,
  rule: (x: any, f?: any) => Promise<boolean>,
  ruleForEmpty?: (x: any, f?: any) => Promise<boolean> | boolean,
  debounce?: number,
) {
  return (target: any, key: string) => {
    if (target.rules === undefined) {
      target.rules = {};
    }

    if (target.rules[key] === undefined) {
      target.rules[key] = [];
    }

    if (target.debounces === undefined) {
      target.debounces = {};
    }

    if (target.debounces[key] === undefined) {
      target.debounces[key] = 0;
    }

    if (target.debounces[key] < (debounce || 0)) {
      target.debounces[key] = debounce;
    }

    target.rules[key].push((x: any, form: any) => {
      const isEmpty = x === undefined || x === null || x === "";
      let ruleCheck: Promise<boolean>;
      if (isEmpty && ruleForEmpty) {
        const isEmptyRulePromise = !!(ruleForEmpty(x, form) as boolean &
          Promise<boolean>).then;
        ruleCheck = isEmptyRulePromise
          ? (ruleForEmpty(x, form) as Promise<boolean>)
          : Promise.resolve(ruleForEmpty(x, form));
      } else if (isEmpty) {
        ruleCheck = Promise.resolve(true);
      } else {
        ruleCheck = rule(x, form);
      }

      return ruleCheck.then(res => {
        if (res) {
          return undefined;
        }

        if (customMessage) {
          return customMessage;
          // currently remove field name
          // } else if (target.fields && target.fields[key]) {
          //   return `${withPostposition(target.fields[key])}: ${defaultMessage}`;
        }
        return defaultMessage;
      });
    });
  };
}
