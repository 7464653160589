import styled from "styled-components";
import React from "react";

import MuiFormControl from "@material-ui/core/FormControl";
import MuiFormControlLabel from "@material-ui/core/FormControlLabel";
import MuiRadio from "@material-ui/core/Radio";
import MuiRadioGroup, {
  RadioGroupProps as MuiRadioGroupProps,
} from "@material-ui/core/RadioGroup";

import { SelectOption } from "./SelectOption";

const RadioGroupWrapper = styled(MuiRadioGroup)<{ horizontal?: boolean }>`
  && {
    display: flex;

    ${({ horizontal }) =>
      horizontal
        ? `
        flex-direction: row;

        > label {
          margin-right: 50px;
        }
      `
        : ""}
  }
`;

export const Radio = styled(props => (
  <MuiRadio
    {...props}
    color="primary" // if ommitted, applies both primary and secondary colors
    disableRipple
    disableFocusRipple
    disableTouchRipple
    classes={{ colorPrimary: "color-primary", checked: "color-checked" }}
  />
))`
  &&& {
    font-size: 14px;
    line-height: 1.43;

    &.color-checked {
      color: ${({ theme }) => theme.primary};
    }

    &.color-primary:hover {
      background-color: transparent; // disable hover style
    }
  }
`;

interface RadioGroupProps extends MuiRadioGroupProps {
  options: SelectOption[];
  horizontal?: boolean;
}

const RadioGroup = ({ options, horizontal, ...props }: RadioGroupProps) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      const { value } = event.target;
      props.onChange(event, value);
    }
  };

  return (
    <MuiFormControl component="fieldset">
      <RadioGroupWrapper horizontal={horizontal}>
        {options.map(({ label, value: optionValue }) => (
          <MuiFormControlLabel
            key={optionValue}
            value={optionValue}
            control={
              <Radio
                checked={props.value === optionValue}
                onChange={onChange}
              />
            }
            label={label}
          />
        ))}
      </RadioGroupWrapper>
    </MuiFormControl>
  );
};

export default RadioGroup;
