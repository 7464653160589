import styled from "styled-components";
import React from "react";

import MuiDialog, {
  DialogProps as MuiDialogProps,
} from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiIconButton from "@material-ui/core/IconButton";
import MuiTypography from "@material-ui/core/Typography";

import { Icon, IconName } from "components/Icons";

const ModalWrapper = styled((props: MuiDialogProps) => (
  <MuiDialog
    PaperProps={{
      classes: {
        root: "modal-paper-root",
      },
    }}
    // pass down or override props
    {...props}
  />
))`
  &&& {
    .modal-paper-root {
      width: 550px;
    }

    .modal-title-root {
      padding: 20px 30px;
      border-bottom: 1px solid ${({ theme }) => theme.gray1};
    }
  }
`;

export const ModalTitle = styled(({ children, onClose, ...props }) => (
  <MuiDialogTitle
    disableTypography
    classes={{
      root: "modal-title-root",
    }}
    // pass down or override props
    {...props}
  >
    <MuiTypography
      variant="h6"
      classes={{ root: "modal-title-typography-root" }}
    >
      {children}
    </MuiTypography>
    {onClose && (
      <MuiIconButton
        aria-label="close"
        onClick={onClose}
        classes={{ root: "modal-title-button-root" }}
      >
        <Icon name={IconName.ModalClose} width="24" height="24" />
      </MuiIconButton>
    )}
  </MuiDialogTitle>
))`
  &&& {
    margin: 0;

    .modal-title-typography-root {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.5;
    }

    .modal-title-button-root {
      position: absolute;
      right: 18px;
      top: 12px;
    }
  }
`;

export const ModalContent = styled(props => (
  <MuiDialogContent
    // pass down or override props
    {...props}
  />
))`
  &&& {
    padding: 30px;
    max-height: calc(100vh - 240px);
  }
`;

export const ModalActions = styled(props => (
  <MuiDialogActions
    // pass down or override props
    {...props}
  />
))`
  &&& {
    padding: 15px 30px;
  }
`;

interface ModalProps extends MuiDialogProps {
  children: React.ReactNode;
  onClose: () => void;
}

export default function Modal(
  props: ModalProps,
): React.FunctionComponentElement<HTMLElement> {
  const { onClose, children } = props;
  return (
    <ModalWrapper
      open
      onClose={onClose}
      disableBackdropClick // prevent from accidental closure when entering data
      disableEscapeKeyDown // prevent from accidental closure especially when some form components use ESC for canceling input (User can potentially press it multiple times)
      {...props}
    >
      {children}
    </ModalWrapper>
  );
}
