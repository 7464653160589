import { Form, field, required, requiredIf } from "lib/form";

export default class FormAddress extends Form {
  @field("Street Number and Name")
  @required()
  public streetNumberAndName: string;

  @field("Select")
  public detailAddressType: string;

  @field("Apartment, Suite, Floor Number")
  public detailAddress: string;

  @field("City or Town")
  @required()
  public cityOrTown: string;

  @field("State")
  @requiredIf(form => form.country === "USA")
  public state: string;

  @field("Zip Code")
  @requiredIf(form => form.country === "USA")
  public zipCode: string;

  @field("Province")
  public province: string;

  @field("Postal Code")
  @requiredIf(form => form.country !== "USA")
  public postalCode: string;

  @field("Country")
  @required()
  public country: string;
}
