/**
 * CaseStatus
 *
 * @export
 * @enum {number}
 */
export enum CaseStatus {
  PreSubmission = "Pre-Submission",
  Submitted = "Submitted",
  Closed = "Closed",
}
