import factory from "./factory";

interface ArrayLengthProps {
  customMessage?: string;
  minLength?: number;
  maxLength?: number;
  bypass?: (form: any) => boolean;
}

export default function arrayLength(props: ArrayLengthProps = {}) {
  const {
    minLength = 0,
    maxLength = Number.MAX_SAFE_INTEGER,
    customMessage,
    bypass,
  } = props;
  const defaultMessage = [
    "Please select",
    minLength !== 0
      ? `at least ${minLength}${!!maxLength ? " or more" : ""}`
      : "",
    maxLength !== Number.MAX_SAFE_INTEGER
      ? `up to ${maxLength} ${!!minLength ? " or less" : ""}`
      : "",
  ]
    .filter(x => x)
    .join(" ");
  const rule = (x: any[], form: any) =>
    Promise.resolve(
      bypass && bypass(form)
        ? true
        : x.length >= minLength && x.length <= maxLength,
    );
  return factory(defaultMessage, customMessage, rule);
}
