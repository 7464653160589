import { useEffect, useCallback } from "react";
import qs, { stringify } from "query-string";
import { useStores } from "app";
import { useLocation } from "react-router-dom";
import { snakeCase } from "change-case";

/**
 * Syncs a configuration interface / requests to the URL
 *
 * @export
 * @template T
 * @param {T} config
 * @param {(config: T) => void} requestCb
 * @param {boolean} [activateBool=true]
 * @returns
 */
export default function useSyncRequestUrl<T extends any>(
  config: T,
  requestCb: (config: T) => void,
  activateBool: boolean = true,
) {
  const { routerStore } = useStores();
  const { search, pathname } = useLocation();

  useEffect(() => {
    if (activateBool) {
      const searchConfig = qs.parse(search, {
        parseBooleans: true,
        parseNumbers: true,
      });
      if (Object.keys(searchConfig).length === 0) {
        // PUSH CONFIG TO URL
        routerStore.replace(pushUrl(pathname, config));
      } else {
        routerStore.replace(pushUrl(pathname, (searchConfig as any) as T));
      }
    }
  }, [activateBool, pathname]);

  useEffect(() => {
    const searchConfig = qs.parse(search);
    if (Object.keys(searchConfig).length > 0) {
      requestCb((searchConfig as any) as T);
    }
  }, [search]);

  const pushUrl = useCallback(
    (pathname: string, config: T) => {
      return `${pathname}?${Object.keys(config).reduce(
        (acc, curr: string) =>
          `${acc ? `${acc}&` : ""}${stringify({
            [snakeCase(curr)]: config[curr],
          })}`,
        "",
      )}`;
    },
    [pathname],
  );

  const onChangeConfig = useCallback(
    (config: T) => {
      const searchConfig = qs.parse(search, {
        parseBooleans: true,
        parseNumbers: true,
      });

      routerStore.push(
        pushUrl(pathname, ({
          ...searchConfig,
          // SEARCH CONFIG KEY: snake_case
          // CONFIG KEY: camelCase
          ...Object.keys(config).reduce(
            (acc, curr) => ({ ...acc, [snakeCase(curr)]: config[curr] }),
            {},
          ),
        } as any) as T),
      );
    },
    [pathname, search],
  );

  return onChangeConfig;
}
