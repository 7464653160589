import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import MuiTextField, { TextFieldProps } from "@material-ui/core/TextField";
import MuiBox from "@material-ui/core/Box";

import { KeyboardKey } from "lib/enums";

const Wrapper = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  width: ${props => (props.fullWidth ? "100%" : "auto")};
`;

const TextField = styled(MuiTextField)`
  & input {
    cursor: pointer;
  }
`;

export type EditableTextFieldProps = TextFieldProps & {
  onSave: (value: string) => void;
};

/**
 * Component that keeps its own text value before the user decides to
 * commit (onSave) or cancel the modified content.
 *
 * @param {EditableTextFieldProps} props
 */
const EditableTextField = (props: EditableTextFieldProps) => {
  /* States */
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(props.value);
  const inputRef = useRef<HTMLInputElement>(null);
  const outerRef = useRef<HTMLDivElement>(null);

  /* Effects */
  // Detects Editing state and changes input state accordingly
  useEffect(() => {
    if (isEditing) {
      // automatically focus on input on edit
      setFocusInput();
    } else {
      // automatically blur on input on cancel edit
      setBlurInput();
    }
  });

  // Adds event listeners to detect key input
  useEffect(() => {
    function handleKeyUp(event: KeyboardEvent) {
      if (isEditing) {
        switch (event.key) {
          case KeyboardKey.Escape:
          case KeyboardKey.Esc:
            handleOnCancel();
            break;
          case KeyboardKey.Enter:
            handleOnSave();
            break;
          default:
        }
      }
    }

    window.addEventListener("keyup", handleKeyUp);
    return () => window.removeEventListener("keyup", handleKeyUp);
  });

  // Adds event listeners to detect clicks away from the element
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClickAway);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickAway);
    };
  }, [props.value]);

  /* Handlers */
  // Helps setting up call to isEditing state updates
  const handleSetIsEditing = (newIsEditing: boolean) => () => {
    setIsEditing(newIsEditing);
  };

  // Handles when user decides to commit edit content
  const handleOnSave = () => {
    setIsEditing(false);
    props.onSave(text as string);
  };

  // Handles when user decides to cancel edit content
  const handleOnCancel = () => {
    // Reset value
    setText(props.value);
    setIsEditing(false);
  };

  // Handles changes to internal text state (temp value while user changes text)
  const handleOnSetText = (e: React.ChangeEvent<HTMLInputElement>) =>
    setText(e.target.value);

  // Deactivates edit view when clicked away
  const handleClickAway = (e: MouseEvent) => {
    if (outerRef.current) {
      if (outerRef.current.contains(e.target as Node)) {
        // inside click
        return;
      }
      // outside click
      handleOnCancel();
    }
  };

  // Sets blur on input element
  const setBlurInput = () => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  // Sets focus on input element
  const setFocusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const { onSave, ...textFieldProps } = props;

  return (
    <Wrapper ref={outerRef} fullWidth={textFieldProps.fullWidth}>
      <MuiBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        padding="10px"
        width={textFieldProps.fullWidth ? "100%" : "auto"}
      >
        <TextField
          {...(textFieldProps as TextFieldProps)}
          onChange={handleOnSetText}
          value={text}
          onClick={props.disabled ? undefined : handleSetIsEditing(true)}
          hiddenLabel
          margin="dense"
          inputRef={inputRef}
        />
      </MuiBox>
      {isEditing ? (
        <MuiBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          padding="10px"
        >
          <button onClick={handleOnSave} disabled={props.disabled}>
            Save
          </button>
          <button onClick={handleOnCancel} disabled={props.disabled}>
            Cancel
          </button>
        </MuiBox>
      ) : (
        <MuiBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          padding="10px"
        >
          <button onClick={handleSetIsEditing(true)} disabled={props.disabled}>
            Edit
          </button>
        </MuiBox>
      )}
    </Wrapper>
  );
};

export default EditableTextField;
