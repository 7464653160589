import { Provider, inject } from "mobx-react";
import { match, Route, RouteProps, Switch } from "react-router-dom";
import * as React from "react";

import { PageStore, Configuration } from "app";
import { PageLoading } from "components/Layout";
import NotFound from "components/NotFound";

import { createCommonStore } from "contexts/common";

import UserStore from "./UserStore";
import UserService from "./UserService";

const UserList = React.lazy(() => import("./views/UserList"));

interface RoutesProps {
  parentPath: string;
  match: match<{}>;
}

interface InjectedProps extends RoutesProps {
  apiConfig: Configuration;
  pageStore: PageStore;
}

@inject("apiConfig", "pageStore")
class Routes extends React.Component<RoutesProps> {
  get injectedProps() {
    return this.props as InjectedProps;
  }

  private stores = {};

  constructor(props: RoutesProps) {
    super(props);
    const { apiConfig, pageStore } = this.injectedProps;

    const userService = new UserService(apiConfig);
    const userStore = new UserStore(pageStore, userService);
    const commonStore = createCommonStore(pageStore, apiConfig);

    this.stores = {
      userStore,
      commonStore,
    };
  }

  public render() {
    const matchedUrl = this.props.match.url;
    return (
      <React.Suspense fallback={<PageLoading />}>
        <Provider {...this.stores}>
          <Switch>
            <Route exact path={matchedUrl} render={this.renderUserList} />
            <Route component={NotFound} />
          </Switch>
        </Provider>
      </React.Suspense>
    );
  }

  private renderUserList = (routerProps: RouteProps) => (
    <UserList {...routerProps} />
  );
}

export default Routes;
