import React from "react";

import UnsupportedBrowser from "./UnsupportedBrowser";
import { isSupportedBrowser } from "lib/utils";

/**
 * Blocks unsupported browsers.
 *
 * Supported browsers are:
 *   - Google Chrome
 */
export default function CheckBrowser(props: { children: React.ReactNode }) {
  if (isSupportedBrowser()) {
    return <>{props.children}</>;
  }
  return <UnsupportedBrowser />;
}
