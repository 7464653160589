// TYPES OF PUSH STREAMS SENT FROM SERVER
export enum PushStreamType {
  NewNotification = "NEW_NOTIFICATION",
  ClientJoined = "CLIENT_JOINED",
  ClientExited = "CLIENT_EXITED",
  ConsultationEnded = "CONSULTATION_ENDED",
  StatusChanged = "STATUS_CHANGED",
  DisconnectExistingConnection = "DISCONNECT_EXISTING_CONNECTION",
  //...
}
