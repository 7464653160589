import { Helmet as ReactHelmet } from "react-helmet";
import * as React from "react";

interface HelmetProps {
  title?: string;
}

const Helmet: React.SFC<HelmetProps> = (props: HelmetProps) => (
  <ReactHelmet>
    <title>{props.title && `${props.title} :: `}Lawfully</title>
  </ReactHelmet>
);

export default Helmet;
