import Service from "lib/models/Service";

import { FormTypeSource } from "../models/FormType";

export default class FormTypeService extends Service {
  /**
   * Get list of form type
   *
   * @returns {Promise<FormType[]>}
   * @memberof FormTypeService
   */
  public async readListFormType(): Promise<FormTypeSource[]> {
    try {
      const res = await this.get("/form-types");
      return res;
    } catch (e) {
      throw e;
    }
  }
}
