import { computed } from "mobx";
import moment from "moment";

import LoadableData from "lib/models/LoadableData";
import { ConsultationRequestStatus } from "lib/enums/Consultations";

export default class LoadableRequestList extends LoadableData<
  ResConsultationRequest[]
> {
  @computed
  public get dataAllByDate() {
    return (
      this.data
        .slice()
        // TODO: REMOVE SORT ONCE GUARANTEED BY SERVER REQUEST
        .sort(
          (a, b) => moment(a.startAt).valueOf() - moment(b.startAt).valueOf(),
        )
    );
  }

  @computed
  public get dataPendingByDate() {
    return this.dataAllByDate.filter(
      request => request.status === ConsultationRequestStatus.Requested,
    );
  }

  @computed
  public get dataBookedByDate() {
    return this.dataAllByDate.filter(
      request =>
        request.status === ConsultationRequestStatus.Confirmed ||
        request.status === ConsultationRequestStatus.ConsultationOpen ||
        request.status === ConsultationRequestStatus.LawyerReady ||
        request.status === ConsultationRequestStatus.ConsultationStarted,
    );
  }
}
