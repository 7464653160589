import React, { useCallback, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import styled from "styled-components";
import MuiBox from "@material-ui/core/Box";
import MuiTypography from "@material-ui/core/Typography";
import isEmail from "validator/lib/isEmail";
import { useStores } from "app";
import {
  Helmet,
  Stack,
  PageLayout,
  PageLayoutTitle,
  PageLayoutBody,
  PageHeader,
} from "components/Layout";
import { TextField } from "components/Form";
import { Button } from "components/Button";

export default function ForgotPassword() {
  const { authStore, pageStore, routerStore } = useStores();
  const { control, errors, formState, handleSubmit } = useForm<
    FormForgotPassword
  >();
  /**
   * Handles form submit.
   */
  const handleOnSubmit = useCallback(
    handleSubmit(async (data: FormForgotPassword) => {
      await authStore.submitResetPassword(data.email);
    }),
    [],
  );

  // Block logged-in users.
  useEffect(() => {
    if (authStore.isLoggedIn) {
      pageStore.openAlert({
        message: "You are already logged in.",
        isError: true,
        onConfirm: () => routerStore.push("/"),
      });
    }
  }, []);

  return (
    <PageLayout>
      <Helmet title="Forgot Password" />

      <PageLayoutBody>
        <MuiBox width="310px">
          {/* Header */}
          <MuiBox pb={6}>
            <MuiBox pb="16px">
              <PageLayoutTitle>Forgot password?</PageLayoutTitle>
            </MuiBox>
            <MuiTypography variant="body1">
              Enter the email address you used and <br />
              we will send a new password to your email.
            </MuiTypography>
          </MuiBox>

          <MuiBox pb={8} component="form" onSubmit={handleOnSubmit}>
            <Stack spacing={6}>
              <Controller
                as={SanitizedTextField}
                control={control}
                name="email"
                defaultValue=""
                placeholder="Email"
                fullWidth
                autoFocus
                rules={{
                  required: true,
                  validate: val => isEmail(val) || "Invalid email",
                }}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
              <Button
                type="submit"
                width={310}
                disabled={
                  formState.isSubmitting ||
                  (formState.isSubmitted && !formState.isValid)
                }
              >
                Send Email
              </Button>
            </Stack>
          </MuiBox>
        </MuiBox>
      </PageLayoutBody>
    </PageLayout>
  );
}

const SanitizedTextField = styled(TextField)`
  &&& {
    margin-bottom: 0;
  }
`;
