import { useState, useEffect } from "react";

// **borrowed** and modified from https://usehooks.com/useOnScreen/
export default function useOnScreen(
  ref: React.MutableRefObject<HTMLElement | undefined | null>,
  rootMargin = "0px",
) {
  // State and setter for storing whether element is visible

  const [isIntersecting, setIntersecting] = useState<boolean | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires

        setIntersecting(entry.isIntersecting);
      },

      {
        rootMargin,
      },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting;
}
