import Service from "lib/models/Service";

import { FormSignUp } from "contexts/auth";

export default class AuthService extends Service {
  public async login(credentials: ReqLogin): Promise<ResUser> {
    try {
      const res = await this.post("/lawyers/login", {
        email: credentials.email,
        password: credentials.password,
      });
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Refreshes given authentication token.
   * A token will be valid for 24 hours from the time it is issued.
   *
   * @param {string} token JWT
   * @returns {ResUser} User response
   */
  public async refreshToken(token: string): Promise<ResUser> {
    try {
      return this.post("/lawyers/refresh-token", { token });
    } catch (e) {
      throw e;
    }
  }

  public async signUp(req: FormSignUp): Promise<boolean> {
    try {
      const res = await this.post("/users", {
        email: req.email,
        password: req.password,
        platform: req.platform,
      });
      return res;
    } catch (e) {
      throw e;
    }
  }

  public async updatePassword(data: {
    email: string;
    oldPassword: string;
    newPassword: string;
  }) {
    try {
      await this.post("/lawyers/change-password", data);
    } catch (e) {
      throw e;
    }
  }

  /**
   * Creates a new password for a given user.
   *
   * @param {string} token
   * @param {string} password
   */
  public async createSetPassword(token: string, password: string) {
    const res = await this.post("/lawyers/set-new-password", {
      token,
      password,
    });
    return res;
  }

  /**
   * Sends a new reset password email to user
   *
   * @param {string} email
   * @returns {Promise<boolean>}
   * @memberof AuthService
   */
  public async createResetPassword(email: string): Promise<boolean> {
    try {
      const res = await this.post("/lawyers/reset-password", {
        email,
      });
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Retrieves user's email address.
   *
   * @returns {Promise<string>}
   * @memberof AuthService
   */
  public async getEmail(): Promise<string> {
    const res = await this.get("/lawyers/email");
    return res.email;
  }

  /**
   * Resets Firebase password.
   *
   * @returns {Promise<{ email: string; password: string }>}
   * @memberof AuthService
   */
  public async resetFirebasePassword(): Promise<{
    email: string;
    password: string;
  }> {
    const res = await this.post("/lawyers/reset-firebase-auth", {});
    return res;
  }
}
