import React from "react";
import MuiBox from "@material-ui/core/Box";
import styled from "styled-components";

export interface StackProps {
  /**
   * align-item
   */
  align?: "flex-start" | "center" | "flex-end";
  /**
   * Root component. Defaults to <div>.
   */
  component?: React.ElementType;
  /**
   * The amount of spacing between elements (Material UI spacing)
   */
  spacing?: any;
}

/**
 * Stacks given elements vertically with spacing between them.
 */
const Stack: React.FC<StackProps> = ({
  children,
  align,
  component,
  spacing = 0,
}) => {
  return (
    <MuiBox
      component={component}
      display="flex"
      flexDirection="column"
      alignItems={align}
    >
      {React.Children.map(children, (child, i) =>
        child ? (
          <Box key={i} pb={spacing}>
            {child}
          </Box>
        ) : null,
      )}
    </MuiBox>
  );
};

const Box = styled(MuiBox)`
  &&& {
    :last-of-type {
      padding-bottom: 0;
    }
  }
`;

export default Stack;
