import { snakeCase } from "change-case";
import { inject, observer } from "mobx-react";
import React from "react";

import { PageStore, AuthStore } from "app";
import { Button } from "components/Button";
import Modal, {
  ModalTitle,
  ModalContent,
  ModalActions,
} from "components/Modal";
import {
  TextField,
  FieldGroup,
  FieldGridContainer,
  FieldGridItem,
  SelectOption,
} from "components/Form";
import Select from "components/Select";
import { PermissionRole } from "lib/enums";

import UserStore from "../UserStore";
import FormUser from "../commands/FormUser";

interface AddUserModalProps {
  onClose: () => void;
}

interface InjectedProps extends AddUserModalProps {
  userStore: UserStore;
  authStore: AuthStore;
  pageStore: PageStore;
}

@inject("userStore", "authStore", "pageStore")
@observer
export default class AddUserModal extends React.Component<AddUserModalProps> {
  get injectedProps() {
    return this.props as InjectedProps;
  }

  public componentWillMount() {
    const { formCreateUser } = this.injectedProps.userStore;
    formCreateUser.reset();
  }

  public render() {
    const { formCreateUser } = this.injectedProps.userStore;
    const { onClose } = this.props;

    const permissionRoleOptions: SelectOption[] = Object.keys(
      PermissionRole,
    ).map(x => ({
      key: x,
      value: snakeCase(x),
      label: PermissionRole[x],
    }));

    return (
      <Modal open onClose={onClose}>
        <ModalTitle onClose={onClose}>Invite User</ModalTitle>
        <ModalContent>
          <form autoComplete="off">
            <FieldGroup label="Name" required vertical>
              <FieldGridContainer>
                <FieldGridItem xs={6}>
                  <TextField
                    placeholder={formCreateUser.fields.firstName}
                    value={formCreateUser.firstName || ""}
                    onChange={this.handleOnChange("firstName")}
                    error={
                      !!formCreateUser.errors.firstName &&
                      formCreateUser.touched.firstName
                    }
                    helperText={
                      formCreateUser.touched.firstName &&
                      formCreateUser.errors.firstName
                    }
                    fullWidth
                  />
                </FieldGridItem>
                <FieldGridItem xs={6}>
                  <TextField
                    placeholder={formCreateUser.fields.lastName}
                    value={formCreateUser.lastName || ""}
                    onChange={this.handleOnChange("lastName")}
                    error={
                      !!formCreateUser.errors.lastName &&
                      formCreateUser.touched.lastName
                    }
                    helperText={
                      formCreateUser.touched.lastName &&
                      formCreateUser.errors.lastName
                    }
                    fullWidth
                  />
                </FieldGridItem>
              </FieldGridContainer>
            </FieldGroup>
            <FieldGroup label={formCreateUser.fields.email} vertical required>
              <TextField
                type="email"
                fullWidth
                placeholder={formCreateUser.fields.email}
                value={formCreateUser.email || ""}
                onChange={this.handleOnChange("email")}
                error={
                  !!formCreateUser.errors.email && formCreateUser.touched.email
                }
                helperText={
                  formCreateUser.touched.email && formCreateUser.errors.email
                }
              />
            </FieldGroup>
            <FieldGroup
              label={formCreateUser.fields.permissionRole}
              vertical
              required
            >
              <Select
                options={permissionRoleOptions}
                placeholder={formCreateUser.fields.permissionRole}
                value={formCreateUser.permissionRole || ""}
                onChange={this.handleOnSelectChange("permissionRole")}
                error={
                  !!formCreateUser.errors.permissionRole &&
                  formCreateUser.touched.permissionRole
                }
                helperText={
                  formCreateUser.touched.permissionRole &&
                  formCreateUser.errors.permissionRole
                }
              />
            </FieldGroup>
          </form>
        </ModalContent>
        <ModalActions>
          <Button
            fullWidth={false}
            width="auto"
            variant="text"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button fullWidth={false} width="auto" onClick={this.handleOnSubmit}>
            Send
          </Button>
        </ModalActions>
      </Modal>
    );
  }

  private handleOnChange = (field: keyof FormUser) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { formCreateUser } = this.injectedProps.userStore;
    const { value } = event.target;
    formCreateUser.update({
      [field]: value,
    });
  };

  private handleOnSelectChange = (field: keyof FormUser) => (value: any) => {
    const { formCreateUser } = this.injectedProps.userStore;
    formCreateUser.update({
      [field]: value,
    });
  };

  private handleOnSubmit = () => {
    const {
      formCreateUser,
      submitAddSingleUser,
    } = this.injectedProps.userStore;
    formCreateUser.validate().then(isValid => {
      if (isValid) {
        submitAddSingleUser();
        this.props.onClose();
      } else {
        this.injectedProps.pageStore.openAlert({
          message: `Please check follows: ${formCreateUser.invalidFieldNames}`,
          isError: true,
        });
      }
    });
  };
}
