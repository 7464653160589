import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import styled from "styled-components";
import MuiBox from "@material-ui/core/Box";
import MuiTypography from "@material-ui/core/Typography";
import isEmail from "validator/lib/isEmail";

import { useStores } from "app";
import {
  Helmet,
  PageLayout,
  PageLayoutTitle,
  PageLayoutBody,
} from "components/Layout";
import { TextField } from "components/Form";
import { Button } from "components/Button";

export default function Login() {
  const { state } = useLocation<{
    redirect?: string;
  }>();
  const { authStore, pageStore, routerStore } = useStores();
  const { control, errors, formState, handleSubmit } = useForm<FormLogin>({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  // State. Is caps-lock turned on?
  const [capsLock, setCapsLock] = useState(false);
  /**
   * Handles form submit.
   */
  const handleOnSubmit = useCallback(
    handleSubmit(async (data: FormLogin) => {
      try {
        await authStore.login(data);
        routerStore.push(state?.redirect || "/");
      } catch (err) {
        pageStore.openAlert({
          message: `There was a problem. ${
            err.name === "LoginFailed"
              ? "Please check your credentials again"
              : "Please retry shortly"
          }.`,
        });
      }
    }),
    [],
  );
  /**
   * Handles caps-lock state change.
   */
  const handleOnCapsLock = useCallback(
    (e: React.MouseEvent | React.KeyboardEvent) =>
      setCapsLock(e.getModifierState("CapsLock")),
    [capsLock],
  );

  // Block logged-in users.
  useEffect(() => {
    if (authStore.isLoggedIn) {
      pageStore.openAlert({
        message: "You are already logged in.",
        isError: true,
        onConfirm: () => routerStore.push("/"),
      });
    }
  }, []);

  return (
    <PageLayout>
      <Helmet title="Log In" />

      <PageLayoutBody>
        <MuiBox component="form" width="310px" onSubmit={handleOnSubmit}>
          {/* Header */}
          <MuiBox pb={6}>
            <PageLayoutTitle>Log in</PageLayoutTitle>
          </MuiBox>

          <MuiBox pb={8}>
            <MuiBox pb="16px">
              <Controller
                as={SanitizedTextField}
                control={control}
                name="email"
                type="email"
                placeholder="Email"
                fullWidth
                autoFocus
                rules={{
                  required: true,
                  validate: (val) => isEmail(val) || "Invalid email",
                }}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            </MuiBox>
            <MuiBox pb="21px">
              <Controller
                as={SanitizedTextField}
                control={control}
                name="password"
                type="password"
                placeholder="Password"
                fullWidth
                rules={{ required: true }}
                error={!!errors.password}
                helperText={errors.password?.message}
                onMouseDown={handleOnCapsLock}
                onKeyUp={handleOnCapsLock}
              />
            </MuiBox>

            {capsLock && (
              <MuiBox pb="21px">
                <MuiTypography variant="caption">
                  <b>Caps Lock</b> is on.
                </MuiTypography>
              </MuiBox>
            )}

            <MuiBox pb={8}>
              <Button
                type="submit"
                width={310}
                disabled={
                  formState.isSubmitting ||
                  (formState.isSubmitted && !formState.isValid)
                }
              >
                Log in
              </Button>
            </MuiBox>

            <MuiBox textAlign="center">
              <StyledLink to="/forgot-password">
                <MuiTypography variant="body1">Forgot password?</MuiTypography>
              </StyledLink>
            </MuiBox>
          </MuiBox>
        </MuiBox>
      </PageLayoutBody>
    </PageLayout>
  );
}

const SanitizedTextField = styled(TextField)`
  &&& {
    margin-bottom: 0;
  }
`;

const StyledLink = styled(Link)`
  &&& {
    text-decoration: none !important;
    color: ${(props) => props.theme.primary};
  }
`;

const CheckLabel = styled(MuiTypography)`
  cursor: pointer;
`;
