import { useState } from "react";
import shortid from "shortid";
import isEmpty from "lodash/isEmpty";

/**
 * React hook. Manages form fields dynamically.
 *
 * @param {any} defaultValues
 */
export default function useDynamicFields<T>(
  emptyField: T,
  defaultValues?: Record<"string", T>,
): [({ id: string } & T)[], () => void, (index: number) => void] {
  const defaultItems =
    defaultValues && !isEmpty(defaultValues)
      ? Object.keys(defaultValues).map(key => ({ id: key, ...defaultValues }))
      : [item(emptyField)];
  // State. List of field items.
  const [fields, setFields] = useState(defaultItems);
  /**
   * Appends empty field.
   */
  const append = () => {
    setFields(xs => [...xs, item(emptyField)]);
  };
  /**
   * Removes field with given index.
   * @param {number} index
   */
  const remove = (index: number) => {
    setFields(xs => xs.filter((_, i) => i !== index));
  };

  return [fields, append, remove];
}

/**
 * Prduces item with unique identifier.
 */
function item(empty: any) {
  return { id: shortid.generate(), ...empty };
}
