import { inject, observer } from "mobx-react";
import styled from "styled-components";
import * as React from "react";

import { PageStore } from "app";
import { WordMark } from "components/Symbol";

import Alert from "./Alert";
import Toast from "./Toast";
import Spinner from "./Spinner";

const PageLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 100vh;
`;

export const PageLayoutTitle = styled.h1`
  font-size: 30px;
  line-height: 1.33;
  color: ${({ theme }) => theme.black};
  margin: 0;
`;

const HeaderWrapper = styled.header`
  box-sizing: border-box;
  border-bottom: 1px solid #f1f3f8;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageLayoutBody = styled.div`
  flex: 1;
  padding: 0 0 100px 0;
  padding-top: max(50px, min(120px, calc(25vh - 100px)));
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const PageLayoutContent = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
`;

interface LayoutProps extends React.HTMLProps<HTMLElement> {
  pageStore?: PageStore;
}

const PageLayout = inject("pageStore")(
  observer(({ children, pageStore }: LayoutProps) => {
    const {
      isSpinnerShow,
      isAlertShow,
      isToastShow,
      // isPromptShow,
      spinnerMessage,
    } = pageStore!;
    return (
      <PageLayoutWrapper>
        <PageLayoutContent>
          <HeaderWrapper>
            <WordMark />
          </HeaderWrapper>
          {children}
          {isAlertShow && <Alert />}
          {isToastShow && <Toast />}
          {/* {isPromptShow && <Prompt />} */}
          {isSpinnerShow && <Spinner message={spinnerMessage} />}
        </PageLayoutContent>
      </PageLayoutWrapper>
    );
  }),
);

export default PageLayout;
