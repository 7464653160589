import { PercentCrop } from "react-image-crop";

/**
 * Crops image.
 *
 * @param {File} source
 * @param {number} aspectRatio
 * @returns {File}
 */
export function trimImage(file: File, aspectRatio: number = 1): Promise<File> {
  return new Promise(async resolve => {
    const src = await toDataURL(file);
    const image = new Image();

    image.onload = () => {
      const originalWidth = image.naturalWidth;
      const originalHeight = image.naturalHeight;
      const originalAspectRatio = originalWidth / originalHeight;

      let width = originalWidth;
      let height = originalHeight;
      if (originalAspectRatio > aspectRatio) {
        width = originalHeight * aspectRatio;
      } else if (originalAspectRatio < aspectRatio) {
        height = originalWidth / aspectRatio;
      } else {
        return resolve(file);
      }

      const canvasX = (width - originalWidth) / 2;
      const canvasY = (height - originalHeight) / 2;
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(image, canvasX, canvasY);
      canvas.toBlob(blob => {
        if (!blob) {
          throw new Error("Image trim failed.");
        }
        resolve(toFile(blob, file.name, file.type));
      }, file.type);
    };

    image.src = src;
  });
}

/**
 * Resizes image.
 *
 * ASSUME: given source is square-shaped image.
 * @param {File} source
 * @param {number} width
 * @returns {File}
 */
export async function resizeSquareImage(
  file: File,
  width = 800,
): Promise<File> {
  const src = await toDataURL(file);
  return new Promise(resolve => {
    const image = new Image();
    image.onload = () => {
      if (image.naturalWidth <= width) {
        return resolve(file);
      }
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.imageSmoothingQuality = "high";
        canvas.width = width;
        canvas.height = width;
        ctx.drawImage(image, 0, 0, width, width);
      }
      canvas.toBlob(blob => {
        if (!blob) {
          throw new Error("Image resize failed.");
        }
        resolve(toFile(blob, file.name, file.type));
      }, file.type);
    };

    image.src = src;
  });
}

export async function getImageDimensions(
  image: File,
): Promise<{ width: number; height: number }> {
  const dataURL = await toDataURL(image);
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    };
    img.src = dataURL;
  });
}

/**
 * Given a file, produces a data URL.
 *
 * @param {File} file
 * @returns {string}
 */
export function toDataURL(file: File): Promise<string> {
  return new Promise(resolve => {
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      resolve(typeof fileReader.result === "string" ? fileReader.result : "");
    };
    fileReader.readAsDataURL(file);
  });
}

/**
 * Given a blob, produces a file.
 *
 * @param {Blob} blob
 * @param {string} name
 * @param {string} type
 * @returns {File}
 */
export function toFile(blob: Blob, name: string, type: string): File {
  return new File([blob], name, {
    type,
    lastModified: Date.now(),
  });
}

/**
 * Given image and crop-data, produces cropped image file.
 *
 * @param {HTMLImageElement} image Image
 * @param {Crop} crop Crop data object
 * @param {String} name File name
 * @param {String} type File mime type
 * @returns {File} Cropped image file
 */
export function cropImage(
  image: HTMLImageElement,
  crop: PercentCrop,
  name: string,
  type: string,
): Promise<File> {
  const canvas = document.createElement("canvas");
  canvas.width = (image.naturalWidth * (crop.width ?? 100)) / 100;
  canvas.height = (image.naturalHeight * (crop.height ?? 100)) / 100;
  const ctx = canvas.getContext("2d");
  ctx?.drawImage(
    image,
    image.naturalWidth * ((crop.x ?? 0) / 100),
    image.naturalHeight * ((crop.y ?? 0) / 100),
    image.naturalWidth * ((crop.width ?? 100) / 100),
    image.naturalHeight * ((crop.height ?? 100) / 100),
    0,
    0,
    canvas.width,
    canvas.height,
  );
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      blob => {
        if (blob) {
          resolve(toFile(blob, name, type));
        } else {
          reject("Crop failed.");
        }
      },
      type || "image/jpeg",
      1,
    );
  });
}
