import * as React from "react";

import Layout from "./Layout";
import Helmet from "./Helmet";
import PageBody from "./PageBody";
import Spinner from "./Spinner";

const PageLoading = () => (
  <Layout>
    <Helmet title="Loading..." />
    <PageBody>
      <Spinner />
    </PageBody>
  </Layout>
);

export default PageLoading;
