/**
 *
 *
 * @export
 * @enum {number}
 */
export enum InvoiceStatus {
  Waiting = "Waiting",
  Approved = "Approved",
  Overdue = "Overdue",
}
