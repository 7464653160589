import { Redirect, Route, Switch } from "react-router-dom";
import * as React from "react";

import {
  ProfileRoutes,
  ReviewsRoutes,
  OnboardingRoutes,
} from "contexts/profile";
// import DashboardRoutes from "contexts/dashboard";
// import CaseRoutes from "contexts/case";
// import ClientRoutes from "contexts/client";
import ConsultationRoutes from "contexts/consultation";
import UserRoutes from "contexts/user";
import AuthRoutes from "contexts/auth";
import NotificationRoutes from "contexts/notification";
// import { TermsOfService, PrivacyPolicy } from "components/Pages"; // Currently not used
import { PermissionType, PermissionDomain } from "lib/enums";

import PrivateRoute from "./PrivateRoute";

const Routes: React.SFC = () => (
  <Switch>
    <PrivateRoute path="/profile" component={ProfileRoutes} />
    <PrivateRoute path="/reviews" component={ReviewsRoutes} />
    <PrivateRoute path="/onboarding" component={OnboardingRoutes} />
    {/* <PrivateRoute
      path="/cases"
      component={CaseRoutes}
      permissionDomain={PermissionDomain.Case}
      permissionType={[PermissionType.Read, PermissionType.ReadAssigned]}
    /> */}
    {/* <PrivateRoute
      path="/clients"
      component={ClientRoutes}
      permissionDomain={PermissionDomain.Client}
    /> */}
    <PrivateRoute path="/consultations" component={ConsultationRoutes} />
    <PrivateRoute
      path="/users"
      component={UserRoutes}
      permissionDomain={PermissionDomain.User}
      permissionType={PermissionType.Create}
    />
    <PrivateRoute path="/notifications" component={NotificationRoutes} />
    {/* DISABLE DASHBOARD ROUTE FOR NOW */}
    {/* <PrivateRoute
      exact
      path="/"
      component={DashboardRoutes}
      permissionDomain={PermissionDomain.Case}
      permissionType={[PermissionType.Read, PermissionType.ReadAssigned]}
    /> */}
    {/* REROUTE HOME TO CONSULTATIONS FOR NOW */}
    <PrivateRoute
      exact
      path="/"
      render={() => <Redirect to="/consultations" />}
    />
    <Route path="" component={AuthRoutes} />
    {/* <Route path="/terms" component={TermsOfService} /> */}
    {/* <Route path="/privacy" component={PrivacyPolicy} /> */}
  </Switch>
);

export default Routes;
