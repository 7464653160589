import Service from "lib/models/Service";
import { PageableData } from "lib/models/LoadablePageableData";

export default class NotificationService extends Service {
  /**
   * Get a list of notifications for the lawyer
   *
   * @param {ReqNotificationList} config
   * @returns {Promise<PageableData<ResNotification[]>>}
   * @memberof NotificationService
   */
  public async getListNotification(
    config: ReqNotificationList,
  ): Promise<PageableData<ResNotification[]>> {
    try {
      const res = await this.get("/lawyers/notifications", config);
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Mark a notification as read
   *
   * @param {string} notificationId
   * @returns
   * @memberof NotificationService
   */
  public async postNotificationRead(notificationId: string) {
    try {
      const res = await this.post(
        `/lawyers/notifications/${notificationId}/read`,
        {},
      );
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Mark all notifications as read
   *
   * @returns
   * @memberof NotificationService
   */
  public async postNotificationReadAll() {
    try {
      const res = await this.post(`/lawyers/notifications/read`, {});
      return res;
    } catch (e) {
      throw e;
    }
  }
}
