import React, { useEffect, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import MuiBox from "@material-ui/core/Box";
import MuiTypography from "@material-ui/core/Typography";
import TagManager from "react-gtm-module";

import { useStores, AuthService } from "app";
import { Helmet, Stack, PageHeader } from "components/Layout";
import { TextField } from "components/Form";
import { Button } from "components/Button";
import { PageLayout, PageLayoutBody, PageLayoutTitle } from "components/Layout";
import Skeleton from "components/Skeleton";

/**
 * Sets up new user's password.
 *
 * A password is valid if it contains:
 *   - minimum 8 characters,
 *   - at least 1 uppercase character,
 *   - at least 1 lowercase character,
 *   - at least 1 digit.
 */
export default function SetPassword() {
  const { authStore, routerStore, pageStore } = useStores();
  // RHF context values.
  const { control, errors, getValues, handleSubmit, formState } = useForm<
    FormSetPassword
  >();
  // State. Email address retrieved using a token.
  const [email, setEmail] = useState("");
  // Auth token extracted from URL.
  const { token } = qs.parse(useLocation().search);
  /**
   * Submits form data. Signs user in and sends to the onboarding step.
   */
  const handleOnSubmit = useCallback(
    handleSubmit(async ({ password }: FormSetPassword) => {
      try {
        if (typeof token === "string") {
          await authStore.submitSetPassword({ token, email, password });
          TagManager.dataLayer({
            dataLayer: {
              event: "WS_Onboarding_PasswordSetup_Complete",
            },
          });
          routerStore.push("/onboarding/profile");
        }
      } catch (err) {
        if (err.name === "UpdateUserInfoException") {
          pageStore.openAlert({
            message: "You have already set a password.",
            confirmMessage: "Log in",
            onConfirm: () => routerStore.push("/login"),
          });
        } else {
          pageStore.openAlert({
            message: "There was a problem. Please retry shortly.",
          });
        }
      }
    }),
    [token, email],
  );

  useEffect(() => {
    if (authStore.isLoggedIn) {
      // Block logged-in users.
      pageStore.openAlert({
        message: "You have already set a password.",
        isError: true,
        onConfirm: () => routerStore.replace("/"),
      });
    } else if (typeof token === "string") {
      // Found token. Retrieve email address.
      new AuthService({ token }).getEmail().then(setEmail).catch();
    } else {
      // No token.
      pageStore.openAlert({
        message: "No token",
        isError: true,
        onConfirm: () => routerStore.push("/login"),
      });
    }
  }, []);

  return (
    <PageLayout>
      <Helmet title="Set Password" />

      <PageLayoutBody>
        <MuiBox width="310px" textAlign="center">
          {/* Header */}
          <PageHeader>
            <MuiBox pb="16px">
              <PageLayoutTitle>Set password</PageLayoutTitle>
            </MuiBox>
            <MuiTypography variant="body1">
              Password must be at least 8 characters long including uppercase,
              lowercase, and numeric characters
            </MuiTypography>
          </PageHeader>

          {/* Form */}
          <form onSubmit={handleOnSubmit}>
            <MuiBox pb={6}>
              <Stack spacing="16px">
                {/* Email address */}
                <MuiBox ml={-20} width={510} height={24}>
                  {email ? (
                    <MuiTypography variant="h3" component="p">
                      {email}
                    </MuiTypography>
                  ) : (
                    <Skeleton width="220px" />
                  )}
                </MuiBox>

                {/* Password */}
                <Controller
                  as={SanitizedTextField}
                  control={control}
                  defaultValue=""
                  type="password"
                  name="password"
                  placeholder="Password"
                  fullWidth
                  autoFocus
                  disabled={formState.isSubmitting}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  rules={{
                    required: true,
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters long",
                    },
                    pattern: {
                      // At least one lowercase char, uppercase char, and digit.
                      value: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
                      message:
                        "Password must contain uppercase, lowercase, and numeric characters",
                    },
                  }}
                />

                {/* Confirm password */}
                <Controller
                  as={SanitizedTextField}
                  control={control}
                  defaultValue=""
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm password"
                  fullWidth
                  disabled={formState.isSubmitting}
                  error={!errors.password && !!errors.confirmPassword}
                  helperText={
                    !errors.password &&
                    !!errors.confirmPassword &&
                    errors.confirmPassword?.message
                  }
                  rules={{
                    validate: (value) => {
                      const { password } = getValues();
                      return value === password || "Password does not match";
                    },
                  }}
                />
              </Stack>
            </MuiBox>

            {/* Submit button */}
            <Button
              type="submit"
              disabled={
                !email ||
                formState.isSubmitting ||
                Object.values(errors).some(Boolean)
              }
              onClick={handleOnSubmit}
            >
              Sign up
            </Button>
          </form>
        </MuiBox>
      </PageLayoutBody>
    </PageLayout>
  );
}

const SanitizedTextField = styled(TextField)`
  &&& {
    margin-bottom: 0;
  }
`;
