import { action, observable } from "mobx";

abstract class Entity<T> {
  public readonly id: string | number;
  @observable protected source: T;

  constructor(id: string | number, source: T) {
    this.id = id;
    this.source = source;
  }

  @action.bound
  public update(source: T) {
    this.source = source;
  }
}

export default Entity;
