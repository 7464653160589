import { stringify } from "query-string";
import { camelCase, snakeCase } from "change-case";

import config from "config";

export default class Service {
  private apiConfig?: ApiConfiguration;

  constructor(apiConfig?: ApiConfiguration) {
    this.apiConfig = apiConfig;
  }

  get headers() {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };

    const token = this.apiConfig?.token;

    if (!token) {
      return headers;
    }
    return {
      ...headers,
      Authorization: token,
    };
  }

  public async request(query: any, variables?: any, operationName?: string) {
    const body = JSON.stringify({ query, operationName, variables });
    return fetch(config.graphQL.baseUrl, {
      method: "POST",
      headers: this.headers,
      body,
    })
      .then((res: any) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })
      .then((res: any) => res.data)
      .catch(this.catchException);
  }

  /**
   * Get method to bypass CORS / CORB policies
   *
   * @param {string} url
   * @param {*} [options]
   * @returns
   * @memberof Service
   */
  public async safeGet(url: string, options?: any) {
    const queryParams = options
      ? `?${stringify(options, { arrayFormat: "bracket" })}`
      : "";
    return fetch(`${url}${queryParams}`, {
      method: "GET",
      headers: {},
    })
      .then(this.handleResponse)
      .catch(this.catchException);
  }

  public async get(action: string, options?: any) {
    const url = this.baseUrl(action);
    const queryParams = options
      ? `?${stringify(this.toSnake(options), {
          arrayFormat: "bracket",
        })}`
      : "";
    return fetch(`${url}${queryParams}`, {
      method: "GET",
      headers: this.headers,
    })
      .then(this.handleResponse)
      .then(res => this.toCamel(res))
      .catch(this.catchException);
  }

  public async post(
    action: string,
    formData: any,
    options?: any,
  ): Promise<any> {
    const url = this.baseUrl(action);
    const body = JSON.stringify(formData);
    return fetch(url, {
      ...options,
      method: "POST",
      headers: this.headers,
      body,
      mode: "cors",
      cache: "default",
    })
      .then(this.handleResponse)
      .then(res => this.toCamel(res))
      .catch(this.catchException);
  }

  public async put(action: string, formData: any, options?: any): Promise<any> {
    const url = this.baseUrl(action);
    const body = JSON.stringify(formData);
    return fetch(url, {
      ...options,
      method: "PUT",
      headers: this.headers,
      body,
      mode: "cors",
      cache: "default",
    })
      .then(this.handleResponse)
      .then(res => this.toCamel(res))
      .catch(this.catchException);
  }

  public async delete(action: string, options?: any): Promise<any> {
    const url = this.baseUrl(action);
    return fetch(url, {
      ...options,
      method: "DELETE",
      headers: this.headers,
      mode: "cors",
      cache: "default",
    })
      .then(this.handleResponse)
      .then(res => this.toCamel(res))
      .catch(this.catchException);
  }

  public async upload(action: string, body: FormData) {
    const url = this.baseUrl(action);
    const headers = Object.keys(this.headers).reduce((acc, headerName) => {
      if (headerName === "Content-Type") return acc;
      return { ...acc, [headerName]: this.headers[headerName] };
    }, {});
    return fetch(url, {
      method: "POST",
      headers,
      body,
    })
      .then(this.handleResponse)
      .then(res => this.toCamel(res))
      .catch(this.catchException);
  }

  private handleResponse = async (res: Response) => {
    if (res.ok) {
      const contentType = res.headers.get("Content-Type");
      if (contentType?.includes("text")) {
        return res.text();
      } else if (contentType?.includes("application/json")) {
        return res.json();
      }
      return res;
    }
    throw res;
  };

  private catchException = async (error: Error | Response) => {
    // `fetch` promise rejects with TypeError when network error is encountered.
    if (error instanceof Error) {
      throw error;
    }
    if (error.status === 401) {
      // this.refreshToken();
    }
    const contentType = error.headers.get("Content-Type");
    if (contentType?.includes("application/json")) {
      const { code, description } = await error.json();
      const exception = new Error(description);
      exception.name = code;
      throw exception;
    }
    throw new Error(await error.text());
  };

  private baseUrl(urlString: string) {
    const { baseUrl } = config;
    return `${baseUrl}${urlString}`;
  }

  private toSnake(data: any): any {
    if (!data) {
      return undefined;
    }
    if (data instanceof Array) {
      return data.map(v => {
        if (!!v && typeof v === "object") {
          return this.toSnake(v);
        }
        return v;
      });
    } else {
      const returnData: { [key: string]: any } = {};
      Object.keys(data).forEach(key => {
        const newKey = snakeCase(key);
        const value = data[key];
        if (
          value instanceof Array ||
          (value !== null &&
            value !== undefined &&
            value.constructor === Object)
        ) {
          returnData[newKey] = this.toSnake(value);
        } else {
          returnData[newKey] = value;
        }
      });
      return returnData;
    }
  }

  private toCamel(data: any): any {
    if (data instanceof Array) {
      return data.map(v => {
        if (!!v && typeof v === "object") {
          return this.toCamel(v);
        }
        return v;
      });
    } else {
      const returnData: { [key: string]: any } = {};
      Object.keys(data).forEach(key => {
        const newKey = camelCase(key);
        const value = data[key];
        if (
          value instanceof Array ||
          (value !== null &&
            value !== undefined &&
            value.constructor === Object)
        ) {
          returnData[newKey] = this.toCamel(value);
        } else {
          returnData[newKey] = value;
        }
      });
      return returnData;
    }
  }
}
