import { action, computed, observable } from "mobx";

import { LoadingState } from "lib/enums";
import RootStore from "app/RootStore";

abstract class Store {
  @observable protected loadingState: LoadingState = LoadingState.Initial;

  protected rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  /**
   * Get loading state is loading
   *
   * @readonly
   * @type {boolean}
   * @memberof Store
   */
  @computed
  get isLoading(): boolean {
    return this.loadingState === LoadingState.Loading;
  }

  /**
   * Get loading state has error
   *
   * @readonly
   * @type {boolean}
   * @memberof Store
   */
  @computed
  get hasError(): boolean {
    return this.loadingState === LoadingState.Error;
  }

  /**
   * Get loading state has loaded
   *
   * @readonly
   * @type {boolean}
   * @memberof Store
   */
  @computed
  get hasLoaded(): boolean {
    return this.loadingState === LoadingState.Loaded;
  }

  /**
   * Update store's loading state with LoadingState Key
   *
   * @param {keyof typeof LoadingState} state
   * @memberof Store
   */
  @action.bound
  public updateLoadingState(state: LoadingState) {
    this.loadingState = state;
  }

  /**
   * Catch error
   *
   * @param {Error} error
   * @memberof Store
   */
  public catchError(error: Error, customMessage?: string): void {
    this.updateLoadingState(LoadingState.Error);
    this.rootStore.pageStore.openAlert({
      message: error.message || customMessage || "Please try it again.",
      isError: true,
    });
  }
}

export default Store;
