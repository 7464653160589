/**
 * Case History Type
 *
 * @export
 * @enum {number}
 */
export enum HistoryType {
  NoticeOfAction = "Notice Of Action",
  CaseInquiry = "Case Inquiry",
  CaseInquiryResponse = "Case Inquiry Response",
  USCISMessage = "USCIS Message",
  Comment = "Comment",
  CaseDeadline = "Case Deadline",
}
