import React, { useContext } from "react";
import MuiBox from "@material-ui/core/Box";
import { Icon, IconName } from "components/Icons";
import { PageLayout, Helmet } from "components/Layout";
import { ThemeContext } from "styled-components";
import { ThemeInterface } from "lib/styled";

export default function NotFound() {
  const theme = useContext<ThemeInterface>(ThemeContext);

  return (
    <PageLayout>
      <Helmet title="404 Not Found" />

      <MuiBox
        pt={20}
        bgcolor={theme.bgBlueGray}
        flex={1}
        width={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <MuiBox pb="16px" fontSize={30} fontWeight="bold" lineHeight={1.07}>
          404 Not Found
        </MuiBox>
        <MuiBox pb={6} lineHeight={2.29} fontSize={14}>
          The requested URL was not found on this server.
        </MuiBox>
        <MuiBox
          overflow="hidden"
          borderRadius={150}
          width={300}
          height={300}
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor={theme.white}
        >
          <Icon name={IconName.NotFound} width={300} height={180} />
        </MuiBox>
      </MuiBox>
    </PageLayout>
  );
}
