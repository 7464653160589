import { Form, field, required, email } from "lib/form";

export default class FormUser extends Form {
  @field("User Id")
  public userId: string;

  @field("First Name")
  @required()
  public firstName: string;

  @field("Last Name")
  @required()
  public lastName: string;

  @field("Name")
  public name: string;

  @field("Email")
  @required()
  @email()
  public email: string;

  @field("Permission")
  @required()
  public permissionRole: string;
}
