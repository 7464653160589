import forEach from "lodash/forEach";
import map from "lodash/map";
import { observable, action, runInAction, computed } from "mobx";

import { PageStore } from "app";
import { SelectOption } from "components/Form";
import { LoadingState, ToastType } from "lib/enums";
import Store from "lib/models/Store";

import User, { UserSource } from "./models/User";
import UserService, { UserSearchResult } from "./UserService";
import FormUser from "./commands/FormUser";
import UserFilter from "./commands/UserFilter";

export default class UserStore extends Store {
  @observable public formCreateUser: FormUser;
  @observable public formUpdateUser: FormUser;
  @observable public filter: UserFilter;

  @observable private selectedId: string | undefined;
  @observable private entities: { [key: string]: User } = {};
  @observable private searchResult: UserSearchResult;

  private userService: UserService;

  constructor(pageStore: PageStore, userService: UserService) {
    super(pageStore);
    this.userService = userService;
    this.searchResult = {
      ids: [],
    };
    this.formCreateUser = new FormUser();
    this.formUpdateUser = new FormUser();
    this.filter = new UserFilter();
  }

  /**
   * Get list of user
   *
   * @readonly
   * @type {User[]}
   * @memberof UserStore
   */
  @computed
  get dataListUser(): User[] {
    return map(this.searchResult.ids, x => this.entities[x]);
  }

  /**
   * The User list does not have pagination metadata,
   * because it might be smaller than 15, which is default page size in client-side.
   * Follow is a function to filtering and sorting client-side
   *
   * @readonly
   * @type {User[]}
   * @memberof UserStore
   */
  @computed
  get dataListUserWithFilter(): User[] {
    const { keyword, sortBy, sortDirection, isAdmin } = this.filter;
    return this.dataListUser
      .filter(
        ({ name, email, isAdmin: userIsAdmin }) =>
          (!keyword ||
            name.toLowerCase().includes(keyword.toLowerCase()) ||
            email.toLowerCase().includes(keyword.toLowerCase())) &&
          (isAdmin === true ? isAdmin && userIsAdmin : true),
      )
      .sort((a, b) => {
        const x =
          typeof a[sortBy] !== "string"
            ? a[sortBy].toString().toLowerCase()
            : a[sortBy].toLowerCase();
        const y =
          typeof b[sortBy] !== "string"
            ? b[sortBy].toString().toLowerCase()
            : b[sortBy].toLowerCase();
        return x < y ? -1 * sortDirection : x > y ? 1 * sortDirection : 0;
      });
  }

  /**
   * Get mapped list of user for select component
   *
   * @readonly
   * @type {SelectOption[]}
   * @memberof UserStore
   */
  @computed
  get dataListUserOption(): SelectOption[] {
    return map(this.searchResult.ids, x => ({
      key: this.entities[x].id,
      value: this.entities[x].id,
      label: this.entities[x].name,
    }));
  }

  /**
   * Get a single client, loaded with loadSingleClient
   *
   * @readonly
   * @type {(User | undefined)}
   * @memberof UserStore
   */
  @computed
  get dataSingleUser(): User | undefined {
    return this.selectedId ? this.entities[this.selectedId] : undefined;
  }

  /**
   * Get a single user by id
   *
   * @param {string} id
   * @returns {(User | undefined)}
   * @memberof UserStore
   */
  @action.bound
  public dataSingleUserById(id: string): User | undefined {
    return this.entities[id] ? this.entities[id] : undefined;
  }

  /**
   * Get list of User
   *
   * @memberof UserStore
   */
  @action.bound
  public async loadListUser() {
    try {
      this.updateLoadingState(LoadingState.Loading);
      const res = await this.userService.readListUser();
      this.updateLoadingState(LoadingState.Loaded);
      if (res) {
        runInAction(() => {
          this.searchResult = res.result;
          this.update(res.sources);
        });
      }
    } catch (e) {
      this.catchError(e);
    }
  }

  /**
   * Create a single User
   *
   * @memberof UserStore
   */
  @action.bound
  public async submitAddSingleUser() {
    try {
      this.updateLoadingState(LoadingState.Loading);
      const res = await this.userService.createSingleUser(this.formCreateUser);
      this.updateLoadingState(LoadingState.Loaded);
      if (res) {
        this.pageStore.openToast("User Successfully Added", {
          type: ToastType.Success,
        });
        this.loadListUser();
      }
    } catch (e) {
      this.catchError(e);
    }
  }

  /**
   * Update a single User
   *
   * @returns
   * @memberof UserStore
   */
  @action.bound
  public async submitEditSingleUser(id: string) {
    try {
      this.updateLoadingState(LoadingState.Loading);
      const res = await this.userService.updateSingleUser(
        id,
        this.formUpdateUser,
      );
      this.updateLoadingState(LoadingState.Loaded);
      if (res) {
        this.pageStore.openAlert({
          message: "User Successfully Edited",
          onConfirm: () => this.loadListUser(),
        });
      }
    } catch (e) {
      this.catchError(e);
    }
  }

  /**
   * Remove a single User
   *
   * @returns
   * @memberof UserStore
   */
  @action.bound
  public async submitRemoveSingleUser(id: string) {
    try {
      this.updateLoadingState(LoadingState.Loading);
      const res = await this.userService.deleteSingleUser(id);
      this.updateLoadingState(LoadingState.Loaded);
      if (res) {
        this.pageStore.openAlert({
          message: "User Successfully Deleted",
          onConfirm: () => this.loadListUser(),
        });
      }
    } catch (e) {
      this.catchError(e);
    }
  }

  protected update(sources: UserSource[]) {
    forEach(sources, x => {
      if (typeof this.entities[x.userId] === "undefined") {
        this.entities[x.userId] = new User(x.userId, x);
      } else {
        this.entities[x.userId].update(x);
      }
    });
  }
}
