import { Provider } from "mobx-react";
import { match, Route, Switch } from "react-router-dom";
import React from "react";

import { PageLoading } from "components/Layout";
import NotFound from "components/NotFound";
import PrivateRoute from 'app/PrivateRoute'
import { env } from "config";

const Login = React.lazy(() => import("./views/Login"));
const ForgotPassword = React.lazy(() => import("./views/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./views/ResetPassword"));
const SetPassword = React.lazy(() => import("./views/SetPassword"));
const ChangePasswordComplete = React.lazy(() =>
  import("./views/ChangePasswordComplete"),
);

interface RoutesProps {
  match: match<{}>;
}

export default class Routes extends React.Component<RoutesProps> {
  private stores = {};

  public render() {
    return (
      <React.Suspense fallback={<PageLoading />}>
        <Provider {...this.stores}>
          <Switch>
            <Route exact path="/login" render={this.renderLogin} />
            {/* lawfirm client 사용을 위해 만들었지만, 현재 사용하지 않으므로 해당 페이지 접근을 차단한다 */}
            {/* <Route path="/sign-up" render={this.renderSignUp} /> */}
            <Route exact path="/set-password" render={this.renderSetPassword} />
            <Route path="/reset-password" render={this.renderResetPassword} />
            <Route path="/forgot-password" render={this.renderForgotPassword} />
            <Route
              exact
              path="/forgot-password"
              render={this.renderForgotPassword}
            />
            <Route
              exact
              path="/change-password-complete"
              render={this.renderChangePasswordComplete}
            />
            <Route path="*" component={NotFound} />
          </Switch>
        </Provider>
      </React.Suspense>
    );
  }

  private renderLogin = () => <Login />;

  // lawfirm client 사용을 위해 만들었지만, 현재 사용하지 않으므로 해당 페이지 접근을 차단한다
  // private renderSignUp = () => <SignUp />;

  private renderSetPassword = () => <SetPassword />;

  private renderForgotPassword = () => <ForgotPassword />;

  private renderResetPassword = () => <ResetPassword />;

  private renderChangePasswordComplete = () => <ChangePasswordComplete />;
}
