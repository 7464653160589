import * as React from "react";

interface WordMarkProps {
  width?: string;
  height?: string;
  className?: string;
}

const WordMark: React.SFC<WordMarkProps> = ({ width = "100px", height = "30px" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 150 47"
  >
    <defs>
      <linearGradient
        id="prefix__a"
        x1="48.081%"
        x2="54.505%"
        y1=".97%"
        y2="108.949%"
      >
        <stop offset="0%" stopColor="#60F" />
        <stop offset="10%" stopColor="#6E06F6" />
        <stop offset="28%" stopColor="#8216DC" />
        <stop offset="50%" stopColor="#A331B3" />
        <stop offset="76%" stopColor="#D0557A" />
        <stop offset="100%" stopColor="#FF7A3F" />
      </linearGradient>
      <linearGradient
        id="prefix__b"
        x1="45.746%"
        x2="52.462%"
        y1="-1.264%"
        y2="103.997%"
      >
        <stop offset="1%" stopColor="#15B4FF" />
        <stop offset="21%" stopColor="#2A85FF" />
        <stop offset="48%" stopColor="#444CFF" />
        <stop offset="71%" stopColor="#5623FF" />
        <stop offset="89%" stopColor="#6209FF" />
        <stop offset="100%" stopColor="#60F" />
      </linearGradient>
    </defs>
    <g fill="none">
      <path
        fill="url(#prefix__a)"
        d="M126.258 5.929L117.121 0 117.121 10.863 120.742 13.214 120.742 33.808 126.258 37.39z"
      />
      <path
        fill="url(#prefix__b)"
        d="M117.121 10.863L111.604 7.286 111.604 38.747 120.742 44.676 120.742 33.808 117.121 31.462z"
      />
      <path
        fill="#60F"
        d="M117.121 10.863L117.121 31.462 120.742 33.808 120.742 13.214z"
      />
      <path
        fill="#000"
        d="M95.473 18.346V30.22c0 2.505 1.098 3.758 3.296 3.758 2.198 0 3.297-1.253 3.297-3.758V18.346h6.396v13.236c0 2.744-.814 4.8-2.44 6.17s-4.053 2.056-7.28 2.056c-3.227 0-5.652-.685-7.275-2.055-1.623-1.37-2.441-3.427-2.456-6.17V18.345h6.462zM0 8.374L7.143 8.374 7.143 33.099 15.385 33.099 15.385 39.385 0 39.385zM39.604 39.385h-6.258V37.11c-1.132 2.05-3.736 3-6.187 3-6.637 0-10.522-5.236-10.522-11.264 0-6.895 4.945-11.22 10.522-11.22 3.434 0 5.357 1.649 6.187 2.956v-2.236h6.258v21.039zm-16.703-10.44c0 1.934 1.429 5.27 5.203 5.27 1.788.09 3.48-.814 4.396-2.352.506-.813.804-1.738.868-2.693.06-.948-.129-1.895-.55-2.747-.832-1.858-2.718-3.016-4.752-2.918-3.582 0-5.165 3.028-5.165 5.418v.022zM41.791 18.346L48.28 18.346 51.484 31.423 54.945 18.346 60.225 18.346 63.736 31.423 66.868 18.346 73.319 18.346 66.912 39.385 61.104 39.385 57.522 25.775 54.011 39.385 48.203 39.385zM77.56 23.126h-2.34v-4.78h2.34V14.5c0-6.099 4.484-6.632 8.902-6.632v4.736c-1.17 0-2.643 0-2.643 2.011v3.759h2.643v4.78h-2.643v16.23H77.56V23.127zM136.055 38.324L128.286 18.346 134.956 18.346 139.22 30.555 143.22 18.346 149.857 18.346 139.374 46.396 132.731 46.396z"
      />
    </g>
  </svg>
);

export default WordMark;
