import styled from "styled-components";
import React from "react";

import MuiButton from "@material-ui/core/Button";

const NavItemWrapper = styled(({ navActive, ...other }) => (
  <MuiButton {...other} disableFocusRipple disableTouchRipple disableRipple />
))`
  && {
    justify-content: flex-start;
    text-transform: none;

    margin-bottom: 10px;
    font-size: 16px;
    font-weight: normal;
    height: 50px;

    svg {
      margin: 10px 20px;
      fill: ${({ theme, disabled }) =>
        disabled ? "currentColor" : theme.black};
    }

    small {
      color: ${({ theme }) => theme.primary};
    }

    :last-child {
      margin-bottom: 0;
    }

    :hover {
      background-color: ${({ theme }) => theme.bgBlueGray};
    }

    ${({ navActive, theme }) =>
      navActive
        ? `
        font-weight: 500;
        background-color: ${theme.primary};
        color: ${theme.white};

        svg {
          fill: ${theme.white};
        }

        small {
          color: ${theme.white};
        }

        :hover {
          cursor: default;
          background-color: ${theme.primary};
        }
    `
        : ""};
  }
`;

const NavItemSecondary = styled.small`
  position: absolute;
  right: 20px;
  font-size: 11px;
`;

interface NavItemProps {
  onClick: () => void;
  label: string;
  active?: boolean;
  icon?: React.ReactNode;
  secondary?: React.ReactNode | string;
  disabled?: boolean;
}

export default function NavItem(
  props: NavItemProps,
): React.FunctionComponentElement<NavItemProps> {
  const { label, onClick, secondary, active, icon, disabled } = props;
  return (
    <NavItemWrapper
      onClick={onClick}
      navActive={active}
      fullWidth
      disabled={disabled}
    >
      {icon ? icon : null}
      {label}
      <NavItemSecondary>{secondary}</NavItemSecondary>
    </NavItemWrapper>
  );
}
