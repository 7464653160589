import { Form, field, required, arrayLength } from "lib/form";

export default class FormProcess extends Form {
  @field("Name")
  @required()
  public processName: string;

  @field("Description")
  public processDescription: string;

  @field("Forms")
  @required()
  @arrayLength({ minLength: 1 })
  public formTypes: number[] = [];
}
