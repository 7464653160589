import factory from "./factory";

export default function required(customMessage?: string) {
  const defaultMessage = "This field is required";
  const rule = (x: any) => {
    if (typeof x === "string") {
      return Promise.resolve(x.trim() !== "");
    }
    return Promise.resolve(true);
  };
  return factory(defaultMessage, customMessage, rule, () => false);
}
