import { computed } from "mobx";
import moment from "moment";
import LoadablePageableData from "lib/models/LoadablePageableData";

export default class LoadablePageableRequestList extends LoadablePageableData<
  ResConsultationRequest[]
> {
  @computed
  public get dataAllByDate() {
    return this.data;
    // SORT GUARANTEED BY SERVER REQUEST
    // ASCENDING (EARLIER DATA FIRST): PENDING / BOOKED REQUESTS
    // DESCENDING (LATER DATE FIRST): COMPLETED / CANCELED REQUESTS
  }
}
