import { RouterStore } from "mobx-react-router";

import { PageStore, AuthStore, UserPreferenceStore, StreamStore } from "app";
import ConsultationStore from "contexts/consultation/ConsultationStore";
import NotificationStore from "contexts/notification/NotificationStore";
import { CommonStore, createCommonStore } from "contexts/common";
import { ProfileStore } from "contexts/profile";

/**
 * Combine multiple stores using Root Store pattern.
 *   - https://mobx.js.org/best/store.html#combining-multiple-stores
 */
export default class RootStore {
  routerStore: RouterStore;
  pageStore: PageStore;
  authStore: AuthStore;
  consultationStore: ConsultationStore;
  notificationStore: NotificationStore;
  profileStore: ProfileStore;
  commonStore: CommonStore;
  streamStore: StreamStore;
  userPreferenceStore: UserPreferenceStore;

  constructor() {
    this.routerStore = new RouterStore();
    this.authStore = new AuthStore(this);
    this.streamStore = new StreamStore(this);
    this.pageStore = new PageStore(this);
    this.commonStore = createCommonStore(this);
    this.userPreferenceStore = new UserPreferenceStore(this);
    this.consultationStore = new ConsultationStore(this);
    this.notificationStore = new NotificationStore(this);
    this.profileStore = new ProfileStore(this);
  }
}
