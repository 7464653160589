import React, { useContext } from "react";
import MuiBox from "@material-ui/core/Box";
import { Icon, IconName } from "components/Icons";
import { PageLayout, Helmet } from "components/Layout";
import styled, { ThemeContext } from "styled-components";
import { ThemeInterface } from "lib/styled";

export default function UnsupportedBrowser() {
  const theme = useContext<ThemeInterface>(ThemeContext);
  return (
    <PageLayout>
      <Helmet title="Sorry" />
      <MuiBox
        pt={20}
        bgcolor={theme.bgBlueGray}
        flex={1}
        width={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <MuiBox pb="16px" fontSize={30} fontWeight="bold" lineHeight={1.07}>
          Sorry
        </MuiBox>
        <MuiBox pb={4} lineHeight={2.29} fontSize={14}>
          This site is optimized for Chrome.
          <StyledLink
            href="https://www.google.com/chrome/"
            target="_blank"
            rel="noopener"
          >
            Download
          </StyledLink>
        </MuiBox>
        <Icon name={IconName.Chrome} width={180} height={180} />
      </MuiBox>
    </PageLayout>
  );
}

const StyledLink = styled.a`
  padding-left: 10px;
  text-decoration: underline;
  color: ${({ theme }) => theme.black};
  :hover {
    text-decoration: none;
    color: ${({ theme }) => theme.black};
  }
`;