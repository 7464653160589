import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import MuiFormControl from "@material-ui/core/FormControl";
import MuiSelect, { SelectProps } from "@material-ui/core/Select";
import MuiFilledInput from "@material-ui/core/FilledInput";
import MuiMenuItem from "@material-ui/core/MenuItem";
import MuiBox from "@material-ui/core/Box";

import { KeyboardKey } from "lib/enums";

import { SelectOption } from "./SelectOption";

const Wrapper = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
`;

const Select = styled(MuiSelect)`
  & input {
    cursor: pointer;
  }
`;

export type EditableSelectProps = SelectProps & {
  onSave: (value: string) => void;
  options: SelectOption[];
};

/**
 * Component that keeps its own value value before the user decides to
 * commit (onSave) or cancel the modified content.
 *
 * @param {EditableSelectProps} props
 */
const EditableSelect = (props: EditableSelectProps) => {
  /* States */
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(props.value);
  const selectRef = useRef<HTMLDivElement>(null);
  const outerRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);

  /* Effects */
  // Detects Editing state and changes input state accordingly
  useEffect(() => {
    if (!isEditing) {
      // automatically blur on input on cancel edit
      setBlurSelect();
    }
  });

  // Adds event listeners to detect key input
  useEffect(() => {
    function handleKeyUp(event: KeyboardEvent) {
      if (isEditing) {
        switch (event.key) {
          case KeyboardKey.Escape:
          case KeyboardKey.Esc:
            handleOnCancel();
            break;
          case KeyboardKey.Enter:
            handleOnSave();
            break;
          default:
        }
      }
    }

    window.addEventListener("keyup", handleKeyUp);
    return () => window.removeEventListener("keyup", handleKeyUp);
  });

  // Adds event listeners to detect clicks away from the element
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClickAway);
    // document.addEventListener("mousedown", handleMenuClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickAway);
      // document.addEventListener("mousedown", handleMenuClick);
    };
  }, [props.value]);

  /* Handlers */
  // Helps setting up call to isEditing state updates
  const handleSetIsEditing = (newIsEditing: boolean) => () => {
    setIsEditing(newIsEditing);
  };

  // Handles when user decides to commit edit content
  const handleOnSave = () => {
    setIsEditing(false);
    props.onSave(value as string);
  };

  // Handles when user decides to cancel edit content
  const handleOnCancel = () => {
    // Reset value
    setIsEditing(false);
    setValue(props.value);
  };

  // Handles changes to value state and persist change to onSave
  const handleOnSetValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setIsEditing(false);
    props.onSave(e.target.value as string);
  };

  // Deactivates edit view when clicked away
  const handleClickAway = (e: MouseEvent) => {
    if (outerRef.current && outerRef.current.contains(e.target as Node)) {
      // inside click
      return;
    }
    // outside click
    handleOnCancel();
  };

  // Calls onSave when menu item is clicked
  const handleMenuClick = (e: MouseEvent) => {
    if (popupRef.current && popupRef.current.contains(e.target as Node)) {
      if ((e.target as Node).nodeName === "LI") {
        // inside click
        // handleOnSave();
        return;
      }
    }
  };

  // Sets blur on Select element
  const setBlurSelect = () => {
    if (selectRef.current) {
      // MuiSelect creates an input only to holding its value.
      // So remove focused class from MuiSelect UI element
      // selectRef.current.classList.remove("Mui-focused");
      selectRef.current.blur();
    }
  };

  // Active isEditing when clicked
  const handleOnSelectClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Ignore click event emitted by the hidden input (i.e. when a menu item is clicked)
    if ((e.target as Node).nodeName === "DIV") {
      setIsEditing(true);
    }
  };

  const { onSave, options, ...selectFieldProps } = props;

  return (
    <Wrapper ref={outerRef} fullWidth={selectFieldProps.fullWidth}>
      <MuiBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        padding="10px"
        width={selectFieldProps.fullWidth ? "100%" : "auto"}
      >
        <MuiFormControl hiddenLabel>
          <Select
            {...(selectFieldProps as SelectProps)}
            value={value || ""}
            onChange={handleOnSetValue}
            margin="dense"
            ref={selectRef}
            open={isEditing}
            autoFocus={isEditing}
            input={<MuiFilledInput />}
            MenuProps={{ innerRef: popupRef }}
            onClick={handleOnSelectClick}
          >
            {options.map(({ key, label, value: optionValue }) => (
              <MuiMenuItem key={key} value={optionValue}>
                {label}
              </MuiMenuItem>
            ))}
          </Select>
        </MuiFormControl>
      </MuiBox>
      {isEditing ? (
        <MuiBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          padding="10px"
        >
          <button onClick={handleOnSave} disabled={props.disabled}>
            Save
          </button>
          <button onClick={handleOnCancel} disabled={props.disabled}>
            Cancel
          </button>
        </MuiBox>
      ) : (
        <MuiBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          padding="10px"
        >
          <button onClick={handleSetIsEditing(true)} disabled={props.disabled}>
            Edit
          </button>
        </MuiBox>
      )}
    </Wrapper>
  );
};

export default EditableSelect;
