import { computed } from "mobx";
import moment from "moment";

import type { FormType as FormTypeSource } from "apis/common/models";
import Entity from "lib/models/Entity";
import { FormClientType } from "lib/enums";

export { FormTypeSource };

export default class FormType extends Entity<FormTypeSource> {
  @computed
  get clientTypes(): FormClientType[] {
    return this.source.clientTypes || [];
  }

  @computed
  get description(): string {
    return this.source.description || "";
  }

  @computed
  get editionDate(): moment.Moment | undefined {
    return this.source.editionDate
      ? moment(this.source.editionDate)
      : undefined;
  }

  @computed
  get expiryDate(): moment.Moment | undefined {
    return this.source.expiryDate ? moment(this.source.expiryDate) : undefined;
  }

  @computed
  get formName(): string {
    return this.source.formName || "";
  }

  @computed
  get formTypeId(): number {
    return this.source.formTypeId;
  }
}
