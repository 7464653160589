import Service from "lib/models/Service";

import { ProcessSource } from "../models/Process";
import FormProcess from "../commands/FormProcess";

export default class ProcessService extends Service {
  /**
   * Get list of process
   *
   * @returns {Promise<ProcessSource[]>}
   * @memberof ProcessService
   */
  public async readListProcess(): Promise<ProcessSource[]> {
    try {
      const res = await this.get("/processes");
      if (!res) {
        throw res;
      }
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Create a single process
   *
   * @param {FormProcess} form
   * @returns {Promise<ProcessSource>}
   * @memberof ProcessService
   */
  public async createSingleProcess(form: FormProcess): Promise<ProcessSource> {
    try {
      const res = await this.post("/processes", form.values);
      if (!res) {
        throw res;
      }
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Update a single process
   *
   * @param {string} processId
   * @param {FormProcess} form
   * @returns {Promise<ProcessSource>}
   * @memberof ProcessService
   */
  public async updateSingleProcess(
    processId: string,
    form: FormProcess,
  ): Promise<ProcessSource> {
    try {
      const res = await this.put(`/processes/${processId}`, form.values);
      if (!res) {
        throw res;
      }
      return res;
    } catch (e) {
      throw e;
    }
  }
}
