import { computed } from "mobx";

import Entity from "lib/models/Entity";

/**
 * Represents lawyer's practice area.
 */
export default class PracticeArea extends Entity<PracticeAreaSource> {
  @computed
  get typeId(): number {
    return this.source.typeId;
  }

  @computed
  get typeName(): string {
    return this.source.typeName;
  }

  @computed
  get description(): string {
    return this.source.description;
  }
}

export interface PracticeAreaSource {
  typeId: number;
  typeName: string;
  description: string;
}
