import { match, Route, RouteProps, Switch } from "react-router-dom";
import * as React from "react";

import { PageLoading } from "components/Layout";
import NotFound from "components/NotFound";

const NotificationList = React.lazy(() =>
  import("./views/NotificationListView"),
);

interface RoutesProps {
  parentPath: string;
  match: match<{}>;
}

class Routes extends React.Component<RoutesProps> {
  public render() {
    const matchedUrl = this.props.match.url;
    return (
      <React.Suspense fallback={<PageLoading />}>
        <Switch>
          <Route exact path={matchedUrl} render={this.renderNotificationList} />
          <Route path="*" component={NotFound} />
        </Switch>
      </React.Suspense>
    );
  }

  private renderNotificationList = (routerProps: RouteProps) => (
    <NotificationList
      {...routerProps}
      trail={[
        {
          name: "Request List",
          path: this.props.match.url,
        },
      ]}
    />
  );
}

export default Routes;
