import React from "react";
import styled from "styled-components";

import { Icon, IconName } from "components/Icons";
import { theme } from "lib/styled";

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

export interface BookmarkButtonProps {
  filled?: boolean;
}

export default function BookmarkButton({ filled }: BookmarkButtonProps) {
  return filled ? (
    <StyledIcon
      width="24"
      height="24"
      color={theme.primary}
      name={IconName.StarFilled}
    />
  ) : (
    <StyledIcon width="24" height="24" name={IconName.StarEmpty} />
  );
}
