import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { generate } from "shortid";
import { animated, useTransition } from "react-spring";
import styled, { keyframes } from "styled-components";
import MuiBox from "@material-ui/core/Box";

import AvatarCropUploadModal from "contexts/profile/views/components/AvatarCropUploadModal";
import { useStores } from "app";

/**
 * State. Hide tooltip until refresh?
 */
let later = false;

/**
 * A component to have users upload high-resolution profile images.
 * Renders a tooltip if existing profile image is too small.
 */
const BubbleProfileImage: React.FC = observer(() => {
  const { profileImageUrl, originalProfileImageUrl } = useStores().profileStore.profile;
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>();
  const transitions = useTransition(open, null, {
    from: { position: "absolute", opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  const handleOnChange = (file: File) => setFile(file);
  const handleOnClose = () => setFile(null);
  const handleOnLater = () => {
    later = true;
    setOpen(false);
  };
  const checkImageSize = (src: string) => {
    if (!src) {
      const img = new Image();
      img.onload = () => setOpen(true);
      img.src = profileImageUrl;
      return;
    }
    const img = new Image();
    img.onload = () => {
      setOpen(img.naturalWidth < 400 || img.naturalHeight < 400);
    };
    img.src = src;
  };
  useEffect(() => {
    if (!later && profileImageUrl) {
      checkImageSize(originalProfileImageUrl);
    }
  }, [profileImageUrl, originalProfileImageUrl]);

  return (
    <MuiBox position="absolute" zIndex={1}>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={props}>
              <Bubble>
                Upload a high-resolution profile picture to make a good
                impression to clients. <span aria-hidden>📸</span>
                <MuiBox pt={1} display="flex" justifyContent="flex-end">
                  <Button onClick={handleOnLater}>Later</Button>
                  <InputImage onChange={handleOnChange}>
                    <Button>
                      <b>Update</b>
                    </Button>
                  </InputImage>
                </MuiBox>
              </Bubble>
            </animated.div>
          ),
      )}
      <AvatarCropUploadModal
        file={file}
        onUpload={handleOnLater}
        onClose={handleOnClose}
      />
    </MuiBox>
  );
});

interface InputImageProps {
  onChange: (file: File) => void;
}

/**
 * Renders custom file input.
 */
const InputImage: React.FC<InputImageProps> = ({ children, onChange }) => {
  const refInputId = useRef(generate());
  const refInput = useRef<HTMLInputElement>(null);
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = e.target.files?.[0];
    if (newFile) onChange(newFile);
  };
  const handleOnClick = () => {
    if (refInput.current) {
      refInput.current.value = "";
    }
  };
  return (
    <>
      <label htmlFor={refInputId.current}>{children}</label>
      <input
        ref={refInput}
        id={refInputId.current}
        type="file"
        accept="image/*"
        hidden
        onChange={handleOnChange}
        onClick={handleOnClick}
      />
    </>
  );
};

const float = keyframes`
  0%, 100% { -webkit-transform: none; }
  45% { -webkit-transform: translate3d(0, -3px, 0); }
`;
const Button = styled.div`
  margin-left: 20px;
  font-size: 12px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
const Bubble = styled.div`
  position: absolute;
  top: 5px;
  left: -12px;
  border: 1px solid #eee;
  padding: 10px 15px;
  width: 170px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease;
  animation: ${float} 1.5s 3 ease-in-out;
  :hover {
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.25);
  }
  :after {
    content: "";
    position: absolute;
    top: -6px;
    left: 11.5%;
    border-top: 1px solid #eee;
    border-left: 1px solid #eee;
    width: 10px;
    height: 10px;
    background: white;
    transform: rotate(45deg);
  }
`;

export default BubbleProfileImage;
