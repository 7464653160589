import { useRef, useCallback, useEffect } from "react";

/**
 * React hook. Keeps track of whether a component is mounted or not
 * to avoid the following error:
 *
 *   ```
 *   `Warning: Can only update a mounted or mounting component. This usually
 *   means you called setState, replaceState, or forceUpdate on an unmounted
 *   component. This is a no-op.`
 *   ```
 *
 * @returns {() => boolean}
 * @example
 * const isMounted = useIsMounted();
 *
 * if (isMounted()) {
 *   setSomeState(true);
 * }
 */
export default function useIsMounted(): () => boolean {
  const isMountedRef = useRef(true);
  const isMounted = useCallback(() => isMountedRef.current, []);

  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    [],
  );

  return isMounted;
}
