import React, { useContext } from "react";
import { isMobile } from "react-device-detect";
import MuiBox from "@material-ui/core/Box";
import { Icon, IconName } from "components/Icons";
import { PageLayout, Helmet } from "components/Layout";
import { ThemeContext } from "styled-components";
import { ThemeInterface } from "lib/styled";

/**
 * Blocks mobile devices
 */
const CheckDevice: React.FC = ({ children }) => {
  if (isMobile) {
    return <UnsupportedDevice />;
  }
  return <>{children}</>;
};

const UnsupportedDevice = () => {
  const theme = useContext<ThemeInterface>(ThemeContext);
  return (
    <PageLayout>
      <Helmet title="Sorry" />
      <MuiBox
        pt={20}
        bgcolor={theme.bgBlueGray}
        flex={1}
        width={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <MuiBox pb="16px" fontSize={30} fontWeight="bold" lineHeight={1.07}>
          Sorry
        </MuiBox>
        <MuiBox pb={4} lineHeight={2.29} fontSize={14}>
          Mobile devices are not supported.
        </MuiBox>
        <Icon name={IconName.Chrome} width={180} height={180} />
      </MuiBox>
    </PageLayout>
  );
};

export default CheckDevice;
