import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import { InputBaseProps } from "@material-ui/core/InputBase";
import MuiBox from "@material-ui/core/Box";
import MuiChip from "@material-ui/core/Chip";
import MuiTooltip from "@material-ui/core/Tooltip";

import { SelectOption, SelectOptionValueType } from "components/Form";
import Autocomplete from "../Autocomplete";

const Wrapper = styled.div`
  display: flex;
`;

interface EditableAutocompleteProps extends InputBaseProps {
  value: SelectOptionValueType[];
  options: SelectOption[];
  onSave: (value: any[]) => void;
  onChipClick?: (value: any) => void;
}

/**
 * Component that keeps its own multi select value before the user decides to
 * commit (onSave) or cancel the modified content.
 *
 * @export
 * @param {EditableAutocompleteProps} props
 * @returns
 */
export default function EditableAutocomplete(props: EditableAutocompleteProps) {
  /* States */
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(props.value);
  const inputRef = useRef<HTMLInputElement>(null);
  const outerRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);

  /* Effects */
  // Detects Editing state and changes input state accordingly
  useEffect(() => {
    if (isEditing) {
      // automatically focus on input on edit
      setFocusInput();
    } else {
      // automatically blur on input on cancel edit
      setBlurInput();
    }
  });

  // Adds event listeners to detect key input
  useEffect(() => {
    function handleKeyUp(event: KeyboardEvent) {
      switch (event.key) {
        case "Esc":
        case "Escape":
          handleOnCancel();
          break;
        case "Enter":
          // do not anything
          // handleOnSave();
          break;
        default:
      }
    }

    window.addEventListener("keyup", handleKeyUp);
    return () => window.removeEventListener("keyup", handleKeyUp);
  });

  // Adds event listeners to detect clicks away from the element
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClickAway);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickAway);
    };
  }, [props.value]);

  /* Handlers */
  // Helps setting up call to isEditing state updates
  const handleSetIsEditing = (newIsEditing: boolean) => () => {
    setIsEditing(newIsEditing);
  };

  const handleOnChipClick = (chipValue: any) => () => {
    if (props.onChipClick) {
      props.onChipClick(chipValue);
    }
  };

  // Handles when user decides to commit edit content
  const handleOnSave = () => {
    setIsEditing(false);
    // props.onSave();
  };

  // Handles when user decides to cancel edit content
  const handleOnCancel = () => {
    // Reset value
    setValue(props.value);
    setIsEditing(false);
  };

  // // Handles changes to internal value state (temp value while user changes value)
  const handleOnSetValue = (targetValue: any[]) => setValue(targetValue);

  // Deactivates edit view when clicked away
  const handleClickAway = (e: MouseEvent) => {
    if (outerRef.current && outerRef.current.contains(e.target as Node)) {
      // inside click
      return;
    }
    if (popupRef.current && popupRef.current.contains(e.target as Node)) {
      // inside click
      return;
    }
    // outside click
    handleOnCancel();
  };

  // Sets blur on input element
  const setBlurInput = () => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  // Sets focus on input element
  const setFocusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const { onSave, options, ref, onChipClick, ...textFieldProps } = props;

  const mappedValue = options
    .filter(({ value: optionValue }) => value.indexOf(optionValue) > -1)
    .sort((a, b) => value.indexOf(a.value) - value.indexOf(b.value));

  return (
    <Wrapper ref={outerRef}>
      <MuiBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        padding="10px"
      >
        {isEditing ? (
          <>
            <Autocomplete
              {...textFieldProps}
              onInputChange={handleOnSetValue}
              value={value}
              options={options}
              // onClick={handleSetIsEditing(true)}
              margin="dense"
              inputRef={ref}
              popupRef={popupRef}
            />
            <MuiBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              padding="10px"
            >
              <button onClick={handleOnSave} disabled={props.disabled}>
                Save
              </button>
              <button onClick={handleOnCancel} disabled={props.disabled}>
                Cancel
              </button>
            </MuiBox>
          </>
        ) : (
          <>
            {mappedValue.map(({ key, label, primary, value: optionValue }) =>
              !!onChipClick ? (
                <MuiTooltip
                  key={`${label}-${key}`}
                  title={`See more ${label}'s info (Click)`}
                >
                  <MuiChip
                    label={label}
                    color={primary ? "primary" : "default"}
                    size="small"
                    onClick={handleOnChipClick(optionValue)}
                  />
                </MuiTooltip>
              ) : (
                <MuiChip
                  key={`${label}-${key}`}
                  label={label}
                  color={primary ? "primary" : "default"}
                  size="small"
                />
              ),
            )}
            <MuiBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              padding="10px"
            >
              <button
                onClick={handleSetIsEditing(true)}
                disabled={props.disabled}
              >
                Edit
              </button>
            </MuiBox>
          </>
        )}
      </MuiBox>
    </Wrapper>
  );
}
