import { observable, reaction, action } from "mobx";
import moment from "moment";
import { DateTime } from "luxon";

import ProfileService from "./ProfileService";
import Profile from "./models/Profile";
import Store from "lib/models/Store";
import LoadablePageableData, {
  PaginationConfigRequest,
} from "lib/models/LoadablePageableData";
import { trimImage, resizeSquareImage } from "lib/image";
import LoadableData from "lib/models/LoadableData";
import RootStore from "app/RootStore";
import { ToastType } from "lib/enums";

export default class ProfileStore extends Store {
  /**
   * User profile.
   */
  @observable public profile = new Profile("", null);
  /**
   * User's average rating.
   */
  @observable public dataRating = new LoadableData<{
    total?: number;
    time?: number;
    kindness?: number;
    expertise?: number;
  }>({});
  /**
   * List of reviews from clients.
   */
  @observable public dataListReview = new LoadablePageableData<ResReview[]>({
    items: [],
    pageNumber: 1,
    pageSize: 5,
    totalCount: 0,
  });

  protected profileService: ProfileService;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.profileService = new ProfileService(rootStore.authStore);
    reaction(
      () => rootStore.authStore.isLoggedIn,
      (isLoggedIn) => {
        if (isLoggedIn) {
          this.loadSingleProfile();
        } else {
          this.resetProfile();
        }
      },
    );
  }

  @action
  private resetProfile() {
    this.profile = new Profile("", null);
  }

  @action
  public resetReviews = () => {
    this.dataListReview = new LoadablePageableData<ResReview[]>({
      items: [],
      pageNumber: 1,
      pageSize: 5,
      totalCount: this.dataListReview.currentConfig.totalCount,
    });
  };

  public readDataListReview = async (
    config: PaginationConfigRequest = {
      pageSize: this.dataListReview.currentConfig.pageSize,
      pageNumber: 1,
    },
  ) => {
    try {
      this.dataRating.setIsLoading();
      this.dataListReview.setIsLoading();
      const data = await this.profileService.getListReview(config);
      const {
        averageTimeStar,
        averageKindnessStar,
        averageExpertiseStar,
      } = data;
      this.dataRating.updateData({
        total:
          (averageTimeStar + averageKindnessStar + averageExpertiseStar) / 3,
        time: averageTimeStar,
        kindness: averageKindnessStar,
        expertise: averageExpertiseStar,
      });
      this.dataListReview.updatePageableData({
        items: data.reviews.items,
        pageNumber: data.reviews.pageNumber,
        pageSize: data.reviews.pageSize,
        totalCount: data.reviews.totalCount,
      });
    } catch (err) {
      this.dataRating.setHasErrored();
      this.dataListReview.setHasErrored();
    }
  };

  /**
   * Loads user profile.
   *
   * @memberof ProfileStore
   */
  public async loadSingleProfile() {
    const profileSource = await this.profileService.readSingleProfile();
    this.updateProfileFromSource(profileSource);
    await this.correctTimeZoneOffsetMismatch();
  }

  /**
   * Time zone mismatch happens when Daylight Saving Time begins or ends.
   *
   * @memberof ProfileStore
   */
  public async correctTimeZoneOffsetMismatch() {
    if (this.profile.timeZoneName && !this.profile.isTimeZoneOffsetCorrect) {
      const currentOffset =
        DateTime.local().setZone(this.profile.timeZoneName).offset / 60;
      const newProfile = await this.profileService.updateUserProfile({
        timezoneOffset: currentOffset,
      });
      this.updateProfileFromSource(newProfile);
    }
  }

  /**
   * Given a new data source, updates stored profile.
   *
   * @param {ResProfile} source
   * @memberof {ProfileStore}
   */
  public updateProfileFromSource(source: ResProfile) {
    if (!this.profile.id) {
      this.profile = new Profile(source.lawyerId, source);
    } else {
      this.profile.update(source);
    }
  }

  /**
   * Updates schedule (working hours).
   */
  public async updateScheudle(formData: FormSchedule) {
    const timezoneOffset = Number(formData.timeZoneOffset);
    const profileReqPayload: Partial<ReqProfile> = {
      timezoneName: formData.timeZoneName,
      timezoneOffset,
      // Convert local hour to UTC hour, and format it in "HH:00:00".
      workingHours: Object.keys(formData.workingHours || {}).reduce(
        (acc, k) => {
          const workingHour = formData.workingHours?.[k];
          return {
            ...acc,
            [`${k}Start`]: this.formatHour(timezoneOffset, workingHour?.start),
            [`${k}End`]: this.formatHour(timezoneOffset, workingHour?.end),
          };
        },
        {},
      ) as ReqWorkingHours,
    };
    try {
      const newProfile = await this.profileService.updateUserProfile(
        profileReqPayload,
      );
      this.updateProfileFromSource(newProfile);
      this.rootStore.pageStore.openToast("Your schedule has been updated.", {
        type: ToastType.Success,
      });
    } catch (err) {
      this.rootStore.pageStore.openAlert({
        message: "There was a problem. Please retry shortly.",
        isError: true,
      });
    }
  }

  /**
   * Updates phone number.
   */
  public async updatePhoneNumber(data: {
    mobileCountry: string;
    mobileNumber: string;
  }) {
    try {
      const newProfile = await this.profileService.updateUserProfile(data);
      this.updateProfileFromSource(newProfile);
    } catch (err) {
      // CONTINUE REGARDLESS OF ERROR
    }
  }

  /**
   * Updates mandatory profile.
   *
   * NOTE: Assume given data is already sanitized that there's no empty arrays or objects.
   * Be careful to not pass those in since the existing remote data will be overwritten.
   * @param {FormOptionalProfile} formData
   * @memberof ProfileStore
   */
  public async updateMandatoryProfile(formData: FormMandatoryProfile) {
    const timezoneOffset = Number(formData.timeZoneOffset);
    const profileReqPayload: Partial<ReqProfile> = {
      firstName: formData.name.firstName,
      middleName: formData.name.middleName,
      lastName: formData.name.lastName,
      profileImageUrl: formData.profileImageUrl,
      mobileCountry: formData.phoneNumber.countryCode,
      mobileNumber: formData.phoneNumber.subscriberNumber,
      firmName: formData.firm.firmName,
      address: {
        address1: formData.firm.addressLine1,
        address2: formData.firm.addressLine2,
        city: formData.firm.city,
        state: formData.firm.state,
        zipcode: formData.firm.zip,
      },
      officeNumber: formData.firm.phoneNumber,
      specialityTypes: formData.practiceAreas.map((x) => parseInt(x, 10)),
      // Remove thousand separators and multiply by 4 to save hourly-rate on server.
      hourlyRate: 4 * parseInt(formData.fee.replace(/,/g, ""), 10),
      timezoneName: formData.timeZoneName,
      timezoneOffset,
      // Convert local hour to UTC hour, and format it in "HH:00:00".
      workingHours: Object.keys(formData.workingHours || {}).reduce(
        (acc, k) => {
          const workingHour = formData.workingHours?.[k];
          return {
            ...acc,
            [`${k}Start`]: this.formatHour(timezoneOffset, workingHour?.start),
            [`${k}End`]: this.formatHour(timezoneOffset, workingHour?.end),
          };
        },
        {},
      ) as ReqWorkingHours,
    };
    const newProfile = await this.profileService.updateUserProfile(
      profileReqPayload,
    );
    this.updateProfileFromSource(newProfile);
    this.rootStore.authStore.updateName(
      newProfile.firstName,
      newProfile.lastName,
    );
  }

  /**
   * Updates optional profile.
   *
   * NOTE: Assume given data is already sanitized that there's no empty arrays or objects.
   * Be careful to not pass those in since the existing remote data will be overwritten.
   * @param {Partial<FormOptionalProfile>} formData
   * @memberof ProfileStore
   */
  public async updateOptionalProfile(formData: Partial<FormOptionalProfile>) {
    const profileReqPayload: Partial<ReqProfile> = {};
    // 1. Licenses -- Record<string, FormLicense> -> LicenseReq[]
    Object.values(formData.licenses || {}).forEach((license) => {
      if (!profileReqPayload.licences) {
        profileReqPayload.licences = [];
      }
      profileReqPayload.licences.push({
        state: license.state,
        status: license.status,
        acquiredYear: Number(license.acquired),
        updatedDate: moment()
          .year(Number(license.updatedDate))
          .format("YYYY-MM-DD"),
      });
    });
    // 2. Schools -- Record<string, FormSchool> -> SchoolReq[]:
    Object.values(formData.schools || {}).forEach((school) => {
      if (!profileReqPayload.schools) {
        profileReqPayload.schools = [];
      }
      profileReqPayload.schools.push({
        schoolName: school.name,
        degree: school.degree,
        graduateYear: Number(school.graduation),
      });
    });
    // 3. Careers Record<string, FormCareer> -> CareerReq[]:
    Object.values(formData.careers || {}).forEach((career) => {
      if (!profileReqPayload.careers) {
        profileReqPayload.careers = [];
      }
      profileReqPayload.careers.push({
        companyName: career.name,
        title: career.title,
        isPresent: career.present,
        startDate: moment()
          .year(Number(career.from.year))
          .month(career.from.month)
          .date(1)
          .format("YYYY-MM-DD"),
        endDate:
          career.to.year && career.to.month
            ? moment()
                .year(Number(career.to.year))
                .month(career.to.month)
                .date(1)
                .format("YYYY-MM-DD")
            : null,
      });
    });
    // 4. Website
    profileReqPayload.website = formData.website;
    // 5. Languages
    profileReqPayload.languages = formData.languages;
    // 6. About you
    profileReqPayload.aboutYou = formData.aboutYou;
    const profileSource = await this.profileService.updateUserProfile(
      profileReqPayload,
    );
    this.updateProfileFromSource(profileSource);
  }

  /**
   * Uploads given file after processing it.
   *
   * @param {File} file
   * @returns Profile response
   * @memberof ProfileStore
   */
  public async uploadProfileImage(file: File): Promise<ResProfile> {
    const processed = await this.processProfileImage(file);
    const res = await this.profileService.createProfileImage(processed);
    this.updateProfileFromSource(res);
    return res;
  }

  /**
   * Completes Stripe connection process.
   *
   * @param {string} code
   * @memberof ProfileStore
   */
  public async createBankAccount(code: string) {
    const stripeAccountId = await this.profileService.createBankAccount(code);
    this.rootStore.authStore.updateAccountId(stripeAccountId);
  }

  /**
   * Verifies phone number.
   *
   * @param {string} mobileCountry +82
   * @param {string} mobileNumber 1056781234
   * @param {string} code Verification code
   * @returns {boolean} Is phone number valid?
   */
  public async verifyPhoneNumber(
    mobileCountry: string,
    mobileNumber: string,
    code: string,
  ): Promise<boolean> {
    try {
      const valid = await this.profileService.checkPhoneVerificationCode(
        mobileCountry + mobileNumber,
        code,
      );
      if (valid) {
        this.updatePhoneNumber({ mobileCountry, mobileNumber });
      }
      return valid;
    } catch (err) {
      return false;
    }
  }

  /**
   * Processes given image file.
   *   1. Crop (square)
   *   2. Resize (800x800)
   *
   * @param {File} file
   * @returns Processed image file.
   * @memberof ProfileStore
   */
  private async processProfileImage(file: File): Promise<File> {
    const trimmed = await trimImage(file);
    const resized = await resizeSquareImage(trimmed);
    return resized;
  }

  /**
   * Given an hour number, produces a formatted UTC hour string.
   *
   * @param {number} hour
   * @param {number} utcOffset UTC offset in hours.
   * @example
   *   formatHour(9, 14);  // ==> "05:00:00"
   *   // 2 PM - KST (UTC +9)
   *   // 5 AM -     (UTC +0)
   * @memberof ProfileStore
   */
  private formatHour(utcOffset: number, hour?: number) {
    if (typeof hour !== "number") return null;
    const m = moment().utcOffset(utcOffset).hour(hour);
    return m.utc().format("HH:00:00");
  }
}
