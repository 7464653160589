import Service from "lib/models/Service";
import {
  PageableData,
  PaginationConfigRequest,
} from "lib/models/LoadablePageableData";

export default class ConsultationService extends Service {
  /**
   * Get a list of requests for video consultation
   *
   * @returns {Promise<ResConsultationRequest[]>}
   * @memberof ConsultationService
   */
  public async getListRequest(): Promise<ResConsultationRequest[]> {
    try {
      const res = await this.get("/consultations");
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Get a list of completed requests for video consultation
   *
   * @param {PaginationConfigRequest} config
   * @returns {Promise<PageableData<ResConsultationRequest[]>>}
   * @memberof ConsultationService
   */
  public async getListRequestCompleted(
    config: PaginationConfigRequest,
  ): Promise<PageableData<ResConsultationRequest[]>> {
    try {
      const res = await this.get("/consultations", {
        status: "completed",
        ...config,
      });
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Get a list of canceled requests for video consultation
   *
   * @param {PaginationConfigRequest} config
   * @returns {Promise<PageableData<ResConsultationRequest[]>>}
   * @memberof ConsultationService
   */
  public async getListRequestCanceled(
    config: PaginationConfigRequest,
  ): Promise<PageableData<ResConsultationRequest[]>> {
    try {
      const res = await this.get("/consultations", {
        status: "canceled",
        ...config,
      });
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Post a decision to wether accept the consultation request or not
   *
   * @param {ReqConsultationDecision} decision
   * @returns {Promise<boolean>}
   * @memberof ConsultationService
   */
  public async postDecisionRequest(
    decision: ReqDecideRequest,
  ): Promise<boolean> {
    try {
      const res = await this.post(
        `/consultations/requests/${decision.id}`,
        decision,
      );
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Get a single consultation detail
   *
   * @param {string} consultationId
   * @returns {Promise<ResConsultationDetail>}
   * @memberof ConsultationService
   */
  public async getConsultation(
    consultationId: string,
  ): Promise<ResConsultationDetail> {
    try {
      const res = await this.get(`/consultations/${consultationId}`);
      return res;
      // return mockGetConsultation;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Get a single consultation chat detail
   *
   * @param {string} consultationId
   * @returns {Promise<ResConsultationChatDetail>}
   * @memberof ConsultationService
   */
  public async getConsultationChatDetail(
    consultationId: string,
  ): Promise<ResConsultationChatDetail> {
    try {
      const res = await this.get(`/consultations/${consultationId}/chat-info`);
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Get lawyer's online status (during which they will able to receive live consultation request)
   *
   * @returns {Promise<ResSetOnlineStatus>}
   * @memberof ConsultationService
   */
  public async getStatusOnline(): Promise<ResSetOnlineStatus> {
    try {
      const res = await this.get("/lawyers/online-status");
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Put lawyer's online status (during which they will able to receive live consultation request)
   *
   * @param {boolean} isOnline
   * @returns
   * @memberof ConsultationService
   */
  public async putStatusOnline(isOnline: boolean) {
    try {
      const res = await this.put("/lawyers/online-status", {
        isOnline,
      });
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Put lawyer's decision on consultation request
   *
   * @param {string} consultationId
   * @param {boolean} willAccept
   * @param {RequestCancelType} [cancelType]
   * @param {string} [reason]
   * @returns
   * @memberof ConsultationService
   */
  public async putAcceptRequest(
    consultationId: string,
    willAccept: boolean,
    cancelType?: RequestCancelType,
    reason?: string,
  ) {
    try {
      const res = await this.put(`/consultations/${consultationId}`, {
        willAccept,
        cancelType,
        reason,
      });
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Post request cancel
   *
   * @param {string} requestId
   * @param {RequestCancelType} cancelType
   * @param {string} reason
   * @returns
   * @memberof ConsultationService
   */
  public async postCancelRequest(
    requestId: string,
    cancelType: RequestCancelType,
    reason: string,
  ) {
    try {
      const res = await this.post(`/consultations/${requestId}/cancel`, {
        cancelType,
        reason,
      });
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Post report client
   *
   * @param {string} consultationId
   * @param {string} reason
   * @returns
   * @memberof ConsultationService
   */
  public async postReportClient(consultationId: string, reason: string) {
    try {
      const res = await this.post(`/consultations/${consultationId}/report-client`, {
        reason,
      });
      return res;
    } catch (e) {
      throw e;
    }
  }
}
