import React from "react";
import MuiButton, {
  ButtonProps as MuiButtonProps,
} from "@material-ui/core/Button";
import styled from "styled-components";
import { ThemeInterface } from "lib/styled";

/**
 * Returns the color string for CSS background-color property
 *
 * @param {ThemeInterface} theme
 * @param {ButtonVariant} [variant="solid"]
 * @param {boolean} [isDisabled=false]
 * @param {boolean} [isNeutral=false]
 * @returns {string}
 */
function renderBgColor(
  theme: ThemeInterface,
  variant: ButtonVariant = "solid",
  isDisabled: boolean = false,
  isNeutral: boolean = false,
): string {
  switch (variant) {
    case "solid":
      if (isDisabled) {
        return "#dddddd";
      }
      if (isNeutral) {
        return "#f3f4f8";
      }
      return theme.primary;
    case "line":
    case "text":
      return theme.white;
  }
}

/**
 * Returns the color string for CSS background-color property on hover
 *
 * @param {ThemeInterface} theme
 * @param {ButtonVariant} [variant="solid"]
 * @param {boolean} [isNeutral=false]
 * @returns {string}
 */
function renderHoverBgColor(
  theme: ThemeInterface,
  variant: ButtonVariant = "solid",
  isDisabled: boolean = false,
  isNeutral: boolean = false,
): string {
  switch (variant) {
    case "solid":
      if (isDisabled) {
        return "#dddddd";
      }
      if (isNeutral) {
        return "#dcdeea";
      }
      return "#0a41b5";
    case "line":
    case "text":
      return theme.bgBlueGray;
  }
}

/**
 * Returns the color string for CSS color property
 *
 * @param {ThemeInterface} theme
 * @param {ButtonVariant} [variant="solid"]
 * @param {boolean} [isDisabled=false]
 * @returns {string}
 */
function renderColor(
  theme: ThemeInterface,
  variant: ButtonVariant = "solid",
  isDisabled: boolean = false,
  isNeutral: boolean = false,
): string {
  switch (variant) {
    case "solid":
      if (isNeutral) {
        return theme.black;
      }
      return theme.white;
    case "line":
    case "text":
      if (isDisabled) {
        return "#dddddd";
      }
      if (isNeutral) {
        return "#666666";
      }
      return theme.primary;
  }
}

/**
 * Returns the border color string for CSS border color property
 *
 * @param {ThemeInterface} theme
 * @param {boolean} [isDisabled=false]
 * @param {boolean} [isNeutral=false]
 * @returns {string}
 */
function renderBorderColor(
  theme: ThemeInterface,
  isDisabled: boolean = false,
  isNeutral: boolean = false,
  variant: ButtonVariant = "solid",
): string {
  if (isDisabled) {
    return "#dddddd";
  }
  if (isNeutral) {
    if (variant === "line") {
      return "#eeeeee";
    }
    return "#666666";
  }
  return theme.primary;
}

/**
 * Returns the padding value for CSS padding property
 *
 * @param {ButtonSize} [size="medium"]
 * @returns {string}
 */
function renderPadding(size: ButtonSize = "medium"): string {
  switch (size) {
    case "large":
    case "medium":
      return "16px 20px";
    case "small":
      return "13px 16px";
    case "xs":
      return "8.5px 16px";
  }
}

/**
 * Returns the font size value for CSS font-size property
 *
 * @param {ButtonSize} [size="medium"]
 * @returns {string}
 */
function renderFontSize(size: ButtonSize = "medium"): string {
  switch (size) {
    case "large":
    case "medium":
      return "16px";
    case "small":
    case "xs":
      return "14px";
  }
}

export type ButtonVariant = "solid" | "line" | "text";

export type ButtonSize = "xs" | "small" | "medium" | "large";

export type ButtonProps = Omit<MuiButtonProps, "variant" | "size"> & {
  /**
   * "solid" | "line" | "text"
   *
   * @type {ButtonVariant}
   */
  line?: boolean;
  /**
   * Width in pixel.
   * Default is 100%
   *
   * @type {number}
   * @memberof ButtonProps
   */
  width?: number | string;
  variant?: ButtonVariant;
  neutral?: boolean;
  size?: ButtonSize;
};

const Button = styled(({ variant, size, neutral, ...rest }: ButtonProps) => (
  <MuiButton
    disableRipple
    fullWidth
    classes={{ label: "button-label-root" }}
    {...rest}
  />
))`
  // root styling goes here
  &&& {
    width: ${({ width }) =>
      width ? (typeof width === "string" ? width : `${width}px`) : "100%"};
    background-color: ${({ theme, variant, disabled, neutral }) =>
      renderBgColor(theme, variant, disabled, neutral)};
    border-radius: 10px;
    color: ${({ theme, variant, disabled, neutral }) =>
      renderColor(theme, variant, disabled, neutral)};
    padding: ${({ size }) => renderPadding(size)};
    text-transform: none;
    line-height: ${({ size }) => (size === "small" ? 1.57 : 1.5)};
    font-size: ${({ size }) => renderFontSize(size)};
    font-weight: bold;
    border-style: solid;
    border-width: ${({ variant }) => (variant === "line" ? "1px" : "0")};
    border-color: ${({ disabled, theme, neutral, variant }) =>
      renderBorderColor(theme, disabled, neutral, variant)};

    .button-label-root {
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
    }
  }

  &&&:hover {
    background-color: ${({ theme, variant, disabled, neutral }) =>
      renderHoverBgColor(theme, variant, disabled, neutral)};
  }
`;

export default Button;
