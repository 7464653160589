import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { observer } from "mobx-react";
import moment from "moment";
import { Box as MuiBox } from "@material-ui/core";
import { useStores, RootStore } from "app";
import { NotificationType } from "lib/enums";
import ThemeInterface from "lib/styled/ThemeInterface";
import { Icon, IconName } from "components/Icons";

const Wrapper = styled.div<{ isRead?: boolean; dense?: boolean }>`
  cursor: pointer;
  background-color: ${({ isRead }) => (isRead ? "white" : "#f3f4f8")};
  transition: background-color 0.2s ease-in;
  display: flex;
  width: 100%;
  border-top: 1px solid #dcdeea;
  ${({ dense }) => dense && `border: none;`}

  &:last-child {
    border-bottom: 1px solid #dcdeea;
  }
`;

const WrapperIcon = styled.div<{ dense?: boolean }>`
  border-radius: 50%;
  width: ${({ dense }) => (dense ? "40px" : "50px")};
  height: ${({ dense }) => (dense ? "40px" : "50px")};
  overflow: hidden;
`;

const WrapperContent = styled.span<{ dense?: boolean }>`
  line-height: 1.63;
  font-size: ${({ dense }) => (dense ? "14px" : "16px")};
  white-space: normal;
`;

const WrapperTime = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.gray5};
`;

const IconBg = styled.div<{ bgColor?: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ bgColor }) => bgColor ?? "none"};
`;

const renderTimeFromCreation = (createdAt: string) => {
  const duration = moment.duration(
    moment(createdAt).valueOf() - moment().valueOf(),
  );

  return <WrapperTime>{duration.humanize(true)}</WrapperTime>;
};

const renderIcon = (type: NotificationType) => {
  const theme = useContext<ThemeInterface>(ThemeContext);

  switch (type) {
    case NotificationType.LiveRequest:
      return (
        <IconBg bgColor="#00b4ff">
          <Icon width={24} height={24} name={IconName.Videocam} />
        </IconBg>
      );
    case NotificationType.BookingRequest:
      return (
        <IconBg bgColor={theme.primary}>
          <Icon width={24} height={24} name={IconName.Message} />
        </IconBg>
      );
    case NotificationType.BookingCancelation:
    case NotificationType.LiveCancelation:
      return (
        <IconBg bgColor="#ff7878">
          <Icon width={24} height={24} name={IconName.CancelMessage} />
        </IconBg>
      );
    case NotificationType.StartConsultation:
      return (
        <IconBg bgColor="#00297f">
          <Icon width={24} height={24} name={IconName.Hourglass} />
        </IconBg>
      );
    default:
      return (
        <IconBg bgColor={theme.gray4}>
          <Icon width={24} height={24} name={IconName.Megaphone} />
        </IconBg>
      );
  }
};

const renderOnClick = (stores: RootStore, notification: ResNotification) => {
  const { notificationStore, consultationStore, routerStore } = stores;
  const { notificationId, notificationType } = notification;

  const updateReadMessage = () => {
    !notification.isRead &&
      notificationStore.updateSingleNotificationRead(notificationId);
  };

  switch (notificationType) {
    case NotificationType.BookingRequest:
    case NotificationType.LiveRequest:
    case NotificationType.RemindRequest:
    case NotificationType.StartConsultation:
    case NotificationType.OpenConsultation:
      return () => {
        updateReadMessage();
        consultationStore.consultationToHighlight = notification.consultationId;
        routerStore.push("/consultations");
      };
    case NotificationType.BookingCancelation:
    case NotificationType.LiveCancelation:
    case NotificationType.ClientNoShow:
    case NotificationType.LawyerNoShow:
      return () => {
        updateReadMessage();
        routerStore.push("/consultations/types/canceled");
      };
    default:
      return () => updateReadMessage();
  }
};

interface NotificationRowProps {
  notification: ResNotification;
  dense?: boolean;
}

export default observer(function NotificationRow({
  notification,
  dense,
}: NotificationRowProps) {
  const stores = useStores();
  return (
    <>
      <Wrapper
        isRead={notification.isRead}
        dense={dense}
        onClick={renderOnClick(stores, notification)}
      >
        <MuiBox p={dense ? "20px" : "22px 20px"}>
          <WrapperIcon dense={dense}>
            {renderIcon(notification.notificationType)}
          </WrapperIcon>
        </MuiBox>
        <MuiBox
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          pr="20px"
          flexShrink="1"
        >
          <MuiBox pt="20px" display="flex" pb="10px">
            <WrapperContent dense={dense}>
              {notification.contents}
            </WrapperContent>
          </MuiBox>
          <MuiBox pb="20px">
            {renderTimeFromCreation(notification.createdAt)}
          </MuiBox>
        </MuiBox>
      </Wrapper>
    </>
  );
});
