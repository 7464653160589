import React from "react";
import { Redirect, useRouteMatch } from "react-router-dom";

import { useStores } from "app";

/**
 * Blocks users who have not yet completed onboarding.
 * Assume a user has completed onboarding if they have `accountId`.
 */
export default function CheckOnboarded(props: { children: React.ReactNode }) {
  const { authStore } = useStores();
  const match = useRouteMatch("/onboarding");

  // If user:
  //   * is not on onboarding route, and
  //   * does not have a Stripe account ID:
  if (!match && authStore.isLoggedIn && !authStore.accountId) {
    if (!authStore.firstName) {
      return <Redirect to="/onboarding/profile" />;
    }

    return <Redirect to="/onboarding/bank-account" />;
  }

  // User is all set.
  return <>{props.children}</>;
}
