/**
 * Notice Of Action Notice Type (Used for specifying I-797 type)
 *
 * @export
 * @enum {number}
 */
export enum NoticeType {
  ReceiptNotice = "Receipt Notice",
  PremiumProcessingReceiptNotice = "Premium Processing Receipt Notice",
  ApprovalNotice = "Approval Notice",
  ASCAppointmentNotice = "ASC Appointment Notice",
  TransferNotice = "Transfer Notice",
  InterviewNotice = "Interview Notice",
  RequestForInitialInterview = "Request For Applicant To Appear For Initial Interview",
  NOIR = "NOIR : Notice Of Intent To Revoke",
  NOID = "NOID : Notice Of Intent To Deny",
  Denial = "Decision (Denial)",
  InterviewNoticeI485 = "I-485 Interview Notice",
  CourtesyLetterToApplicant = "Courtesy Letter To Applicant",
  ChangeOfAddress = "Change Of Address",
}
