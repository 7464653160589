import { computed } from "mobx";

import type { Process as ProcessSource } from "apis/common/models";
import Entity from "lib/models/Entity";

export { ProcessSource };

export default class Process extends Entity<ProcessSource> {
  @computed
  get processName(): string {
    return this.source.processName || "";
  }

  @computed
  get processDescription(): string {
    return this.source.processDescription || "";
  }

  @computed
  get formTypes(): number[] {
    return this.source.formTypes || [];
  }
}
