import React from "react";
import styled from "styled-components";
import { Grid as MuiGrid, Box as MuiBox } from "@material-ui/core";
import { GridProps } from "@material-ui/core/Grid";

export interface GridContainerProps extends GridProps {
  /**
   * Grid container width in pixel number
   *
   * @type {number}
   * @memberof GridContainerProps
   */
  width?: number;
}

export const GridContainer = styled(
  ({ width, className, ...props }: GridContainerProps) => (
    <MuiBox className={className}>
      <MuiGrid {...props} container />
    </MuiBox>
  ),
)`
  & {
    width: ${({ width }) => (width ? `${width}px` : "100%")};
  }
`;

export const GridItem = styled(props => <MuiGrid {...props} item />)``;
