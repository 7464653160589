import ThemeInterface from "./ThemeInterface";

const theme: ThemeInterface = {
  primary: "#195dee",
  bgBlueGray: "#f1f3f8",
  blueGray2: "#e3e7f1",
  black: "#000000",
  white: "#ffffff",
  gray1: "#eeeeee",
  gray2: "#dddddd",
  gray3: "#bbbbbb",
  gray4: "#aaaaaa",
  gray5: "#999999",
  gray6: "#666666",
  red: "#ff3333",
  lightPink: "#f8f0f0",

  // legacy styles
  primaryButtonBackground: "white",
  primaryButtonFont: "white",
  secondary: "#d85f20",
  secondaryButtonBackground: "white",
  secondaryButtonFont: "white",
  gray100: "#444a5c",
  gray80: "#676d7e",
  gray60: "#8c909d",
  gray40: "#a5a9b5",
  gray30: "#bec0c8",
  gray20: "#dadbe0",
  gray15: "#D8D8D8",
  gray10: "#f0f2f5",
  red100: "#ed4e4e",
  red80: "#ec6f6f",
  red60: "#f38989",
  red40: "#e9a5a5",
  red20: "#f2c4c4",
  red10: "#fce4e0",
  orange100: "#e8a206",
  orange80: "#e7b036",
  orange60: "#e8bb58",
  orange40: "#e8c477",
  orange20: "#f1d69b",
  orange10: "#f8eed7",
  green100: "#64c717",
  green80: "#7cd736",
  green60: "#95e15a",
  green40: "#a4e174",
  green20: "#bbed95",
  green10: "#e9fdda",
  blue100: "#4882e4",
  blue80: "#6293e6",
  blue40: "#a5bfea",
  blue60: "#86aded",
  blue20: "#bdd1f5",
  blue10: "#e1eafa",
};

export default theme;
