import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import moment from "moment";

import MuiBox from "@material-ui/core/Box";
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MaterialUiPickersDate,
} from "@material-ui/pickers";

import { KeyboardKey } from "lib/enums";

const Wrapper = styled.div`
  display: flex;
`;

const DatePicker = styled(KeyboardDatePicker)`
  & input {
    cursor: pointer;
  }
`;

/**
 * Component that keeps its own date value before the user decides to
 * commit (onChange) or cancel the modified content.
 *
 * @param {KeyboardDatePickerProps} props
 */
const EditableDatePicker = (props: KeyboardDatePickerProps) => {
  /* States */
  const [isEditing, setIsEditing] = useState(false);
  const [date, setDate] = useState(props.value);
  const outerRef = useRef<HTMLDivElement>(null);
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  let inputRef: HTMLInputElement | null = null;

  /* Effects */
  // Detects Editing state and changes input state accordingly
  useEffect(() => {
    if (inputWrapperRef.current) {
      inputRef = inputWrapperRef.current.getElementsByTagName("input")[0];
    }
    if (isEditing) {
      // automatically focus on input on edit
      setFocusInput();
    } else {
      // automatically blur on input on cancel edit
      setBlurInput();
    }
  });

  // Adds event listeners to detect key input
  useEffect(() => {
    function handleKeyUp(event: KeyboardEvent) {
      if (isEditing) {
        switch (event.key) {
          case KeyboardKey.Escape:
          case KeyboardKey.Esc:
            handleOnCancel();
            break;
          case KeyboardKey.Enter:
            handleOnSave();
            break;
          default:
        }
      }
    }

    window.addEventListener("keyup", handleKeyUp);
    return () => window.removeEventListener("keyup", handleKeyUp);
  });

  // Adds event listeners to detect clicks away from the element
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClickAway);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickAway);
    };
  }, [props.value]);

  /* Handlers */
  // Helps setting up call to isEditing state updates
  const handleSetIsEditing = (newIsEditing: boolean) => () => {
    setIsEditing(newIsEditing);
  };

  // Handles when user decides to commit edit content
  const handleOnSave = () => {
    setIsEditing(false);
    props.onChange(date as MaterialUiPickersDate);
  };

  // Handles when user decides to cancel edit content
  const handleOnCancel = () => {
    // Reset value
    setDate(props.value);
    setIsEditing(false);
  };

  // Handles changes to internal date state (temp value while user changes date)
  const handleOnSetDate = (dateValue: moment.Moment) => setDate(dateValue);

  // Deactivates edit view when clicked away
  const handleClickAway = (e: MouseEvent) => {
    if (outerRef.current) {
      if (outerRef.current.contains(e.target as Node)) {
        // inside click
        return;
      }
      // outside click
      handleOnCancel();
    }
  };

  // Sets blur on input element
  const setBlurInput = () => {
    if (inputRef) {
      inputRef.blur();
    }
  };

  // Sets focus on input element
  const setFocusInput = () => {
    if (inputRef) {
      inputRef.focus();
    }
  };

  const { onChange, ...dateFieldProps } = props;

  return (
    <Wrapper ref={outerRef}>
      <MuiBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        padding="10px"
      >
        <DatePicker
          {...dateFieldProps}
          onChange={handleOnSetDate}
          value={date}
          onFocus={handleSetIsEditing(true)}
          hiddenLabel
          margin="dense"
          innerRef={inputWrapperRef}
          onOpen={setFocusInput}
          onClose={setBlurInput}
        />
      </MuiBox>
      {isEditing ? (
        <MuiBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          padding="10px"
        >
          <button onClick={handleOnSave} disabled={props.disabled}>
            Save
          </button>
          <button onClick={handleOnCancel} disabled={props.disabled}>
            Cancel
          </button>
        </MuiBox>
      ) : (
        <MuiBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          padding="10px"
        >
          <button onClick={handleSetIsEditing(true)} disabled={props.disabled}>
            Edit
          </button>
        </MuiBox>
      )}
    </Wrapper>
  );
};

export default EditableDatePicker;
