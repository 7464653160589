import { action, computed, observable, runInAction, autorun } from "mobx";

import Store from "lib/models/Store";
import { LoadingState, PermissionDomain, PermissionType } from "lib/enums";
import { FormSignUp } from "contexts/auth";

import AuthService from "./AuthService";
import RootStore from "app/RootStore";

export default class AuthStore extends Store implements ApiConfiguration {
  @observable public formSignUp: FormSignUp;
  @observable private user: ResUser | null;

  private authService: AuthService;
  private tokenTimer: number;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.authService = new AuthService(this);
    this.formSignUp = new FormSignUp();
    this.refreshTokenSilently();
  }

  @computed
  get isLoggedIn(): boolean {
    return !!this.user;
  }

  /**
   * Determines whether user has completed mandatory-profile onboarding step.
   * Assume it's completed if there is first name.
   */
  @computed
  get hasCompletedMandatoryProfileStep(): boolean {
    return !!this.rootStore.profileStore.profile.name.firstName;
  }

  /**
   * Determines whether user has completed bank-account onboarding step.
   * Assume it's completed if there is Stripe account ID.
   */
  @computed
  get hasCompletedBankAccountStep(): boolean {
    return !!this.accountId;
  }

  @computed
  get email(): string {
    return this.user?.email || "";
  }

  @computed
  get lawyerId(): string | undefined {
    return this.user?.lawyerId;
  }

  @computed
  get accountId(): string | undefined {
    return this.user?.accountId;
  }

  @computed
  get firstName(): string {
    return this.user?.firstName || "";
  }

  @computed
  get userName(): string {
    return (this.user && `${this.user.firstName} ${this.user.lastName}`) || "";
  }

  @computed
  get firebasePassword(): string | undefined {
    return this.user?.firebasePassword;
  }

  @computed
  get firmName(): string {
    return this.user?.firmName || "";
  }

  @computed
  get token(): string | undefined {
    return this.user?.token;
  }

  /**
   * Returns an array of user permissions for a given domain
   *
   * @param {("user" | "client" | "case")} domain
   * @returns {string[]}
   * @memberof AuthStore
   */
  @action.bound
  public listPermissionAt(domain: PermissionDomain): string[] {
    return this.user?.permission?.[domain] ?? [];
  }

  /**
   * Returns a boolean representing a permission for a given domain and a given (list of) permission type
   *
   * @param {PermissionDomain} domain
   * @param {(PermissionType | PermissionType[])} permission
   * @returns {boolean}
   * @memberof AuthStore
   */
  @action.bound
  public checkPermissionAt(
    domain: PermissionDomain,
    permissionType: PermissionType | PermissionType[], // applies OR condition on permission when supplied in array form
  ): boolean {
    const permissionList = this.listPermissionAt(domain);
    if (Array.isArray(permissionType)) {
      return permissionType.reduce(
        (prev, curr) => prev || permissionList.includes(curr),
        false,
      );
    } else {
      return permissionList.includes(permissionType);
    }
  }

  /**
   * @alias AuthStore.refreshToken
   */
  public loginWithToken = this.refreshToken;

  /**
   * Refreshes given authentication token.
   *
   * @param {string} token JWT
   * @returns {Promise<ResUser | null>} User response
   * @memberof AuthStore
   */
  public async refreshToken(token: string): Promise<ResUser | null> {
    try {
      const user = await this.authService.refreshToken(token);
      runInAction(() => this.updateUser(user));
      return user;
    } catch (err) {
      runInAction(this.logout);
      return null;
    }
  }

  /**
   * Login
   *
   * @param {FormLogin} form Login form data
   * @returns {Promise<boolean>} Was the login successful?
   * @memberof AuthStore
   */
  @action.bound
  public async login(form: FormLogin) {
    try {
      this.updateLoadingState(LoadingState.Loading);
      const res = await this.authService.login(form);
      this.updateLoadingState(LoadingState.Loaded);
      this.updateUser(res);
    } catch (e) {
      this.updateLoadingState(LoadingState.Error);
      throw e;
    }
  }

  @action.bound
  public logout(): void {
    this.updateUser(null);
    this.rootStore.routerStore.push("/login");
  }

  @action.bound
  public async signUp() {
    try {
      this.updateLoadingState(LoadingState.Loading);
      const res = await this.authService.signUp(this.formSignUp);
      this.updateLoadingState(LoadingState.Loaded);
      if (res) {
        runInAction(() => {
          this.rootStore.pageStore.openAlert({
            message: "Successfully Signed up",
            confirmMessage: "Go Login",
            onConfirm: () => {
              this.rootStore.routerStore.push("/login");
            },
          });
        });
      }
    } catch (e) {
      this.catchError(e);
    }
  }

  @action.bound
  public async submitChangePassword(data: FormChangePassword) {
    if (!this.user) return;
    try {
      this.updateLoadingState(LoadingState.Loading);
      await this.authService.updatePassword({
        email: this.user.email,
        oldPassword: data.currentPassword,
        newPassword: data.password,
      });
      this.updateLoadingState(LoadingState.Loaded);
    } catch (e) {
      this.updateLoadingState(LoadingState.Error);
      this.rootStore.pageStore.openAlert({
        message: e.message || "Please try it again.",
        isError: true,
      });
      throw e;
    }
  }

  /**
   * Submits server request for setting a password for newly created users
   *
   * @param {string} param.token
   * @param {string} param.email
   * @param {string} param.password
   * @memberof AuthStore
   */
  @action
  public submitSetPassword = async ({
    token,
    email,
    password,
  }: {
    token: string;
    email: string;
    password: string;
  }) => {
    await this.authService.createSetPassword(token, password);
    const res = await this.authService.login({ email, password });
    runInAction(() => {
      this.updateUser(res);
    });
  };

  /**
   * Submits server request for sending a reset password email for users
   *
   * @param {string} email
   * @memberof AuthStore
   */
  @action.bound
  public async submitResetPassword(email: string) {
    try {
      this.updateLoadingState(LoadingState.Loading);
      const res = await this.authService.createResetPassword(email);
      this.updateLoadingState(LoadingState.Loaded);
      if (res) {
        runInAction(() => {
          this.rootStore.pageStore.openAlert({
            message: "Reset Password email sent",
          });
        });
      }
    } catch (e) {
      this.rootStore.pageStore.openAlert({
        message: e.message || "Please try it again.",
        isError: true,
      });
      this.updateLoadingState(LoadingState.Error);
    }
  }

  public readEmail() {
    return this.authService.getEmail();
  }

  @action
  public updateName(firstName: string, lastName: string) {
    if (this.user) {
      this.updateUser({ ...this.user, firstName, lastName });
    }
  }

  @action
  public updateAccountId(accountId: string) {
    if (this.user) {
      this.updateUser({ ...this.user, accountId });
    }
  }

  public async resetFirebasePassword() {
    const res = await this.authService.resetFirebasePassword();
    return res;
  }

  @action.bound
  private updateUser(user?: ResUser | null) {
    if (user) {
      this.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      this.user = null;
      localStorage.removeItem("user");
    }
  }

  /**
   * Silently refreshes authentication token every hour.
   */
  @action.bound
  private refreshTokenSilently() {
    autorun(() => {
      if (this.isLoggedIn) {
        this.tokenTimer = setInterval(() => {
          if (this.token) {
            this.refreshToken(this.token);
          }
        }, 1 * 60 * 60 * 1000); // 1 hour
      } else {
        clearInterval(this.tokenTimer);
      }
    });
  }
}
