import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import React, { createContext, ReactChild } from "react";

const FirebaseChatContext = createContext<firebase.app.App | null>(null);
const APP_NAME = "firebaseChat";

export default ({ children }: { children: ReactChild }) => {
  const chatApp = firebase.apps.filter(app => app.name === APP_NAME);
  if (!chatApp.length) {
    firebase.initializeApp(
      {
        apiKey: "AIzaSyAsN3fhBebx9WNFr-P1GIeVtAJw7PIAeJM",
        authDomain: "tracker-31d5e.firebaseapp.com",
        databaseURL: "https://tracker-31d5e.firebaseio.com",
        projectId: "tracker-31d5e",
        storageBucket: "tracker-31d5e.appspot.com",
        messagingSenderId: "759019709617",
        appId: "1:759019709617:web:33dbfa9303cfe1f6632e90",
        measurementId: "G-J633THYDZD",
      },
      APP_NAME,
    );
  }
  return (
    <FirebaseChatContext.Provider value={firebase.app(APP_NAME)}>
      {children}
    </FirebaseChatContext.Provider>
  );
};

export { FirebaseChatContext };
