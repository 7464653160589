import Service from "lib/models/Service";

import config from "config";
import { PracticeAreaSource } from "../models/PracticeArea";

export default class PracticeAreaService extends Service {
  /**
   * Retrieves a list of practice areas.
   *
   * @memberof AuthService
   */
  public async readListPracticeArea(): Promise<PracticeAreaSource[]> {
    const res = await fetch(`${config.baseHost}/common/v1/speciality-types`);
    return res.json();
  }
}
