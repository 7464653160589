import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import * as React from "react";

import MuiButton from "@material-ui/core/Button";
import MuiTextField from "@material-ui/core/TextField";

import { AuthStore, PageStore } from "app";
import { setCookie } from "lib/cookie";
import { KeyboardKey } from "lib/enums";
import { Helmet, PageLayout, PageLayoutBody } from "components/Layout";

import FormSignUp from "../commands/FormSignUp";

interface SignUpProps {
  authStore?: AuthStore;
  pageStore?: PageStore;
}

@inject("authStore", "pageStore")
@observer
export default class SignUp extends React.Component<SignUpProps> {
  public render() {
    const { formSignUp } = this.props.authStore!;
    return (
      <PageLayout>
        <Helmet title="Sign Up" />
        <PageLayoutBody>
          <form onSubmit={this.handleOnSubmit}>
            <MuiTextField
              type="email"
              name="email"
              label={formSignUp.fields.email}
              value={formSignUp.email}
              onChange={this.handleOnChange("email")}
              onKeyDown={this.handleOnKeyDown}
              autoFocus
            />
            <MuiTextField
              type="password"
              name="password"
              label={formSignUp.fields.password}
              value={formSignUp.password}
              onChange={this.handleOnChange("password")}
              onKeyDown={this.handleOnKeyDown}
            />
            <MuiTextField
              type="password"
              name="passwordConfirm"
              label={formSignUp.fields.passwordConfirm}
              value={formSignUp.passwordConfirm}
              onChange={this.handleOnChange("passwordConfirm")}
            />
            <MuiButton
              variant="contained"
              color="primary"
              onClick={this.handleOnSubmit}
            >
              Sign Up
            </MuiButton>
            <p>
              By creating an account, you agree to Lawfully's{" "}
              <Link to="/terms">Conditions of Use</Link> and{" "}
              <Link to="/privacy">Privacy Notice</Link>.
            </p>
            <p>
              Already have an account? <Link to="/login">Sign in</Link>
            </p>
          </form>
        </PageLayoutBody>
      </PageLayout>
    );
  }

  private handleOnChange = (field: keyof FormSignUp) => (value: any) => {
    const { formSignUp } = this.props.authStore!;
    formSignUp.update({
      [field]: value,
    });
  };

  private handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    if (key === KeyboardKey.Enter) {
      this.props.authStore!.signUp();
    }
  };

  private handleOnSubmit = () => {
    const { formSignUp } = this.props.authStore!;
    this.props.pageStore!.openSpinner();
    formSignUp.validate().then(isValid => {
      this.props.pageStore!.closeSpinner();
      if (isValid) {
        this.props.authStore!.signUp();
        if (formSignUp.remember) {
          setCookie("email", formSignUp.email);
          setCookie("password", formSignUp.password);
        }
      } else {
        this.props.pageStore!.openAlert({
          message: `Please check follows: ${formSignUp.invalidFieldNames}`,
          isError: true,
        });
      }
    });
  };
}
