import factory from "./factory";
export default function requiredIf(
  condition: (form: any, value?: string) => boolean,
  customMessage?: string,
) {
  const defaultMessage = "Required";
  const rule = (x: any) => {
    if (typeof x === "string") {
      return Promise.resolve(x.trim() !== "");
    }
    return Promise.resolve(true);
  };
  return factory(
    defaultMessage,
    customMessage,
    rule,
    (x: string, form: any) => !condition(form, x),
  );
}
