import { match, Route, RouteProps, Switch, Redirect } from "react-router-dom";
import * as React from "react";

import { PageLoading } from "components/Layout";
import FirebaseChatProvider from "lib/contexts/firebaseChat";

const RequestList = React.lazy(() => import("./views/RequestListView"));
const VideoChat = React.lazy(() => import("./views/VideoChatView"));

interface RoutesProps {
  parentPath: string;
  match: match<{}>;
}

class Routes extends React.Component<RoutesProps> {
  public render() {
    const matchedUrl = this.props.match.url;
    return (
      <React.Suspense fallback={<PageLoading />}>
        <FirebaseChatProvider>
          <Switch>
            <Route
              exact
              path={matchedUrl}
              render={() => <Redirect to="/consultations/types/requests" />}
            />
            <Route
              exact
              path={`${matchedUrl}/types/:type?`}
              render={this.renderRequestList}
            />
            <Route path={`${matchedUrl}/:id`} render={this.renderVideoChat} />
          </Switch>
        </FirebaseChatProvider>
      </React.Suspense>
    );
  }

  private renderRequestList = (routerProps: RouteProps) => (
    <RequestList
      {...routerProps}
      trail={[
        {
          name: "Request List",
          path: this.props.match.url,
        },
      ]}
    />
  );

  private renderVideoChat = (routerProps: RouteProps) => (
    <VideoChat
      {...routerProps}
      trail={[
        {
          name: "Video Chat",
          path: this.props.match.url,
        },
      ]}
    />
  );
}

export default Routes;
