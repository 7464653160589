import { computed } from "mobx";

import type { User as UserSource } from "apis/user/models";
import Entity from "lib/models/Entity";
import { PermissionRole } from "lib/enums";

export { UserSource };

export default class User extends Entity<UserSource> {
  @computed
  get name(): string {
    return `${this.source.firstName} ${this.source.lastName}`;
  }

  @computed
  get email(): string {
    return this.source.email;
  }

  @computed
  get isAdmin(): boolean {
    return this.source.isAdmin || false;
  }

  @computed
  get permissionRole(): PermissionRole {
    switch (this.source.permissionRole) {
      case "admin":
        return PermissionRole.Admin;
      case "full_access":
        return PermissionRole.FullAccess;
      default:
        // return Assigned Only as it is the lowest permission role
        return PermissionRole.AssignedOnly;
    }
  }
}
