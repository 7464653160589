import { useState } from "react";

export default function useMoreActionMenu(
  defaultHoverState = false,
  onOverrideHoverState?: (state: boolean) => void,
): [
  boolean,
  (isMouseOver: boolean) => () => void,
  null | HTMLElement,
  boolean,
  (event: React.MouseEvent<HTMLElement>) => void,
  () => void,
  (callback: () => void) => (event: React.MouseEvent<HTMLElement>) => void,
  (event: React.MouseEvent<HTMLElement>) => void,
] {
  // keep track of row's hover status
  const [isRowHovered, setHover] = useState(defaultHoverState);

  // keep track of the actual menu's anchor element
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // state for whether menu is open or not
  let isMoreMenuOpen;

  isMoreMenuOpen = Boolean(anchorEl);

  // returns setter for hover state
  const onChangeHover = (isMouseHover: boolean) => () => setHover(isMouseHover);

  // setter for menu open state
  const handleOnMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    // show menu
    setAnchorEl(event.currentTarget);
  };

  // setter for menu close state
  const handleOnMenuClose = () => {
    // hide menu
    setAnchorEl(null);
    // reset hover state
    setHover(false);
    if (onOverrideHoverState) {
      onOverrideHoverState(false);
    }
  };

  // maker for menu item click
  // receives a callback for execution along with
  // preventive measures for unwanted event triggers
  const makeOnMenuItemClick = (callback: () => void) => (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    callback();
    handleOnMenuClose();
  };

  // setter for menu close
  const onMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleOnMenuClose();
  };

  return [
    isRowHovered, // deprecated
    onChangeHover, // deprecated
    anchorEl,
    isMoreMenuOpen,
    handleOnMenuClick,
    handleOnMenuClose,
    makeOnMenuItemClick,
    onMenuClose,
  ];
}
