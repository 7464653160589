import EventTypeService from "./services/EventTypeService";
import FormTypeService from "./services/FormTypeService";
import ProcessService from "./services/ProcessService";
import PracticeAreaService from "./services/PracticeAreaService";
import { default as CommonStore } from "./CommonStore";
import RootStore from "app/RootStore";

export default function createCommonStore(rootStore: RootStore): CommonStore {
  return new CommonStore(
    rootStore,
    new EventTypeService(rootStore.authStore),
    new FormTypeService(rootStore.authStore),
    new ProcessService(rootStore.authStore),
    new PracticeAreaService(rootStore.authStore),
  );
}
