export enum ConsultationRequestStatus {
  Requested = "requested",
  Rejected = "rejected",
  Expired = "expired",
  Confirmed = "confirmed",
  Completed = "completed",
  ConsultationOpen = "consultation_open",
  LawyerReady = "lawyer_ready",
  ConsultationStarted = "consultation_started",
  CanceledByClient = "canceled_by_client",
  CanceledByLawyer = "canceled_by_lawyer",
}
