export default function field(name: string) {
  return (target: any, key: string) => {
    if (target.fields === undefined) {
      target.fields = {};
    }
    // Since target is now specific to, append properties defined in parent.
    const parentTarget = Object.getPrototypeOf(target);
    if (parentTarget && parentTarget.fields) {
      target.fields = { ...parentTarget.fields, ...target.fields, [key]: name };
    } else {
      target.fields[key] = name;
    }
  };
}
