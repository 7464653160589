import moment from "moment";

export default class WorkingHour {
  static toUTCHour(offset: number, hour: number) {
    return moment()
      .utcOffset(offset)
      .hour(hour);
  }

  /**
   * @param timeOffset UTC offset in hours
   * @param timeStart Start hour
   * @param timeEnd End hour
   */
  constructor(
    private timeOffset: number,
    private timeStart: number,
    private timeEnd: number,
  ) {}

  /**
   * Produces a string representation of working-hour.
   *
   * @returns {string} Today's schedule
   * @example "Tuesday 9:00 am ~ 6:00 pm"
   */
  public toString() {
    const day = moment()
      .utcOffset(this.timeOffset)
      .format("dddd");
    const start = moment()
      .hour(this.start)
      .format("h:00 a");
    const end = moment()
      .hour(this.end)
      .format("h:00 a");
    return `${day} ${start} ~ ${end}`;
  }

  /**
   * Start hour shifted by given offset from UTC.
   *
   * @returns A start hour shifted by offset: [0..23]
   */
  public get start(): number {
    return this.convert(this.timeStart);
  }

  /**
   * End hour shifted by given offset from UTC.
   *
   * @returns An end hour shifted by offset: [1..24]
   */
  public get end(): number {
    // NOTE: If the converted value of end-hour is 0, it means the end of the day,
    // not the beginning. Return 24 instead.
    return this.convert(this.timeEnd) || 24;
  }

  /**
   * Produces an hour shifted by offset.
   *
   * @param hour UTC
   * @returns An hour shifted by offset
   */
  public convert(hour: number): number {
    return Number(
      moment
        .utc()
        .hour(Number(hour))
        .utcOffset(this.timeOffset)
        .format("HH"),
    );
  }
}
