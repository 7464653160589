import { observer } from "mobx-react";
import styled, { ThemeContext } from "styled-components";
import React, { useContext } from "react";
import TagManager from "react-gtm-module";
import {
  Box as MuiBox,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
} from "@material-ui/core";
import { MenuProps as MuiMenuProps } from "@material-ui/core/Menu";
// import { adminHash } from "config"; // TODO: how to deal with admin hash..
import { useStores } from "app";
import { Icon, IconName } from "components/Icons";
import { WordMark } from "components/Symbol";
import { theme as themeColor, ThemeInterface } from "lib/styled";
import { PermissionType, PermissionDomain } from "lib/enums";
import NotificationRow from "contexts/notification/components/NotificationRow";
import config from "config";

import NavItem from "./NavItem";
import BubbleProfileImage from "./BubbleProfileImage";

const NavWrapper = styled.nav`
  position: relative;
  z-index: 9;
  top: 0;
  flex: 0 0 240px;
  overflow: hidden;
  padding: 20px 0;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.white};
`;

const Name = styled.div`
  font-size: 20px;
  line-height: 1.6;
  font-weight: bold;
`;

const Email = styled.div`
  margin-top: 10px;
  font-size: 12px;
  line-height: 1.5;
  color: #8c8c8c;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Version = styled(Email)`
  font-size: 10px;
`;

const NotificationButton = styled.div`
  position: relative;
  overflow: visible;
  background-color: #f3f4f8;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

const NotificationBadge = styled.div`
  padding: 3px 5px;
  background-color: #ff3333;
  color: white;
  font-size: 10px;
  font-weight: bold;
  border-radius: 8px;
  position: absolute;
  line-height: 1;
  left: 21px;
  top: -4px;
`;

const NotificationPopup = styled((props: MuiMenuProps) => (
  <MuiMenu
    {...props}
    classes={{
      paper: "notification-popup-paper-root",
      list: "notification-popup-list-root",
    }}
  />
))`
  &&& {
    .notification-popup-paper-root {
      margin-left: 70px;
      margin-top: 20px;
      box-shadow: 0 0 10px 0 rgba(134, 140, 176, 0.2);
      border: 1px solid ${({ theme }) => theme.gray1};
      overflow-x: hidden;
      overflow-y: auto;
    }

    .notification-popup-list-root {
      padding: 0;
      border-radius: 10px;
      overflow: hidden;
    }
  }
`;

const MenuItem = styled(MuiMenuItem)`
  && {
    width: 390px;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 56px;
  }
`;

const MenuButton = styled.div`
  display: flex;
  color: ${({ theme }) => theme.gray6};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
`;

function ProfileImage({ src }: { src: string }) {
  const theme = useContext<ThemeInterface>(ThemeContext);

  return (
    <MuiBox
      overflow="hidden"
      boxSizing="border-box"
      border={`1px solid ${theme.gray1}`}
      borderRadius={20}
      width={40}
      height={40}
      bgcolor={theme.bgBlueGray}
    >
      <img width="100%" src={src} alt="" />
      <BubbleProfileImage />
    </MuiBox>
  );
}

export default observer(function Nav(props: {}) {
  const {
    authStore,
    routerStore,
    notificationStore,
    profileStore,
  } = useStores();
  const { checkPermissionAt, email, userName } = authStore;
  const { profile } = profileStore;
  const {
    dataNumUnreadNotification,
    dataListNotificationLatest,
    requestBrowserNotificationPermission,
  } = notificationStore;
  const { pathname } = routerStore.location;

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleOnNotificationPopupClose = () => {
    setAnchorEl(null);
  };

  const handleOnLogoutClick = () => {
    tagNavLink("Log out");
    authStore.logout();
  };

  const goTo = (path: string) => {
    routerStore.push(path);
  };

  const handleOnNotificationClick = (
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    tagNavLink("Notification");
    // GET BROWSER NOTIFICATION PERMISSION
    requestBrowserNotificationPermission();
    if (dataListNotificationLatest.data.length > 0) {
      setAnchorEl(event.currentTarget);
    } else {
      routerStore.push("/notifications");
    }
  };

  const handleOnNotificationItemClick = () => {
    tagNotificationPopupItemClick("Notification");
  };

  return (
    <NavWrapper>
      <MuiBox padding="0 30px" paddingBottom="30px">
        <WordMark />
      </MuiBox>
      <MuiBox
        display="flex"
        mb="10px"
        p="0 15px 0 30px"
        justifyContent="space-between"
      >
        <ProfileImage src={profile?.profileImageUrl} />
        <NotificationButton onClick={handleOnNotificationClick}>
          <Icon width={20} height={20} name={IconName.Bell} color="black" />
          {dataNumUnreadNotification > 0 && (
            <NotificationBadge>{dataNumUnreadNotification}</NotificationBadge>
          )}
        </NotificationButton>
        <NotificationPopup
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleOnNotificationPopupClose}
          onClick={handleOnNotificationPopupClose}
        >
          {dataListNotificationLatest.data.length > 0 &&
            dataListNotificationLatest.data.map(notification => (
              <MenuItem
                disableRipple
                disableTouchRipple
                key={notification.notificationId}
                onClick={handleOnNotificationItemClick}
              >
                <NotificationRow notification={notification} dense />
              </MenuItem>
            ))}
          <MenuItem
            onClick={() => {
              tagNotificationPopupItemClick("See More");
              goTo("/notifications");
            }}
          >
            <MenuButton>See more</MenuButton>
          </MenuItem>
        </NotificationPopup>
      </MuiBox>
      <MuiBox padding="0 30px">
        <Name>{userName}</Name>
        <Email>{email}</Email>
      </MuiBox>
      <MuiBox
        margin="15px 0"
        padding="15px"
        borderTop={`1px solid ${themeColor.bgBlueGray}`}
        borderBottom={`1px solid ${themeColor.bgBlueGray}`}
      >
        <NavItem
          label="Home"
          onClick={() => {
            tagNavLink("Home");
            goTo("/");
          }}
          // REROUTE HOME TO CONSULTATIONS FOR NOW
          // active={pathname === "/"}
          active={pathname.startsWith("/consultations")}
          icon={<Icon name={IconName.Home} />}
        />
        <NavItem
          label="Profile"
          onClick={() => {
            tagNavLink("Profile");
            goTo("/profile");
          }}
          active={pathname.startsWith("/profile")}
          icon={<Icon name={IconName.My} />}
        />
        <NavItem
          label="Reviews"
          onClick={() => {
            tagNavLink("Reviews");
            goTo("/reviews");
          }}
          active={pathname.startsWith("/reviews")}
          icon={<Icon name={IconName.Review} />}
        />
        <NavItem
          label="My Stripe"
          onClick={() => {
            tagNavLink("Payout");
            window.open("https://dashboard.stripe.com", "_blank", "noopener");
          }}
          icon={<Icon name={IconName.Payout} />}
        />
        <NavItem
          label="Client"
          onClick={() => goTo("/clients")}
          active={pathname.startsWith("/clients")}
          icon={<Icon name={IconName.Smile} />}
          disabled
        />
        <NavItem
          label="Case"
          onClick={() => goTo("/cases")}
          active={pathname.startsWith("/cases")}
          icon={<Icon name={IconName.Folder} />}
          disabled
        />
        {checkPermissionAt(PermissionDomain.User, PermissionType.Create) && (
          <NavItem
            label="User"
            onClick={() => goTo(`/users`)}
            active={pathname.startsWith("/users")}
            icon={<Icon name={IconName.User} />}
            secondary="Only Admin"
          />
        )}
      </MuiBox>
      <MuiBox paddingLeft="15px" paddingRight="15px">
        <NavItem
          label="Log out"
          onClick={handleOnLogoutClick}
          icon={<Icon name={IconName.Exit} />}
        />
      </MuiBox>
      <MuiBox p="0 30px">
        <Version>{`v${config.version}`}</Version>
      </MuiBox>
    </NavWrapper>
  );
});

// TAG HELPER
const tagNavLink = (value: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "WS_Sidebar_Menu",
      eventData: {
        value,
      },
    },
  });
};

const tagNotificationPopupItemClick = (value: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "WS_Sidebar_NotificationDropDown",
      eventData: {
        value,
      },
    },
  });
};
