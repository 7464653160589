export enum NotificationType {
  LiveRequest = "live_request",
  BookingRequest = "booking_request",
  LiveCancelation = "live_cancelation",
  ClientNoShow = "client_no_show",
  LawyerNoShow = "lawyer_no_show",
  BookingCancelation = "booking_cancelation",
  StartConsultation = "start_consultation",
  RemindRequest = "remind_request",
  OpenConsultation = "open_consultation",
  //...
}
