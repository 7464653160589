import React from "react";
import {
  InputAdornment as MuiInputAdornment,
  Box as MuiBox,
} from "@material-ui/core";
import { TextFieldProps as MuiTextFieldProps } from "@material-ui/core/TextField";

import TextField from "./TextField";
import { Icon, IconName } from "components/Icons";

export default function SearchField(props: MuiTextFieldProps) {
  return (
    <TextField
      {...props}
      InputProps={{
        startAdornment: (
          <MuiInputAdornment position="start">
            <MuiBox m="0 5px">
              <Icon name={IconName.Search} />
            </MuiBox>
          </MuiInputAdornment>
        ),
      }}
    />
  );
}
