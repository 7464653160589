import { action } from "mobx";

import { Form, field } from "lib/form";
import { SortDirection } from "lib/enums";

export default class UserFilter extends Form {
  @field("Site Admin Only")
  public isAdmin: boolean;

  @field("Keyword")
  public keyword: string;

  @field("Sort By")
  public sortBy: string = "id";

  @field("Sort Direction")
  public sortDirection: SortDirection = SortDirection.Desc;

  @action.bound
  public resetFilter() {
    this.update({
      keyword: undefined,
      sortBy: undefined,
      isAdmin: false,
      sortDirection: SortDirection.Desc,
    });
  }
}
