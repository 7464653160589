const { version } = require("../package.json");

export const env = process.env.REACT_APP_DIST_ENV || "dev";

const hash = "DAoD";
export const adminHash = `admin-${hash}`;

const apiVersion = "v1";

const base = {
  version,
  baseHost: "https://dev.lawfully-api.com",
  baseUrl: `https://dev.lawfully-api.com/web/${apiVersion}`,
  baseWebsocketUrl: `wss://dev.lawfully-api.com/ws/${apiVersion}`,
  graphQL: {
    baseUrl:
      "https://he9lyrs61m.execute-api.us-east-1.amazonaws.com/staging/api/v1/",
  },
  gtmId: "GTM-TC3HJ9J",
  amplitudeKey: "1a3587d6fafbc310ddfbd7b778a6f79d",
  firebaseConfig: {
    apiKey: "AIzaSyDhAeVwF5zbkQsF0RXi2ySlTMC9pek2Y2c",
    authDomain: "lawfully-solution.firebaseapp.com",
    databaseURL: "https://lawfully-solution.firebaseio.com",
    projectId: "lawfully-solution",
    storageBucket: "lawfully-solution.appspot.com",
    messagingSenderId: "984264173887",
    appId: "1:984264173887:web:c15aba3462a2c9e0c78b8a",
    measurementId: "G-K2LJSNGBCJ",
  },
  sentryDSN:
    "https://1bcf1d2f233043f0a15266c01b63fde1@o388902.ingest.sentry.io/5226372",
  firebaseConsultCollectionKey: "consult_lawyer_dev",
  agoraAppId: "7067459b00bb41f9b5fde872b12013c4",
  stripePublicKey: "pk_test_eL9n1WyU1HX0XnporLkVxYnO00nOppp1cm",
  stripeClientId: "ca_GocNCCBXxHZJXstc9clpFkBfKh36ELzT",
};

const localConfig = {
  ...base,
  baseHost: "https://dev.lawfully-api.com",
  baseUrl: `https://dev.lawfully-api.com/web/${apiVersion}`,
  baseWebsocketUrl: `wss://dev.lawfully-api.com/ws/${apiVersion}`,
  graphQL: {
    baseUrl: "http://127.0.0.1:9001/api/v1/",
  },
  sentryDSN: null,
};

const prodConfig = {
  ...base,
  baseHost: "https://prod.lawfully-api.com",
  baseUrl: `https://prod.lawfully-api.com/web/${apiVersion}`,
  baseWebsocketUrl: `wss://prod-websocket.lawfully-api.com/ws/${apiVersion}`,
  graphQL: {
    baseUrl:
      "https://yeqzg0hnq4.execute-api.us-east-1.amazonaws.com/productionapi/v1/",
  },
  amplitudeKey: "b4f32de2e586a5071f528915270783af",
  firebaseConfig: {
    apiKey: "AIzaSyDqM8cXFobqIRUkCpYTADHIHBcPgrrpPJI",
    authDomain: "lawfully-web-solution.firebaseapp.com",
    databaseURL: "https://lawfully-web-solution.firebaseio.com",
    projectId: "lawfully-web-solution",
    storageBucket: "lawfully-web-solution.appspot.com",
    messagingSenderId: "815918214138",
    appId: "1:815918214138:web:138579a2db885059d55243",
    measurementId: "G-Z2ETWXFC0P",
  },
  sentryDSN:
    "https://1bcf1d2f233043f0a15266c01b63fde1@o388902.ingest.sentry.io/5226372",
  firebaseConsultCollectionKey: "consult_lawyer_production",
  agoraAppId: "286e93365b5546a1a69ff9a4066ee5d0",
  stripePublicKey: "pk_live_M9ZWTeSB2R0qCo1sXHwEcrRB00mqOKawoL",
  stripeClientId: "ca_GocNRTlxX8jtvRWtFFTaNrteasR29KWG",
};

const stagConfig = {
  ...prodConfig,
  baseHost: "https://staging.lawfully-api.com",
  baseUrl: `https://staging.lawfully-api.com/web/${apiVersion}`,
  baseWebsocketUrl: `wss://staging.lawfully-api.com/ws/${apiVersion}`,
  amplitudeKey: "a42e962ca1c4d4369a776420d2db19ad",
  firebaseConsultCollectionKey: "consult_lawyer_stage",
};

const config = {
  dev: base,
  local: localConfig,
  staging: stagConfig,
  production: prodConfig,
};

export default config[env] || config.dev;
