import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";

import { PageStore } from "app";
import {
  TextField,
  FieldGridContainer,
  FieldGridItem,
  SelectOptionValueType,
} from "components/Form";
import Select from "components/Select";

import FormAddress from "../commands/FormAddress";

interface AddressFormfieldProps {
  onChange: (form: FormAddress) => void;
}

interface InjectedProps extends AddressFormfieldProps {
  pageStore: PageStore;
}

@inject("pageStore")
@observer
export default class AddressFormfield extends React.Component<
  AddressFormfieldProps
> {
  @observable private formAddress = new FormAddress();

  get injectedProps() {
    return this.props as InjectedProps;
  }

  public render() {
    const { fields, errors, touched } = this.formAddress;

    // TODO: enumeration ?
    const detailAddressTypeOptions = ["Apt", "Ste", "Flr"].map(x => ({
      key: x,
      value: x,
      label: x,
    }));
    return (
      <FieldGridContainer>
        <FieldGridItem xs={12}>
          <TextField
            fullWidth
            placeholder={fields.streetNumberAndName}
            value={this.formAddress.streetNumberAndName || ""}
            onChange={this.handleOnChange("streetNumberAndName")}
            error={!!errors.streetNumberAndName && touched.streetNumberAndName}
            helperText={
              touched.streetNumberAndName && errors.streetNumberAndName
            }
          />
        </FieldGridItem>
        <FieldGridItem xs={3}>
          <Select
            options={detailAddressTypeOptions}
            placeholder={fields.detailAddressType}
            value={this.formAddress.detailAddressType || ""}
            onChange={this.handleOnSelectChange("detailAddressType")}
            error={!!errors.detailAddressType && touched.detailAddressType}
            helperText={touched.detailAddressType && errors.detailAddressType}
          />
        </FieldGridItem>
        <FieldGridItem xs={9}>
          <TextField
            fullWidth
            placeholder={fields.detailAddress}
            value={this.formAddress.detailAddress || ""}
            onChange={this.handleOnChange("detailAddress")}
            error={!!errors.detailAddress && touched.detailAddress}
            helperText={touched.detailAddress && errors.detailAddress}
          />
        </FieldGridItem>
        <FieldGridItem xs={6}>
          <TextField
            fullWidth
            placeholder={fields.cityOrTown}
            value={this.formAddress.cityOrTown || ""}
            onChange={this.handleOnChange("cityOrTown")}
            error={!!errors.cityOrTown && touched.cityOrTown}
            helperText={touched.cityOrTown && errors.cityOrTown}
          />
        </FieldGridItem>
        <FieldGridItem xs={6} />
        <FieldGridItem xs={6}>
          <TextField
            fullWidth
            placeholder={fields.state}
            value={this.formAddress.state || ""}
            onChange={this.handleOnChange("state")}
            error={!!errors.state && touched.state}
            helperText={touched.state && errors.state}
          />
        </FieldGridItem>
        <FieldGridItem xs={6}>
          <TextField
            fullWidth
            placeholder={fields.zipCode}
            value={this.formAddress.zipCode || ""}
            onChange={this.handleOnChange("zipCode")}
            error={!!errors.zipCode && touched.zipCode}
            helperText={touched.zipCode && errors.zipCode}
          />
        </FieldGridItem>
        <FieldGridItem xs={6}>
          <TextField
            fullWidth
            placeholder={fields.province}
            value={this.formAddress.province || ""}
            onChange={this.handleOnChange("province")}
            error={!!errors.province && touched.province}
            helperText={touched.province && errors.province}
          />
        </FieldGridItem>
        <FieldGridItem xs={6}>
          <TextField
            fullWidth
            placeholder={fields.postalCode}
            value={this.formAddress.postalCode || ""}
            onChange={this.handleOnChange("postalCode")}
            error={!!errors.postalCode && touched.postalCode}
            helperText={touched.postalCode && errors.postalCode}
          />
        </FieldGridItem>
        <FieldGridItem xs={6}>
          <TextField
            fullWidth
            placeholder={fields.country}
            value={this.formAddress.country || ""}
            onChange={this.handleOnChange("country")}
            error={!!errors.country && touched.country}
            helperText={touched.country && errors.country}
          />
        </FieldGridItem>
        <FieldGridItem xs={6} />
      </FieldGridContainer>
    );
  }

  private handleOnChange = (field: keyof FormAddress) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    this.formAddress.update({
      [field]: value,
    });
    this.props.onChange(this.formAddress);
  };

  private handleOnSelectChange = (field: keyof FormAddress) => (
    value: SelectOptionValueType,
  ) => {
    this.formAddress.update({
      [field]: value,
    });
  };
}
