import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import MuiCheckbox from "@material-ui/core/Checkbox";
import MuiBox from "@material-ui/core/Box";

import { Icon, IconName } from "components/Icons";
import { ThemeInterface } from "lib/styled";

type CheckboxProps = React.ComponentProps<typeof MuiCheckbox>;

const Checkbox = styled(props => (
  <MuiCheckbox
    {...props}
    color="primary" // if ommitted, applies both primary and secondary colors
    disableRipple
    disableFocusRipple
    disableTouchRipple
    classes={{ colorPrimary: "color-primary" }}
    icon={<CheckboxIcon />}
    checkedIcon={<CheckboxIcon checked />}
  />
))<CheckboxProps>`
  &&& {
    color: ${({ theme }) => theme.primary};
    padding: 8px;

    &.color-primary:hover {
      background-color: transparent; // disable hover style
    }
  }
`;

/**
 * Displays checkbox icon.
 */
const CheckboxIcon = ({ checked = false }) => {
  const theme = useContext<ThemeInterface>(ThemeContext);
  return (
    <MuiBox
      width={20}
      height={20}
      display="flex"
      alignItems="center"
      justifyContent="center"
      boxSizing="border-box"
      border={`1px solid ${theme.primary}`}
      borderRadius={3}
      bgcolor={checked ? theme.primary : "transparent"}
      style={{ transition: "background-color 0.1s ease-in-out" }}
    >
      {checked && (
        <MuiBox display="flex" width={16} height={16}>
          <StyledIcon name={IconName.Check} color={theme.white} />
        </MuiBox>
      )}
    </MuiBox>
  );
};

const StyledIcon = styled(Icon)`
  width: 16px;
  height: 16px;
`;

export default Checkbox;
