import factory from "./factory";

export default function equalToField(field: string, customMessage?: string) {
  const defaultMessage = `Please enter the same value as the ${field}.`;
  const rule = (x: string, form: any) => {
    return Promise.resolve(x === form[field]);
  };
  return factory(
    defaultMessage,
    customMessage,
    rule,
    (x: string, form: any) => x === form[field],
  );
}
