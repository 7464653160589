import { computed } from "mobx";
import moment from "moment";
import LoadablePageableData from "lib/models/LoadablePageableData";

export default class LoadablePageableNotificationList extends LoadablePageableData<
  ResNotification[]
> {
  @computed
  public get dataLatestUnread() {
    const filtered = this.data.filter(notification => !notification.isRead);

    return filtered.slice(0, 3);
  }
}
