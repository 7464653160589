import styled, { keyframes } from "styled-components";

const animation = keyframes`
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 200% 50%;
  }

  100% {
    background-position: 400% 50%;
  }
`;

interface SkeletonProps {
  width?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Skeleton = styled.span<SkeletonProps>`
  display: inline-block;
  margin: 0.25em ${props => props.marginRight || 0} 0.25em
    ${props => props.marginLeft};
  width: ${props => props.width || "4em"};
  height: 1em;
  vertical-align: top;
  background: linear-gradient(
    -45deg,
    ${props => props.theme.gray20},
    ${props => props.theme.gray15},
    ${props => props.theme.gray20}
  );
  background-size: 400% 400%;
  border-radius: 2px;
  animation: ${animation} 5s infinite;
`;

export default Skeleton;
