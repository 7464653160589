import { useEffect, useCallback } from "react";
import qs from "query-string";
import { PaginationConfigRequest } from "lib/models/LoadablePageableData";
import { useStores } from "app";
import { useLocation } from "react-router-dom";

/**
 *  Implement a URL synced request hook for pageable data
 *
 * @export
 * @param {PaginationConfigRequest} config
 * @param {(config: PaginationConfigRequest) => void} requestCb
 * a callback to call when a new pagination config is detected.
 * @param {boolean} [activateBool=true]
 * use this boolean to indicate when you want to activate the sync
 * @returns
 */
export default function usePageableUrl(
  config: PaginationConfigRequest,
  requestCb: (config: PaginationConfigRequest) => void,
  activateBool: boolean = true,
) {
  const { routerStore } = useStores();
  const { search, pathname } = useLocation();

  useEffect(() => {
    if (activateBool) {
      const { pageNumber, pageSize } = qs.parse(search);
      if (pageNumber && pageSize) {
        routerStore.replace(
          pushUrl(pathname, pageNumber as string, pageSize as string),
        );
      } else {
        routerStore.replace(
          pushUrl(pathname, config.pageNumber, config.pageSize),
        );
      }
    }
  }, [activateBool, pathname]);

  useEffect(() => {
    const { pageNumber, pageSize } = qs.parse(search);
    if (pageNumber && pageSize && activateBool) {
      requestCb({ pageNumber: Number(pageNumber), pageSize: Number(pageSize) });
    }
  }, [search, activateBool]);

  const pushUrl = useCallback(
    (
      pathname: string,
      pageNumber: string | number,
      pageSize: string | number,
    ) => {
      return `${pathname}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    },
    [pathname],
  );

  const onChangePage = useCallback(
    (pageNumber: number, pageSize: number = config.pageSize) => {
      routerStore.push(
        `${pathname}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      );
    },
    [pathname],
  );

  return onChangePage;
}
