import { useContext } from "react";
import { MobXProviderContext } from "mobx-react";

import RootStore from "./RootStore";

/**
 * React hook. Makes root-store available via `context` instead of `inject`.
 *   - https://mobx-react.js.org/recipes-migration#hooks-to-the-rescue
 */
export default function useStores() {
  return useContext(MobXProviderContext) as RootStore;
}
