import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import MuiBox from "@material-ui/core/Box";
import MuiTypography from "@material-ui/core/Typography";

import { useStores } from "app";
import { Helmet, Stack } from "components/Layout";
import { TextField } from "components/Form";
import { Button } from "components/Button";
import { PageLayout } from "components/Layout";
import ProfileService from 'contexts/profile/ProfileService'

/**
 * TODO: MOVE THIS FEATURE TO INTRA.
 */
const DevInvite = () => {
  const { pageStore } = useStores();
  const { register, errors, handleSubmit } = useForm<{
    email: string;
  }>();
  const [isLoading, setIsLoading] = useState(false);
  const handleOnSubmit = useCallback(
    handleSubmit(async (data: { email: string }) => {
      setIsLoading(true);
      try {
        await new ProfileService().inviteLaywer(data.email);
        pageStore.openAlert({
          message: "An invitation email has been sent!",
        });
      } catch (err) {
        pageStore.openAlert({
          message: err.message || "Something went wrong",
          isError: true,
        });
      } finally {
        setIsLoading(false);
      }
    }),
    [],
  );

  return (
    <PageLayout>
      <MuiBox
        flex={1}
        pb="30vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Helmet title="Send invitation" />

        <MuiBox width="310px" pt={10}>
          <Stack spacing={10}>
            {/* Header */}
            <MuiBox component="header">
              <MuiTypography paragraph component="h2">
                <MuiBox fontSize={30} fontWeight="bold">
                  Send invitation
                </MuiBox>
              </MuiTypography>
            </MuiBox>

            <form onSubmit={handleOnSubmit}>
              <Stack spacing={6}>
                <Stack spacing={3}>
                  <SanitizedTextField
                    type="email"
                    name="email"
                    placeholder="donghee.kim@lawfully.com"
                    fullWidth
                    autoFocus
                    disabled={false}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    InputProps={{
                      inputProps: {
                        ref: register({
                          required: "This field is required",
                        }),
                      },
                    }}
                  />
                </Stack>

                {/* Submit button */}
                <Button
                  type="submit"
                  disabled={isLoading || Object.values(errors).some(Boolean)}
                  onClick={handleOnSubmit}
                >
                  Sign Up
                </Button>
              </Stack>
            </form>
          </Stack>
        </MuiBox>
      </MuiBox>
    </PageLayout>
  );
};

const SanitizedTextField = styled(TextField)`
  &&& {
    margin-bottom: 0;
  }
`;

export default DevInvite;
