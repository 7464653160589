import React, { useCallback, useState, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { TextField as MuiTextField } from "@material-ui/core";
import { TextFieldProps as MuiTextFieldProps } from "@material-ui/core/TextField";
import MuiBox from "@material-ui/core/Box";
import MuiInputAdornment from "@material-ui/core/InputAdornment";
import { ThemeInterface } from "lib/styled";

const TextField: React.FC<{ showCount?: boolean } & MuiTextFieldProps> = ({
  showCount = false,
  ...textFieldProps
}) => {
  const theme = useContext<ThemeInterface>(ThemeContext);
  // State. The number of characters.
  const [count, setCount] = useState(
    (textFieldProps.value as string)?.length ?? 0,
  );
  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      textFieldProps.onChange?.(e);
      setCount(e.target.value.length);
    },
    [textFieldProps],
  );

  if (showCount && textFieldProps.InputProps?.inputProps?.maxLength) {
    return (
      <StyledTextField
        {...textFieldProps}
        InputProps={{
          ...textFieldProps.InputProps,
          endAdornment: (
            <MuiInputAdornment
              position="end"
              classes={{
                positionEnd: "adornment-position-end",
              }}
            >
              <MuiBox fontSize={12} lineHeight={1.83} color={theme.gray5}>
                {count}/{textFieldProps.InputProps.inputProps.maxLength}
              </MuiBox>
            </MuiInputAdornment>
          ),
        }}
        onChange={handleOnChange}
      />
    );
  }

  return <StyledTextField {...textFieldProps} />;
};

const StyledTextField = styled((props: MuiTextFieldProps) => (
  <MuiTextField
    // pass down TextField props
    {...props}
    // always set variant as "outlined"
    variant="outlined"
    // supply props that should be applied to the underlying input component
    InputProps={{
      ...props.InputProps,
      classes: {
        ...(props.InputProps || {}).classes,
        root: "input-root",
        input: "input-base",
        notchedOutline: "input-notched-outline",
        error: "input-error",
        focused: "input-focused",
        multiline: "input-multiline",
        adornedEnd: "input-adorn-end",
      },
    }}
    FormHelperTextProps={{
      classes: { root: "helper-text-root", error: "helper-text-error" },
    }}
  />
))`
  &&& {
    margin-bottom: 16px;

    // target deep components
    .input-notched-outline {
      border-radius: 10px; // input border radius
      border-color: #eeeeee;
    }

    .input-base {
      padding: 13px 16px;
      height: 22px;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.57;
    }

    .input-root:hover .input-notched-outline {
      border-color: ${({ theme }) => theme.primary};
    }

    .input-error .input-notched-outline {
      border-color: #ff3333;
    }

    .input-focused .input-notched-outline {
      border-color: ${({ theme }) => theme.primary};
    }

    .helper-text-root {
      font-size: 12px;
      margin: 10px 0 0;
    }

    .helper-text-error {
      color: #ff3333;
    }

    .input-multiline {
      padding: 0;
      display: flex;
    }

    .input-adorn-end {
      padding-right: 16.8px;
    }
  }
`;

export default TextField;
