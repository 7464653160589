import Service from "lib/models/Service";

import { UserSource } from "./models/User";
import FormUser from "./commands/FormUser";

export interface UserSearchResult {
  ids: string[];
}

export default class UserService extends Service {
  /**
   * Get list of user
   *
   * @returns {Promise<{
   *     result: UserSearchResult;
   *     sources: UserSource[];
   *   }>}
   * @memberof UserService
   */
  public async readListUser(): Promise<{
    result: UserSearchResult;
    sources: UserSource[];
  }> {
    try {
      const res = await this.get("/users");
      if (!res) {
        throw res;
      }
      return {
        result: {
          ids: res.map((x: UserSource) => x.userId),
        },
        sources: res,
      };
    } catch (e) {
      throw e;
    }
  }

  /**
   * Create a single user
   *
   * @param {FormUser} form
   * @returns {Promise<UserSource>}
   * @memberof UserService
   */
  public async createSingleUser(form: FormUser): Promise<UserSource> {
    try {
      const res = await this.post("/users", form.values);
      if (!res) {
        throw res;
      }
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Update a single user
   *
   * @param {string} userId
   * @param {FormUser} form
   * @returns {Promise<UserSource>}
   * @memberof UserService
   */
  public async updateSingleUser(
    userId: string,
    form: FormUser,
  ): Promise<UserSource> {
    try {
      console.log(form, userId);
      const res = await this.put(`/users/${userId}`, form.values);
      if (!res) {
        throw res;
      }
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Delete a single user
   *
   * @param {string} userId
   * @param {FormUser} form
   * @returns {Promise<boolean>}
   * @memberof UserService
   */
  public async deleteSingleUser(userId: string): Promise<boolean> {
    try {
      const res = await this.delete(`/users/${userId}`);
      return !!res;
    } catch (e) {
      throw e;
    }
  }
}
