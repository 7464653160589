import shortid from "shortid";
import styled from "styled-components";
import React from "react";

export const PageTitle = styled.h2`
  font-size: 30px;
  line-height: 1.33;
  color: ${({ theme }) => theme.black};
  margin: 0;
`;

export const PageTitleLight = styled.span`
  font-weight: normal;
`;

export const PageSubtitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  margin: 0 0 30px;
`;

const Header = styled.header`
  background-color: transparent;
  height: auto;
  margin-bottom: 50px;
`;

interface PageHeaderProps {
  trail?: any;
  className?: string;
}

const PageHeader: React.SFC<PageHeaderProps> = ({ children, className }) => (
  <Header id={`pageHeader-${shortid.generate()}`} className={className}>
    {children}
  </Header>
);

export default PageHeader;
