import * as React from "react";

import MuiSelect, { SelectProps } from "@material-ui/core/Select";
import MuiMenuItem from "@material-ui/core/MenuItem";
import MuiInputLabel from "@material-ui/core/InputLabel";
import MuiChip from "@material-ui/core/Chip";
import MuiTypography from "@material-ui/core/Typography";
import MuiFormHelperText from "@material-ui/core/FormHelperText";

import { SelectOption } from "components/Form";

interface MultiSelectProps extends SelectProps {
  options: SelectOption[];
  value: any[];
  label?: string;
  required?: boolean;
  helperText?: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

/**
 * (deprecated)
 * Use Select instead of this component
 *
 * For multi select field with preset
 * It requires options implement by SelectOption or children to select
 *
 * @export
 * @class MultiSelect
 * @extends {React.Component<MultiSelectProps>}
 */
export default class MultiSelect extends React.Component<MultiSelectProps> {
  public render() {
    const {
      children,
      options,
      value,
      label,
      required,
      error,
      helperText,
      ...rest
    } = this.props;

    return (
      <>
        {label && <MuiInputLabel required={required}>{label}</MuiInputLabel>}
        <MuiSelect
          {...rest}
          error={error}
          value={value}
          multiple
          renderValue={this.renderMultipleValue}
        >
          {children
            ? children
            : options.map(option => (
                <MuiMenuItem key={option.key} value={option.value}>
                  <MuiTypography color={option.primary ? "primary" : "inherit"}>
                    {option.label}
                  </MuiTypography>
                  {option.description && (
                    <MuiTypography variant="caption">
                      {` ${option.description}`}
                    </MuiTypography>
                  )}
                </MuiMenuItem>
              ))}
        </MuiSelect>
        {!!helperText && <MuiFormHelperText>{helperText}</MuiFormHelperText>}
      </>
    );
  }

  // An option's human-readable label may be different from its value
  // But we only have a list of actual values, so it needs to filter options which included in its value
  private renderMultipleValue = () => {
    const { options, value } = this.props;
    const selected = options.filter(
      option => value.indexOf(option.value) !== -1,
    );
    return selected.map(({ label, primary }) => (
      <MuiChip
        key={label}
        label={label}
        color={primary ? "primary" : "default"}
        size="small"
      />
    ));
  };
}
