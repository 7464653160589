import { inject, observer } from "mobx-react";
import * as React from "react";

import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiButton from "@material-ui/core/Button";
import MuiFormControl from "@material-ui/core/FormControl";
import MuiTextField from "@material-ui/core/TextField";
import MuiFilledInput from "@material-ui/core/FilledInput";
import MuiInputLabel from "@material-ui/core/InputLabel";
import MuiMenuItem from "@material-ui/core/MenuItem";
import MuiTypography from "@material-ui/core/Typography";

import { PageStore } from "app";
import MultiSelect from "components/MultiSelect";

import CommonStore from "../CommonStore";
import FormProcess from "../commands/FormProcess";

interface AddProcessModalProps {
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface InjectedProps extends AddProcessModalProps {
  pageStore: PageStore;
  commonStore: CommonStore;
}

@inject("pageStore", "commonStore")
@observer
export default class AddProcessModal extends React.Component<
  AddProcessModalProps
> {
  get injectedProps() {
    return this.props as InjectedProps;
  }

  public componentWillMount() {
    const { commonStore } = this.injectedProps;
    const { formCreateProcess, loadListFormType } = commonStore;
    loadListFormType();
    formCreateProcess.reset();
  }

  public render() {
    const { commonStore } = this.injectedProps;
    const { onClose } = this.props;
    const { formCreateProcess, dataListFormTypeOption } = commonStore;
    return (
      <MuiDialog
        open
        onClose={onClose}
        fullWidth
        disableBackdropClick // prevent from accidental closure when entering case data
      >
        <MuiDialogTitle>CREATING A NEW PROCESS</MuiDialogTitle>
        <form>
          <MuiDialogContent>
            <MuiTextField
              fullWidth
              label={formCreateProcess.fields.processName}
              value={formCreateProcess.processName || ""}
              onChange={this.handleOnChange("processName")}
              required
              variant="filled"
              margin="normal"
              error={
                !!formCreateProcess.errors.processName &&
                formCreateProcess.touched.processName
              }
              helperText={formCreateProcess.errors.processName}
            />
            <MuiTextField
              fullWidth
              label={formCreateProcess.fields.processDescription}
              value={formCreateProcess.processDescription || ""}
              onChange={this.handleOnChange("processDescription")}
              variant="filled"
              margin="normal"
              error={
                !!formCreateProcess.errors.processDescription &&
                formCreateProcess.touched.processDescription
              }
              helperText={formCreateProcess.errors.processDescription}
            />
            <MuiFormControl
              fullWidth
              variant="filled"
              margin="normal"
              error={
                !!formCreateProcess.errors.formTypes &&
                formCreateProcess.touched.formTypes
              }
            >
              <MuiInputLabel required>
                {formCreateProcess.fields.formTypes}
              </MuiInputLabel>
              <MultiSelect
                value={formCreateProcess.formTypes || []}
                onChange={this.handleOnChange("formTypes")}
                input={<MuiFilledInput />}
                options={dataListFormTypeOption}
                error={
                  !!formCreateProcess.errors.formTypes &&
                  formCreateProcess.touched.formTypes
                }
                helperText={formCreateProcess.errors.formTypes}
                required
              />
            </MuiFormControl>
          </MuiDialogContent>
          <MuiDialogActions>
            <MuiButton onClick={onClose}>Dismiss</MuiButton>
            <MuiButton onClick={this.handleOnSubmit} color="primary">
              Submit
            </MuiButton>
          </MuiDialogActions>
        </form>
      </MuiDialog>
    );
  }

  private handleOnChange = (field: keyof FormProcess) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { formCreateProcess } = this.injectedProps.commonStore;
    const { value } = event.target;
    formCreateProcess.update({
      [field]: value,
    });
  };

  private handleOnSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    const {
      submitAddSingleProcess,
      formCreateProcess,
    } = this.injectedProps.commonStore;
    formCreateProcess.validate().then(isValid => {
      if (isValid) {
        submitAddSingleProcess();
        this.props.onClose(event);
      } else {
        this.injectedProps.pageStore.openAlert({
          message: `Please check follows: ${formCreateProcess.invalidFieldNames}`,
          isError: true,
        });
      }
    });
  };
}
