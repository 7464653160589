import { Link } from "react-router-dom";
import styled from "styled-components";
import React from "react";

const Wrapper = styled.div`
  font-size: 14px;
  line-height: 1.57;
  color: ${({ theme }) => theme.gray6};

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.gray6};

    :hover {
      text-decoration: underline;
    }
  }
`;

interface Trail {
  name: string;
  path?: string;
}

interface BreadcrumbProps {
  trail: Trail[];
}

export default function Breadcrumb({ trail }: BreadcrumbProps) {
  const currentIndex = trail.length - 1;
  return (
    <Wrapper>
      {trail.map(({ name, path }, i) => (
        <React.Fragment key={i}>
          {!!path ? <Link to={path}>{name}</Link> : <span>{name}</span>}
          {i < currentIndex ? " > " : null}
        </React.Fragment>
      ))}
    </Wrapper>
  );
}

export type { Trail };
