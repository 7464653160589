import { observable, computed, action } from "mobx";
import LoadableData from "./LoadableData";

export interface PaginationConfigRequest {
  pageNumber: number;
  pageSize: number;
}

export interface PaginationConfig extends PaginationConfigRequest {
  totalCount: number;
}

export interface PageableData<T> extends PaginationConfig {
  items: T;
}

class LoadablePageableData<T> extends LoadableData<T> {
  @observable protected pageNumber: number;
  @observable protected pageSize: number;
  @observable protected totalCount: number;

  constructor(defaultData: PageableData<T>) {
    const { pageNumber, pageSize, totalCount } = defaultData;
    super(defaultData.items);
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.totalCount = totalCount;
  }

  @action.bound
  public updatePageableData(dataSource: PageableData<T>) {
    const { items, pageNumber, pageSize, totalCount } = dataSource;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.totalCount = totalCount;
    this.updateData(items);
  }

  @computed
  public get currentConfig(): PaginationConfig {
    return {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      totalCount: this.totalCount,
    };
  }
}

export default LoadablePageableData;
