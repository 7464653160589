import { observable, action, autorun } from "mobx";

import RootStore from "app/RootStore";
import Store from "lib/models/Store";
import LoadableData from "lib/models/LoadableData";
import { PaginationConfigRequest } from "lib/models/LoadablePageableData";
import { PushStreamType } from "lib/enums";

import ConsultationService from "./ConsultationService";
import { LoadableRequestList, LoadablePageableRequestList } from "./models";

export default class ConsultationStore extends Store {
  // Data
  @observable public dataListRequest = new LoadableRequestList([]);
  @observable
  public dataListRequestCompleted = new LoadablePageableRequestList({
    items: [],
    pageNumber: 1,
    pageSize: 10,
    totalCount: 0,
  });
  @observable
  public dataListRequestCanceled = new LoadablePageableRequestList({
    items: [],
    pageNumber: 1,
    pageSize: 10,
    totalCount: 0,
  });
  @observable public dataConsultation = new LoadableData<ResConsultationDetail>(
    {
      consultationId: "-1",
      status: "",
      client: {
        clientName: "",
        clientId: 0,
      },
      contents: "",
      appointmentTime: "",
      startAt: "",
      cancelBy: "",
      expiredAt: "",
      endAt: "",
      sessionDuration: 0,
      isLive: false,
      uscisCases: [],
    },
  );
  @observable
  public dataConsultationChatDetail = new LoadableData<
    ResConsultationChatDetail
  >({
    chatChannelName: "",
    agoraToken: "",
    consultationId: "",
    email: "",
  });
  @observable public dataIsOnline = false;
  @observable public consultationToHighlight: string | null = null;

  private consultationService: ConsultationService;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.consultationService = new ConsultationService(rootStore.authStore);
  }

  @action.bound
  public async readDataListRequest() {
    try {
      this.dataListRequest.setIsLoading();
      const data = await this.consultationService.getListRequest();
      this.dataListRequest.updateData(data);
    } catch (e) {
      this.dataListRequest.setHasErrored();
    }
  }

  @action.bound
  public async readDataListRequestCompleted(
    config: PaginationConfigRequest = {
      pageSize: this.dataListRequestCompleted.currentConfig.pageSize,
      pageNumber: 1,
    },
  ) {
    try {
      this.dataListRequestCompleted.setIsLoading();
      const data = await this.consultationService.getListRequestCompleted(
        config,
      );
      this.dataListRequestCompleted.updatePageableData(data);
    } catch (e) {
      this.dataListRequestCompleted.setHasErrored();
    }
  }

  @action.bound
  public async readDataListRequestCanceled(
    config: PaginationConfigRequest = {
      pageSize: this.dataListRequestCanceled.currentConfig.pageSize,
      pageNumber: 1,
    },
  ) {
    try {
      this.dataListRequestCanceled.setIsLoading();
      const data = await this.consultationService.getListRequestCanceled(
        config,
      );
      this.dataListRequestCanceled.updatePageableData(data);
    } catch (e) {
      this.dataListRequestCanceled.setHasErrored();
    }
  }

  @action.bound
  public async readDataConsultation(consultationId: string) {
    try {
      this.dataConsultation.setIsLoading();
      const data = await this.consultationService.getConsultation(
        consultationId,
      );
      this.dataConsultation.updateData(data);
    } catch (e) {
      this.dataConsultation.setHasErrored();
      throw e;
    }
  }

  @action.bound
  public async readDataConsultationChatDetail(consultationId: string) {
    try {
      this.dataConsultationChatDetail.setIsLoading();
      const data = await this.consultationService.getConsultationChatDetail(
        consultationId,
      );
      this.dataConsultationChatDetail.updateData(data);
    } catch (e) {
      this.dataConsultationChatDetail.setHasErrored();
      throw e;
    }
  }

  /**
   * Read lawyer's live consultation status
   *
   * @memberof ConsultationStore
   */
  @action.bound
  public async readStatusOnline() {
    try {
      const data = await this.consultationService.getStatusOnline();
      this.dataIsOnline = data.isOnline;
    } catch (e) {
      // FAIL SLIENTLY
    }
  }

  /**
   * Update lawyer's live consultation status
   *
   * @param {boolean} isOnline
   * @memberof ConsultationStore
   */
  @action.bound
  public async updateStatusOnline(isOnline: boolean) {
    try {
      this.dataIsOnline = isOnline;
      await this.consultationService.putStatusOnline(isOnline);
    } catch (e) {
      this.dataIsOnline = false;
    }
  }

  /**
   * Update lawyer's response to a request
   *
   * @param {string} consultationId
   * @param {boolean} willAccept
   * @param {RequestCancelType} [cancelType]
   * @param {string} [reason]
   * @memberof ConsultationStore
   */
  @action.bound
  public async updateAcceptRequest(
    consultationId: string,
    willAccept: boolean,
    cancelType?: RequestCancelType,
    reason?: string,
  ) {
    try {
      await this.consultationService.putAcceptRequest(
        consultationId,
        willAccept,
        cancelType,
        reason,
      );
      await this.readDataListRequest();
    } catch (e) {
      throw e;
    }
  }

  /**
   * Cancel booked request
   *
   * @param {string} requestId
   * @param {RequestCancelType} cancelType
   * @param {string} reason
   * @memberof ConsultationStore
   */
  @action.bound
  public async updateCancelRequest(
    requestId: string,
    cancelType: RequestCancelType,
    reason: string,
  ) {
    try {
      await this.consultationService.postCancelRequest(
        requestId,
        cancelType,
        reason,
      );
      await this.readDataListRequest();
    } catch (e) {
      throw e;
    }
  }

  /**
   * Report a client
   *
   * @param {string} consultationId
   * @param {string} reason
   * @memberof ConsultationStore
   */
  @action.bound
  public async updateReportClient(consultationId: string, reason: string) {
    try {
      await this.consultationService.postReportClient(consultationId, reason);
    } catch (e) {
      throw e;
    }
  }
}
