import RootStore from "./RootStore";

class UserPreferenceStore {
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  public set(key: string, value: any) {
    if (this.rootStore.authStore.isLoggedIn) {
      localStorage.setItem(
        `${this.rootStore.authStore.email}.${key}`,
        JSON.stringify(value),
      );
    } else {
      throw new Error("User preferences cannot be stored");
    }
  }

  public get(key: string): any {
    if (this.rootStore.authStore.isLoggedIn) {
      const localStorageItem = localStorage.getItem(
        `${this.rootStore.authStore.email}.${key}`,
      );
      return localStorageItem ? JSON.parse(localStorageItem) : undefined;
    } else {
      throw new Error("User preferences are not available");
    }
  }
}

export default UserPreferenceStore;
