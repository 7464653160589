import { action, observable, runInAction } from "mobx";
import React from "react";

import { ToastType } from "lib/enums";
import RootStore from "./RootStore";

export interface AlertInterface {
  /**
   * (deprecated)
   * Title will no longer be displayed. Move appropriate content to message.
   *
   * @type {string}
   * @memberof AlertInterface
   */
  title_deprecated?: string; // tslint:ignore // this is deprecated
  message: React.ReactNode | string;
  confirmMessage?: string;
  cancelMessage?: string;
  customMessage?: string;
  isNegative?: boolean;
  isError?: boolean;
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  onCustom?: () => void;
  noButtons?: boolean;
}

export interface PromptInterface {
  title: string;
  message?: string;
  initialValue?: string;
  hintText?: string;
  validator?: (value: string) => boolean;
  confirmMessage?: string;
  cancelMessage?: string;
  onConfirm: (value: string) => void;
  onCancel?: () => void;
}

export interface ToastOptionInterface {
  /**
   * millisecond (1000 ms = 1 sec)
   *
   * @type {number}
   * @memberof ToastOptionInterface
   */
  duration?: number;
  content?: React.ReactNode;
  icon?: React.ReactNode;
  type?: ToastType;
  /**
   * toast close button can be appeared
   *
   * @type {boolean}
   * @memberof ToastOptionInterface
   */
  interactive?: boolean;
}

export default class PageStore {
  // spinner
  @observable public isSpinnerShow: boolean = false;
  @observable public spinnerMessage?: string;

  // alert
  @observable public isAlertShow: boolean;
  @observable public alert: AlertInterface;

  // prompt
  @observable public isPromptShow: boolean;
  @observable public prompt: PromptInterface;

  // toast
  @observable public isToastShow: boolean;
  @observable public toastOptions: ToastOptionInterface;
  @observable public rootContainer: () => HTMLElement;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.rootContainer = () => document.getElementById("main")!;
  }

  @action.bound
  public openSpinner(content?: string) {
    this.isSpinnerShow = true;
    this.spinnerMessage = content;
  }

  @action.bound
  public closeSpinner() {
    this.isSpinnerShow = false;
  }

  @action.bound
  public openAlert(options?: AlertInterface) {
    this.isAlertShow = true;
    if (options) {
      this.alert = options;
    }
  }

  @action.bound
  public closeAlert() {
    this.isAlertShow = false;
  }

  @action.bound
  public openPrompt(options?: PromptInterface) {
    this.isPromptShow = true;
    if (options) {
      this.prompt = options;
    }
  }

  @action.bound
  public closePrompt() {
    this.isPromptShow = false;
  }

  @action.bound
  public openToast(content: string, options?: ToastOptionInterface) {
    this.isToastShow = false;
    setTimeout(() => {
      runInAction(() => {
        this.isToastShow = true;
        this.toastOptions = {
          content,
          duration: options ? options.duration : 1000,
          ...options,
        };
      });
    }, 100);
  }

  @action.bound
  public closeToast() {
    this.isToastShow = false;
    this.toastOptions = {
      duration: 1000,
      type: ToastType.Info,
    };
  }

  @action.bound
  public openPage(
    address: string,
    e?: React.MouseEvent<
      HTMLTableRowElement | HTMLDivElement | HTMLButtonElement
    >,
  ) {
    if (e && (e.metaKey || e.ctrlKey)) {
      window.open(address, "_blank");
    } else {
      this.rootStore.routerStore.push(address);
    }
  }

  @action.bound
  public replacePage(address: string) {
    this.rootStore.routerStore.replace(address);
  }
}
