import React, { createContext, ReactChild } from "react";
import firebase from "firebase/app";
import config from "config";

const FirebaseContext = createContext<firebase.app.App | null>(null);

export default ({ children }: { children: ReactChild }) => {
  if (!firebase.apps.length) { // SHOULD BE THE FIRST APP TO INITIALIZE
    firebase.initializeApp(config.firebaseConfig);
  }
  return (
    <FirebaseContext.Provider value={firebase.app()}>{children}</FirebaseContext.Provider>
  );
};

export { FirebaseContext };
