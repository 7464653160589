import React from "react";
import styled from "styled-components";
import { Switch as MuiSwitch } from "@material-ui/core";
import { SwitchProps as MuiSwitchProps } from "@material-ui/core/Switch";

const Switch = styled((props: MuiSwitchProps) => (
  <MuiSwitch
    // pass down Switch props
    {...props}
    color="primary"
    disableRipple
    disableFocusRipple
    disableTouchRipple
    classes={{
      switchBase: "switch-base",
      thumb: "switch-thumb",
      track: "switch-track",
      checked: "switch-checked",
    }}
  />
))`
  &&& {
    width: 62px;
    height: 36px;
    padding: 0;

    // target deep components
    .switch-track {
      opacity: 1;
      border-radius: 26px;
      background-color: #bbbbbb;
    }

    .switch-checked + .switch-track {
      background-color: ${({ theme }) => theme.primary};
    }

    .switch-base {
      background-color: transparent;
      padding: 5px;
    }

    .switch-base {
      background-color: transparent;
      padding: 5px;
    }

    .switch-base.switch-checked {
      transform: translateX(26px);
    }

    .switch-thumb {
      width: 26px;
      height: 26px;
      background-color: white;
    }

    &.switch-checked {
      background-color: ${({ theme }) => theme.primary};
    }
  }
`;

export default Switch;
