import shortid from "shortid";
import styled from "styled-components";
import React from "react";

const Body = styled.div`
  position: relative;
  padding: 30px;
  overflow: hidden;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.white};

  flex: 1 0 auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-top: 0;
  }
`;

interface PageBodyProps {
  className?: string;
}

const PageBody: React.SFC<PageBodyProps> = ({ children, className }) => (
  <Body id={`pageBody-${shortid.generate()}`} className={className}>
    {children}
  </Body>
);

export default PageBody;
