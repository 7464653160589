import { action, observable, computed } from "mobx";
import { LoadingState } from "lib/enums";

/**
 * Represents a class that has a loading state attached
 *
 * @class LoadableData
 * @template T
 */
class LoadableData<T> {
  @observable protected dataSource: T;
  @observable protected state: LoadingState = LoadingState.Initial;

  constructor(defaultData: T) {
    this.dataSource = defaultData;
  }

  @action.bound
  public updateData(dataSource: T) {
    this.setHasLoaded();
    this.dataSource = dataSource;
  }

  @action.bound
  public updateState(state: LoadingState) {
    this.state = state;
  }

  @computed
  public get data(): T {
    return this.dataSource;
  }

  // CONVENIENCE METHODS

  @action.bound
  public setIsLoading() {
    this.state = LoadingState.Loading;
  }

  @action.bound
  public setHasLoaded() {
    this.state = LoadingState.Loaded;
  }

  @action.bound
  public setHasErrored() {
    this.state = LoadingState.Error;
  }

  @action.bound
  public resetState() {
    this.state = LoadingState.Initial;
  }

  @computed
  public get loadingState(): LoadingState {
    return this.state;
  }

  @computed
  public get isLoading(): boolean {
    return this.state === LoadingState.Loading;
  }

  @computed
  public get hasLoaded(): boolean {
    return this.state === LoadingState.Loaded;
  }

  @computed
  public get hasErrored(): boolean {
    return this.state === LoadingState.Error;
  }
}

export default LoadableData;
