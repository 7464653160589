import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider } from "mobx-react";
import { syncHistoryWithStore } from "mobx-react-router";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import amplitude from "amplitude-js";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/browser";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import RootStore from "app/RootStore";

import reportWebVitals from "./reportWebVitals";
import config, { env } from "./config";
import Routes from "./app";
import { theme } from "lib/styled";
import FirebaseProvider from "lib/contexts/firebase";
import ErrorBoundary from "components/ErrorBoundary";
import CheckDevice from "components/CheckDevice";
import CheckBrowser from "components/CheckBrowser";
import CheckAuth from "components/CheckAuth";
import CheckOnboarded from "components/CheckOnboarded";
import { PageLoading } from "components/Layout";
import PrivateRoute from "app/PrivateRoute";
import DevInvite from "contexts/auth/views/DevInvite";

const RoutesMobile = React.lazy(() => import("app/RoutesMobile"));

if (config.sentryDSN) {
  Sentry.init({
    dsn: config.sentryDSN,
    release: `${env}-v${config.version}`,
    environment: env,
  });
}
const stripePromise = loadStripe(config.stripePublicKey);

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: ["DM Sans", "Roboto", "sans-serif"].join(", "),
    h1: {
      lineHeight: 40 / 30,
      fontSize: 30,
      fontWeight: "bold",
    },
    h2: {
      lineHeight: 32 / 20,
      fontSize: 20,
      fontWeight: "bold",
    },
    h3: {
      lineHeight: 24 / 18,
      fontSize: 18,
      fontWeight: "bold",
    },
    h4: {
      lineHeight: 24 / 14,
      fontSize: 14,
      fontWeight: "bold",
    },
    body1: {
      lineHeight: 22 / 14,
      fontSize: 14,
    },
    body2: {
      lineHeight: 24 / 16,
      fontSize: 16,
    },
    caption: {
      lineHeight: 18 / 12,
      fontSize: 12,
      color: theme.gray6,
    },
  },
  palette: {
    primary: {
      main: "#195dee",
    },
  },
  spacing: 5,
  shape: {
    borderRadius: 10,
  },
});

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    font-family: "DM Sans", "Roboto", sans-serif;
    font-size: 87.5%; /* 14px */
    height: 100%;
    line-height: 1.8em;
    position: relative;
    background: ${theme.bgBlueGray};
    @media only screen and (min-resolution: 192dpi) {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
  a {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: ${theme.primary};
    }
  }
  #root {
    height: 100%;
  }
  input,
  textarea {
    border-radius: 0;
  }
  ::selection {
    color: ${theme.primaryButtonFont};
    background: ${theme.primary}
  }
  ::-moz-selection {
    color: ${theme.primaryButtonFont};
    background: ${theme.primary}
  }
`;

// tslint:disable:no-console
console.log(
  `%cWelcome to Lawfully Tracker v${config.version} [${env.toUpperCase()}]`,
  "font-size: 24px; color: #264381; padding: 2px 5px;",
);
// tslint:enable:no-console

const stores = new RootStore();
const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, stores.routerStore);

// INIT GTM
TagManager.initialize({
  gtmId: config.gtmId,
  dataLayer: {
    gaMeasurementId: config.firebaseConfig.measurementId,
  },
});

// INIT AMPLITUDE
window.amplitude = amplitude; // expose amplitude to windows for access in GTM
amplitude.getInstance().init(config.amplitudeKey);

ReactDOM.render(
  <ErrorBoundary>
    <GlobalStyle />
    <FirebaseProvider>
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Elements stripe={stripePromise}>
              <Provider {...stores}>
                <Router history={history}>
                  <React.Suspense fallback={<PageLoading />}>
                    <Switch>
                      <Route path="/m" component={RoutesMobile} />
                      <Route path="*">
                        <CheckDevice>
                          <CheckBrowser>
                            <CheckAuth>
                              <Switch>
                                <PrivateRoute
                                  exact
                                  path="/invite"
                                  component={DevInvite}
                                />
                                <Route path="*">
                                  <CheckOnboarded>
                                    <Routes />
                                  </CheckOnboarded>
                                </Route>
                              </Switch>
                            </CheckAuth>
                          </CheckBrowser>
                        </CheckDevice>
                      </Route>
                    </Switch>
                  </React.Suspense>
                </Router>
              </Provider>
            </Elements>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </FirebaseProvider>
  </ErrorBoundary>,
  document.getElementById("root"),
);

// Send results of Web Vitals metrics to Google Tag Manager.
//   - https://github.com/GoogleChrome/web-vitals
reportWebVitals(({ name, delta, id }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "web-vitals",
      event_category: "Web Vitals",
      event_action: name,
      event_value: Math.round(name === "CLS" ? delta * 1000 : delta),
      event_label: id,
    },
  });
});
