// New Permission Type Structure
export enum PermissionType {
  Create = "Create",
  Read = "Read",
  Update = "Update",
  Delete = "Delete",
  ReadAssigned = "ReadAssigned",
  UpdateAssigned = "UpdateAssigned",
  DeleteAssigned = "DeleteAssigned",
}

/**
 * Represents the domain for which permission is assigned
 *
 * @export
 * @enum {number}
 */
export enum PermissionDomain {
  Case = "case", // it seems as though mobx internally converts capitalized property keys to lowercase
  Client = "client",
  User = "user",
}

// Permission Role enum for use within the app
export enum PermissionRole {
  Admin = "Admin",
  FullAccess = "Full Access",
  AssignedOnly = "Assigned Only",
}
