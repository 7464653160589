import { observable, autorun, action } from "mobx";
import moment from "moment";
import { RootStore } from "app";
import Store from "lib/models/Store";
import { PushStreamType } from "lib/enums";
import config from "config";

export default class StreamStore extends Store {
  private connection: WebSocket | null;
  private connectionId?: string;
  private retryIntervalId?: number;

  constructor(rootStore: RootStore) {
    super(rootStore);
    autorun(() => {
      if (this.rootStore.authStore.lawyerId) {
        // CLOSE PREVIOUSLY OPENED CONNECTIONS
        this.disconnect();
        // SUPPLY WITH ENCRYPTED lawyerId
        this.connect(this.rootStore.authStore.lawyerId);
      }
    });
  }

  @observable public latestStream: Stream;

  @observable public streams: Stream[] = [];

  @action.bound
  public connect(id: string) {
    try {
      console.log(moment().format("hh:mm:ss"), "connecting");
      const ws = new WebSocket(`${config.baseWebsocketUrl}/lawyers/${id}`);

      this.connection = ws;
      this.connectionId = id;

      this.connection.onopen = this.onOpen;
      this.connection.onmessage = this.onMessage;
      this.connection.onclose = this.onClose;
    } catch (e) {
      // ACTUALLY WEBSOCKET INSTANTIATION EXCEPTIONS ARE NOT CAUGHT BY DESIGN
      // https://stackoverflow.com/questions/31002592/javascript-doesnt-catch-error-in-websocket-instantiation/31003057
      console.warn("websocket connection establishment failed."); // WE WILL NEVER SEE THIS
    }
  }

  @action.bound
  public disconnect() {
    if (this.connection) {
      console.log(moment().format("hh:mm:ss"), "ws disconnecting");
      this.connection.close();
    }
  }

  @action.bound
  public reconnect() {
    if (this.connectionId && !this.connection) {
      console.log(moment().format("hh:mm:ss"), "ws reconnecting");
      this.connect(this.connectionId);
    }
  }

  @action.bound
  public onOpen() {
    console.log(moment().format("hh:mm:ss"), "ws connection open");
  }

  @action.bound
  public onClose() {
    console.log(moment().format("hh:mm:ss"), "ws connection closed");
    this.connection = null;
    if (!this.retryIntervalId) {
      const intervalId = setInterval(() => {
        if (!this.connection) {
          // RETRY CONNECTION IF CONNECTION DOESN'T EXIST
          this.reconnect();
        } else {
          // CLEAR RETRY INTERVAL IF CONNECTION EXISTS
          clearInterval(intervalId);
          this.retryIntervalId = undefined;
        }
      }, 5000);
      this.retryIntervalId = intervalId;
    }
  }

  @action.bound
  public onMessage(evt: MessageEvent) {
    try {
      const parsedData: {
        consultationId: string;
        eventType: string;
      } = JSON.parse(evt?.data);
      // LOG OUT MULTIPLE CONNECTIONS
      if (
        parsedData.eventType === PushStreamType.DisconnectExistingConnection
      ) {
        if (this.connection) {
          this.connection.onclose = () =>
            console.log(
              moment().format("hh:mm:ss"),
              "ws connection closed. won't reconnect",
            );
          this.disconnect();
        }
        this.rootStore.pageStore.openAlert({
          message: `Multiple connections detected.
          Refresh the page to revalidate the session.`,
          noButtons: true,
        });
      } else {
        this.streams.push(parsedData);
        this.latestStream = parsedData;
      }
    } catch (e) {
      console.warn("message parse failed.");
    }
  }
}
