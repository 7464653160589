import Service from "lib/models/Service";

import { EventTypeSource } from "../models/EventType";

export default class EventTypeService extends Service {
  /**
   * Get list of event type
   *
   * @returns {Promise<EventTypeSource[]>}
   * @memberof EventTypeService
   */
  public async readListEventType(): Promise<EventTypeSource[]> {
    try {
      const res = await this.get("/event-types");
      return res;
    } catch (e) {
      throw e;
    }
  }
}
