import { Form, field, required, email, equalToField } from "lib/form";

export default class FormSignUp extends Form {
  @field("Email")
  @email()
  @required()
  public email: string;

  @field("Password")
  @required()
  public password: string;

  @field("Re-enter Password")
  @required()
  @equalToField("password")
  public passwordConfirm: string;

  @field("Name")
  @required()
  public name: string;
}
