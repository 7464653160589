export function setCookie(
  cname: string,
  cvalue: string | number,
  exdays: number = 365,
) {
  const date = new Date();
  date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
  document.cookie = `${cname}=${cvalue};expires=${date.toUTCString()};path=/`;
}

export function getCookie(cname: string) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  let value = "";
  decodedCookie.split(";").forEach(c => {
    let cookie = c;
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      value = cookie.substring(name.length, cookie.length);
    }
  });
  return value;
}
