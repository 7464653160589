import { inject, observer } from "mobx-react";
import styled from "styled-components";
import React from "react";

import MuiIconButton from "@material-ui/core/IconButton";
import MuiSnackbar from "@material-ui/core/Snackbar";
import MuiSlide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions/transition";

import { PageStore } from "app";
import { Icon, IconName } from "components/Icons";
import { ToastType } from "lib/enums";

const ToastWrapper = styled(props => (
  <MuiSnackbar
    {...props}
    ContentProps={{
      classes: {
        root: "content-root",
        message: "content-message",
      },
    }}
  />
))`
  &&& {
    .content-root {
      background: ${({ theme }) => theme.white};
      color: ${({ theme }) => theme.black};
      padding: 18px 20px;
      border-radius: 10px;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    }

    .content-message {
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
  }
`;

const ToastMessage = styled.div`
  white-space: pre-line;
  font-size: 14px;
  line-height: 1.57;
  margin: 0 20px;
`;

const ToastCloseIconButton = styled(MuiIconButton)`
  padding: 0;
`;

interface InjectedProps {
  pageStore: PageStore;
}

interface ToastState {
  isClosing?: boolean;
}

@inject("pageStore")
@observer
export default class Toast extends React.Component<{}, ToastState> {
  get injectedProps() {
    return this.props as InjectedProps;
  }

  public state = {
    isClosing: false,
  };

  private toastTimer: number;
  private mounted: boolean;

  public componentDidMount() {
    this.mounted = true;
    this.toastTimer = window.setTimeout(
      this.setToastTimeout,
      (this.injectedProps.pageStore.toastOptions
        ? this.injectedProps.pageStore.toastOptions.duration || 1000
        : 1000) + 500,
    );
  }

  public componentWillUpdate(prevProps: {}) {
    const prevInjectedProps = prevProps as InjectedProps;
    if (
      prevInjectedProps.pageStore.isToastShow !==
      this.injectedProps.pageStore.isToastShow
    ) {
      window.clearTimeout(this.toastTimer);
      this.handleOnResetToastTimeout();
    }
  }

  public componentWillUnmount() {
    window.clearTimeout(this.toastTimer);
    this.mounted = false;
    this.injectedProps.pageStore.closeToast();
  }

  public render() {
    const { toastOptions } = this.injectedProps.pageStore;
    return (
      <ToastWrapper
        open={!this.state.isClosing}
        {...toastOptions}
        TransitionComponent={this.setTransition}
        onMouseOver={this.handleOnPauseToastTimeout}
        onMouseLeave={this.handleOnResetToastTimeout}
        message={
          <>
            {this.renderIcon()}
            <ToastMessage>{toastOptions.content}</ToastMessage>
            {toastOptions.interactive && (
              <ToastCloseIconButton
                size={"small"}
                key="close"
                aria-label="close"
                onClick={this.hanldeOnClose}
              >
                <Icon name={IconName.ToastClose} width="24" height="24" />
              </ToastCloseIconButton>
            )}
          </>
        }
      />
    );
  }

  /**
   * Renders toast icon (Default: info)
   *
   * @private
   * @memberof Toast
   */
  private renderIcon = () => {
    const { toastOptions } = this.injectedProps.pageStore;
    switch (toastOptions.type) {
      case ToastType.Success:
        return <Icon name={IconName.ToastSuccess} width="24" height="24" />;
      case ToastType.Warn:
        return <Icon name={IconName.ToastWarn} width="24" height="24" />;
      case ToastType.Info:
      default:
        return <Icon name={IconName.ToastInfo} width="24" height="24" />;
    }
  };

  /**
   * Set slide animation to toast
   *
   * @private
   * @param {TransitionProps} props
   * @returns
   * @memberof Toast
   */
  private setTransition(props: TransitionProps) {
    return <MuiSlide {...props} direction="up" />;
  }

  /**
   * Set auto close timeout
   *
   * @private
   * @memberof Toast
   */
  private setToastTimeout = () => {
    const { isToastShow } = this.injectedProps.pageStore;
    if (isToastShow && this.mounted) {
      this.setState({ isClosing: true });
    }
  };

  /**
   * Handler that user decides to close toast
   *
   * @private
   * @memberof Toast
   */
  private hanldeOnClose = () => {
    this.injectedProps.pageStore.closeToast();
  };

  /**
   * Handler that pause toast timeout when the mouse cursor hovers on toast.
   *
   * @private
   * @memberof Toast
   */
  private handleOnPauseToastTimeout = () => {
    this.setState({ isClosing: false });
    window.clearTimeout(this.toastTimer);
  };

  /**
   * Handler that reset toast timeout when the mouse cursor is removed from toast.
   *
   * @private
   * @memberof Toast
   */
  private handleOnResetToastTimeout = () => {
    const { toastOptions } = this.injectedProps.pageStore;
    this.toastTimer = window.setTimeout(
      this.setToastTimeout,
      toastOptions ? toastOptions.duration || 1000 : 1000,
    );
  };
}
