import styled from "styled-components";
import React from "react";

import { Grid as MuiGrid } from "@material-ui/core";

interface FieldSectionProps {
  label: string;
  children?: React.ReactNode;
}

const FieldSectionWrapper = styled.div`
  width: 100%;

  & label {
    font-weight: normal;
  }
`;

const FieldSectionHeader = styled.header`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  margin-bottom: 10px;
`;

export const FieldSection = ({ label, children }: FieldSectionProps) => (
  <FieldSectionWrapper>
    <FieldSectionHeader>{label}</FieldSectionHeader>
    {children}
  </FieldSectionWrapper>
);

const FieldGroupWrapper = styled.div<{ vertical?: boolean }>`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? "column" : "row")};
  margin-bottom: 15px;

  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
`;

const FieldLabel = styled.label<{ labelWidth?: string; vertical?: boolean }>`
  width: ${({ labelWidth }) => (labelWidth ? labelWidth : "155px")};
  margin-right: ${({ vertical }) => (vertical ? "10px" : "50px")};
  margin-bottom: 10px;
  font-weight: 500;
  font-size: ${({ vertical }) => (vertical ? "14px" : "16px")};
`;

export const FieldGridContainer = styled(props => (
  <MuiGrid {...props} container spacing={3} />
))`
  &&& {
    margin-top: 0;
  }
`;

export const FieldGridItem = styled(props => <MuiGrid item {...props} />)`
  &&& {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

interface FieldGroupProps {
  children: React.ReactNode;
  label?: React.ReactChild;
  vertical?: boolean;
  required?: boolean;
  labelWidth?: string;
  className?: string;
}

export default function FieldGroup(
  props: FieldGroupProps,
): React.FunctionComponentElement<FieldGroupProps> {
  const { label, children, vertical, required, labelWidth, className } = props;
  return (
    <FieldGroupWrapper vertical={vertical} className={className}>
      {label && (
        <FieldLabel labelWidth={labelWidth} vertical={vertical}>
          {label}
          {required && "*"}
        </FieldLabel>
      )}
      {children}
    </FieldGroupWrapper>
  );
}
