import isArray from "lodash/isArray";
import includes from "lodash/includes";
import shortid from "shortid";
import styled from "styled-components";
import React, { useRef, useState, useEffect } from "react";

import MuiClickAwayListener from "@material-ui/core/ClickAwayListener";
import MuiFormControl from "@material-ui/core/FormControl";
import MuiInputBase from "@material-ui/core/InputBase";
import MuiInputLabel from "@material-ui/core/InputLabel";
import MuiInputAdornment, {
  InputAdornmentProps as MuiInputAdornmentProps,
} from "@material-ui/core/InputAdornment";
import MuiList from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import MuiListItemText from "@material-ui/core/ListItemText";
import { TextFieldProps as MuiTextFieldProps } from "@material-ui/core/TextField";
import MuiPopover, {
  PopoverProps as MuiPopoverProps,
} from "@material-ui/core/Popover";

import {
  TextField,
  SelectOption,
  SelectOptionValueType,
} from "components/Form";
import Tag from "components/Tag";
import { TagProps } from "components/Tag/Tag";
import { Icon, IconName, IconButton } from "components/Icons";
import { KeyboardKey } from "lib/enums";
import { useKeyPress } from "lib/hooks";
import { theme as libTheme } from "lib/styled";

const PopoverWrapper = styled((props: MuiPopoverProps) => (
  <MuiPopover classes={{ paper: "select-popover-paper" }} {...props} />
))`
  &&& .select-popover-paper {
    border: 1px solid ${({ theme }) => theme.gray1};
    border-radius: 10px;
    margin-top: 10px;
    width: ${({ anchorEl }) =>
      anchorEl && (anchorEl as HTMLElement).offsetWidth
        ? `${(anchorEl as HTMLElement).offsetWidth}px`
        : "auto"};
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
`;

// Styled TextField component to disable underlying input element.
// We rely on InputAdornment component to render inputs
const SelectTextField = styled((props: MuiTextFieldProps) => (
  <TextField
    {...props}
    InputProps={{
      ...props.InputProps,
      classes: { inputAdornedStart: "select-input-adorned-start" },
    }}
  />
))`
  &&& {
    input,
    textarea {
      cursor: ${({ disabled }) => (disabled ? "initial" : "pointer")};
    }

    .select-input-adorned-start {
      display: none;
    }
  }
`;

// Represents a single input in a multiple select interface
const InputTag = styled((props: TagProps) => (
  <Tag {...props} icon={<Icon name={IconName.Close} />} />
))`
  &&& {
    margin-right: 10px;
    margin-bottom: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const InputAdornment = styled((props: MuiInputAdornmentProps) => (
  <MuiInputAdornment
    {...props}
    classes={{
      positionStart: "input-adornment-start",
      positionEnd: "input-adornment-end",
    }}
  />
))<{ multiple?: boolean }>`
  // needed to properly display multiple tag inputs
  &&& {
    &.input-adornment-start {
      flex-wrap: wrap;
      padding: 8px 10px 3px;
      height: auto;
      max-height: none;
      flex: 0 0 auto;
      width: 100%;
      box-sizing: border-box;
    }

    &.input-adornment-end {
      ${({ multiple }) => (multiple ? "padding-right: 14px" : null)}
    }
  }
`;

const List = styled(MuiList)`
  &&& {
    padding: 0;
    max-height: 300px;
    position: relative;
    overflow-y: auto;
  }
`;

const ListItem = styled(props => (
  <MuiListItem
    classes={{
      selected: "select-list-item-selected",
      disabled: "select-list-item-disabled",
    }}
    {...props}
  />
))`
  &&& {
    :hover,
    :active,
    :focus {
      background-color: ${({ theme }) => theme.bgBlueGray};
    }
  }

  &&&.select-list-item-selected {
    background-color: ${({ theme }) => theme.bgBlueGray};
  }

  &&&.select-list-item-disabled {
    opacity: 1;
    color: ${({ theme }) => theme.gray1};

    :hover {
      background: transparent;
    }
  }
`;

const ListItemSearch = styled(ListItem)``;

const FilterTextInput = styled(MuiInputBase)`
  &&& {
    font-size: 14px;
    line-height: 1.57;
  }
`;

interface BaseSelectProps {
  options: SelectOption[];
  label?: string;
  placeholder?: string | undefined;
  helperText?: string | false | undefined;
  popupRef?: React.RefObject<HTMLDivElement>;
  required?: boolean;
  error?: boolean;
  className?: string;
  disabled?: boolean;
  /**
   * Set it to true, or false to explicitly
   * enable or disable the search input respectively.
   * Otherwise, search input is hidden for
   * options containing less than 5 menu items.
   *
   * @type {boolean}
   * @memberof BaseSelectProps
   */
  withSearch?: boolean;
}

interface SingleSelectProps extends BaseSelectProps {
  value: SelectOptionValueType;
  onChange: (value: any) => void;
  multiple?: false;
  checklist?: false;
}

interface MultiSelectProps extends BaseSelectProps {
  value: SelectOptionValueType[];
  onChange: (value: any) => void;
  multiple: true;
  checklist?: boolean;
}

type SelectProps = SingleSelectProps | MultiSelectProps;

export default function Select(props: SelectProps) {
  const {
    value: fieldValue = props.multiple ? [] : "",
    label: fieldLabel,
    multiple,
    onChange,
    options,
    placeholder,
    helperText,
    error,
    required,
    withSearch,
    checklist,
    className,
    disabled,
    ...rest
  } = props;

  const uniqueKey = shortid.generate();
  const [isListOpen, setIsListOpen] = useState(false);
  const [filteredText, setFilteredText] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const [keyIndex, setKeyIndex] = useState<number | undefined>(undefined);
  const textFieldRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const escPress = useKeyPress(KeyboardKey.Esc);
  const escapePress = useKeyPress(KeyboardKey.Escape);
  const enterPress = useKeyPress(KeyboardKey.Enter);
  const arrowUpPress = useKeyPress(KeyboardKey.ArrowUp);
  const arrowDownPress = useKeyPress(KeyboardKey.ArrowDown);

  /* Effects */
  // Detects Editing state and changes input state accordingly
  useEffect(() => {
    if (isListOpen) {
      // automatically focus on input on edit
      setFocusInput();
    } else {
      // automatically blur on input on cancel edit
      closeList();
    }
  }, [isListOpen]);

  // Detects keyboard key presses
  useEffect(() => {
    if (isListOpen) {
      if (escPress || escapePress) {
        // Escape key is intercepted by Popover component,
        // so we instead use its onClose method to close the Popover
        // do nothing
      } else if (enterPress) {
        if (keyIndex !== undefined) {
          setFilteredText("");
          const option = selectableMenu.find((_, i) => keyIndex === i);
          if (!!option) {
            setValue(option);
            closeList();
          }
        }
      } else if (arrowUpPress) {
        if (keyIndex !== undefined && keyIndex !== 0) {
          return setKeyIndex(keyIndex! - 1);
        }
        return setKeyIndex(0);
      } else if (arrowDownPress) {
        if (keyIndex === undefined) {
          return setKeyIndex(0);
        }
        return setKeyIndex(keyIndex! + 1);
      }
    }
  }, [escPress, escapePress, enterPress, arrowUpPress, arrowDownPress]);

  /* Handlers */
  // Pass variable to setValue
  // Because MuiListItem's onClick event is not allowed function with variable
  const handleOnMenuItemClick = (option: SelectOption) => (
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    event.preventDefault();
    if (!checklist) {
      setValue(option);
      closeList();
    } else {
      // is checklist
      if (existsInSelectedValueArray(option)) {
        removeValue(option);
      } else {
        setValue(option);
      }
    }
  };

  // Handles when user decides to change value
  const handleOnInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilteredText(event.target.value);
  };

  const handleOnClickAway = (e: React.MouseEvent<Document, MouseEvent>) => {
    // if target is input filter not be blurred
    if (
      (e.target as HTMLInputElement).name! !==
      `input-select-filter-${uniqueKey}`
    ) {
      closeList();
    }
  };

  // Closes the list menu
  const closeList = () => {
    setIsListOpen(false);
    setFilteredText("");
    setKeyIndex(undefined);

    // Sets blur on input element
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  // Sets focus on search input element
  const setFocusInput = () => {
    if (!!inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleOnInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
    setIsListOpen(!!event.currentTarget);
  };

  const setValue = (option: SelectOption) => {
    if (multiple) {
      const updateValue = isArray(fieldValue) ? fieldValue.slice(0) : [];
      updateValue.push(option.value);
      onChange(updateValue);
      setKeyIndex(undefined);
      setFilteredText("");
    } else {
      onChange(option.value);
    }
  };

  // Makes a handler function that removes a single element from a mutiple select interface
  const makeRemoveSingleValue = (optionToRemove: SelectOption) => () => {
    removeValue(optionToRemove);
  };

  const removeValue = (optionToRemove: SelectOption) => {
    if (multiple) {
      const updateValue = isArray(fieldValue)
        ? fieldValue.filter(option => option !== optionToRemove.value)
        : [];
      onChange(updateValue);
    } else {
      // shouldn't get here
      onChange("");
    }
  };

  // checks whether a specific option exists in the selected options
  const existsInSelectedValueArray = (optionToCheck: SelectOption) => {
    return Boolean(
      selectedValueArray.find(option => option.value === optionToCheck.value),
    );
  };

  // filter options by filter text
  const selectableMenu = options.filter(
    ({ label, value }) =>
      label
        .toString()
        .toLowerCase()
        .indexOf(filteredText.toLowerCase()) > -1 &&
      // Show option when the following expression evaluates to true
      // 1. value is not already selected
      // 2. single value interface
      // 3. multiple value interface w/ checklist enabled
      ((multiple && isArray(fieldValue) && !includes(fieldValue, value)) ||
        !multiple ||
        (multiple && checklist)),
  );

  let selectedValueString = "";
  let selectedValueArray: SelectOption[] = [];
  if (multiple && isArray(fieldValue)) {
    // An option's human-readable label may be different from its value.
    // But we only have a list of actual values, so it needs to filter options which included in its value
    selectedValueArray = options
      .filter(({ value }) => fieldValue.indexOf(value) > -1)
      .sort(
        (a, b) => fieldValue.indexOf(a.value) - fieldValue.indexOf(b.value),
      );
  } else {
    const option = options.find(({ value }) => value === fieldValue);
    selectedValueString = !!option ? option.label : "";
  }

  const hasValue = selectedValueString !== "" || selectedValueArray.length > 0;

  return (
    <MuiClickAwayListener onClickAway={handleOnClickAway}>
      <MuiFormControl fullWidth variant="outlined" className={className}>
        {fieldLabel && (
          <MuiInputLabel
            error={props.error}
            variant="outlined"
            required={props.required}
          >
            {fieldLabel}
          </MuiInputLabel>
        )}
        <SelectTextField
          name={`input-select-value-${uniqueKey}`}
          onClick={handleOnInputClick}
          placeholder={
            checklist && selectedValueArray.length > 0
              ? `${selectedValueArray.length} Selected`
              : !hasValue
              ? placeholder
              : ""
          }
          inputRef={textFieldRef}
          value={hasValue ? selectedValueString : ""}
          helperText={helperText}
          error={error}
          multiline={multiple}
          InputProps={{
            startAdornment: multiple &&
              !checklist &&
              selectedValueArray.length > 0 && (
                <InputAdornment position="start">
                  {selectedValueArray.map((option: SelectOption) => (
                    <InputTag
                      key={`${option.label}-${option.key}`}
                      label={option.label}
                      size={"small"}
                      onIconClick={
                        disabled ? undefined : makeRemoveSingleValue(option)
                      }
                    />
                  ))}
                </InputAdornment>
              ),
            endAdornment: (!multiple || checklist) && !disabled && (
              <InputAdornment position="end" multiple={multiple}>
                <Icon
                  name={isListOpen ? IconName.ArrowUp : IconName.ArrowDown}
                  width="20"
                  height="20"
                />
              </InputAdornment>
            ),
          }}
          inputProps={{
            readOnly: true,
          }}
          disabled={disabled}
        />
        <PopoverWrapper
          open={isListOpen && !disabled}
          anchorEl={isListOpen ? anchorEl : undefined}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          disableRestoreFocus
          elevation={0}
          onClose={closeList}
        >
          {/* only display search input when option length is bigger than 5
            also display when it is explicitly told to or not to */}
          {(withSearch || (withSearch !== false && options.length > 5)) && (
            <ListItemSearch divider disabled>
              <MuiInputAdornment position="start">
                <Icon name={IconName.Search} width="20" height="20" />
              </MuiInputAdornment>
              <FilterTextInput
                name={`input-select-filter-${uniqueKey}`}
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                autoSave="off"
                placeholder="Search"
                fullWidth
                value={filteredText}
                onChange={handleOnInputChange}
                inputRef={inputRef}
                autoFocus={isListOpen}
                {...rest}
              />
            </ListItemSearch>
          )}
          <List>
            {selectableMenu.length === 0 ? (
              <ListItem button disabled>
                There are no available items
              </ListItem>
            ) : (
              selectableMenu.map((option, index) => (
                <ListItem
                  key={option.key}
                  onClick={handleOnMenuItemClick(option)}
                  selected={keyIndex === index}
                  button
                >
                  <MuiListItemText
                    primary={option.label}
                    primaryTypographyProps={{
                      color: option.primary ? "primary" : "inherit",
                    }}
                  />
                  {checklist && (
                    <IconButton edge="end" disabled>
                      <Icon
                        name={
                          existsInSelectedValueArray(option)
                            ? IconName.Check
                            : IconName.Null
                        }
                        color={libTheme.primary}
                      />
                    </IconButton>
                  )}
                </ListItem>
              ))
            )}
          </List>
        </PopoverWrapper>
      </MuiFormControl>
    </MuiClickAwayListener>
  );
}
