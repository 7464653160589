import React from "react";
import styled, { keyframes } from "styled-components";

/**
 * Ball's width and height (px).
 */
const BALL = 12;
/**
 * Spacing between balls (px).
 */
const GUTTER = 12;

/**
 * Displays a loading spinner (3-ball animation).
 *
 *   - https://codepen.io/bernethe/details/dorozd
 */
export default function Spinner() {
  return (
    <Container>
      <Balls />
    </Container>
  );
}

const alpha = keyframes`
  0% { opacity: 1; }
  33% { opacity: 0.25; }
  66% { opacity: 0.25; }
  100% { opacity: 1; }
`;
const alphaBefore = keyframes`
  0% { opacity: 0.25; }
  33% { opacity: 1; }
  66% { opacity: 0.25; }
`;
const alphaAfter = keyframes`
  33% { opacity: 0.25; }
  66% { opacity: 1; }
  100% { opacity: 0.25; }
`;

const Container = styled.div`
  width: ${3 * BALL + 2 * GUTTER}px;
  display: flex;
  justify-content: center;
`;

const Balls = styled.div`
  &,
  &:before,
  &:after {
    border-radius: 50%;
    background-color: ${props => props.theme.primary};
    width: ${BALL}px;
    height: ${BALL}px;
    transform-origin: center center;
    display: inline-block;
  }
  & {
    position: relative;
    background-color: ${props => props.theme.primary};
    opacity: 1;
    animation: ${alpha} 1s infinite linear;
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    opacity: 0.25;
  }
  &:before {
    left: ${BALL + GUTTER}px;
    top: 0px;
    animation: ${alphaBefore} 1s infinite linear;
  }
  &:after {
    left: -${BALL + GUTTER}px;
    top: 0;
    animation: ${alphaAfter} 1s infinite linear;
  }
`;
