import { css, keyframes } from "styled-components";

export const clearfix = css`
  &::before,
  &::after {
    content: "";
    display: table;
  }

  &::after {
    clear: both;
  }
`;

export const textEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const resetUl = css`
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-image: none;
`;

export const rotate360 = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const blink = keyframes`
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
`;

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

export const shake = keyframes`
  0% {
    margin-left: 0;
    margin-right: 0;
  }

  8.33% {
    margin-left: -3px;
    margin-right: 3px;
  }

  25% {
    margin-left: 3px;
    margin-right: -3px;
  }

  41.67% {
    margin-left: -3px;
    margin-right: 3px;
  }

  58.33% {
    margin-left: 3px;
    margin-right: -3px;
  }

  75% {
    margin-left: -3px;
    margin-right: 3px;
  }

  91.67% {
    margin-left: 3px;
    margin-right: -3px;
  }

  100% {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const dashGrow = (dashFrom: string, dashTo: string) => keyframes`
  from {
    stroke-dasharray: ${dashFrom};
  }

  to {
    stroke-dasharray: ${dashTo};
  }
`;
