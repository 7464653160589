import { inject, observer } from "mobx-react";
import styled from "styled-components";
import React from "react";

import { PageStore } from "app";
import Nav from "components/Nav";

import Alert from "./Alert";
import Spinner from "./Spinner";
import Toast from "./Toast";
// import PageFooter from "./PageFooter"; // Currently not used

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 30px;
  width: calc(100vw - 240px);
  min-width: 1040px; /* prevent layout from breaking beyond recognition */
  overflow: auto;
  position: relative;
`;

interface LayoutProps extends React.HTMLProps<HTMLElement> {
  pageStore?: PageStore;
}

const Layout = inject("pageStore")(
  observer(({ children, pageStore }: LayoutProps) => {
    const {
      // isPromptShow,
      isAlertShow,
      isSpinnerShow,
      isToastShow,
      spinnerMessage,
    } = pageStore!;
    return (
      <LayoutWrapper>
        <Nav />
        <Main id="main">{children}</Main>
        {isAlertShow && <Alert />}
        {isSpinnerShow && <Spinner message={spinnerMessage} />}
        {isToastShow && <Toast />}
        {/* Currently not used */}
        {/* {isPromptShow && <Prompt />} */}
        {/* <PageFooter /> */}
      </LayoutWrapper>
    );
  }),
);

export default Layout;
