import React from "react";
import * as Sentry from "@sentry/browser";

export default class ErrorBoundary extends React.Component {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const user = localStorage.getItem("user");
    const id = user ? JSON.parse(user).lawyerId : null;
    Sentry.withScope(scope => {
      if (id) {
        scope.setUser({ id });
      }
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    return this.props.children;
  }
}
