import styled from "styled-components";
import * as React from "react";

import MuiList from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import MuiListItemText from "@material-ui/core/ListItemText";
import MuiChip from "@material-ui/core/Chip";
import MuiFormControl from "@material-ui/core/FormControl";
import MuiFormHelperText from "@material-ui/core/FormHelperText";
import MuiInputBase, { InputBaseProps } from "@material-ui/core/InputBase";
import MuiInputLabel from "@material-ui/core/InputLabel";
import MuiPaper from "@material-ui/core/Paper";
import MuiPopper, { PopperProps } from "@material-ui/core/Popper";
import MuiClickAwayListener from "@material-ui/core/ClickAwayListener";

import { SelectOption, SelectOptionValueType } from "components/Form";
import { KeyboardKey } from "lib/enums";

const ChipWrapper = styled.div`
  padding-right: 4px;
  display: flex;
  flex-direction: row;
`;

const InputWrapper = styled.div<{ error?: boolean; isLabeled?: boolean }>`
  border-radius: 3px;
  background-color: #eee;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ isLabeled }) => (isLabeled ? "27px" : "10px")} 12px 10px;
  position: relative;

  &:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    pointer-events: none;
    transform: scaleX(1);

    ${({ error }) =>
      !!error
        ? `
    border-bottom-width: 2px;
    border-bottom-color: #f44336;
    `
        : ""}
  }
`;

const PopperWrapper = styled(MuiPopper)`
  && {
    z-index: 9999;
  }
`;

interface AutocompleteProps extends InputBaseProps {
  value: SelectOptionValueType[];
  options: SelectOption[];
  onInputChange: (value: SelectOptionValueType[]) => void;
  label?: string;
  placeholder?: string;
  helperText?: string;
  popupRef?: React.RefObject<HTMLDivElement>;
}

interface AutocompleteState {
  focusing: boolean;
  filteredText: string;
  selectableMenu: SelectOption[];
  keyIndex?: number;
  anchorEl: PopperProps["anchorEl"] | null;
}

/**
 * It is multi select field with filter
 * It requires options implement by SelectOption
 *
 * @export
 * @class Autocomplete
 * @extends {React.Component<AutocompleteProps, AutocompleteState>}
 */
export default class Autocomplete extends React.Component<
  AutocompleteProps,
  AutocompleteState
> {
  public static getDerivedStateFromProps(
    nextProps: AutocompleteProps,
    nextState: AutocompleteState,
  ) {
    // filter options by filter text
    const { options, value: fieldValue } = nextProps;
    const { filteredText } = nextState;
    const selectableMenu = options.filter(
      ({ label, value }) =>
        label
          .toString()
          .toLowerCase()
          .indexOf(filteredText.toLowerCase()) > -1 &&
        fieldValue.indexOf(value) === -1,
    );

    return {
      selectableMenu,
    };
  }

  public inputRef: HTMLInputElement | null = null;

  public readonly state = {
    focusing: false,
    filteredText: "",
    keyIndex: undefined,
    selectableMenu: this.props.options,
    anchorEl: null,
  };

  public render() {
    const {
      value: fieldValue,
      label: fieldLabel,
      options,
      placeholder,
      onInputChange,
      error,
      required,
      helperText,
      fullWidth,
      popupRef,
      ...rest
    } = this.props;
    const {
      focusing,
      filteredText,
      keyIndex,
      selectableMenu,
      anchorEl,
    } = this.state;

    // An option's human-readable label may be different from its value.
    // But we only have a list of actual values, so it needs to filter options which included in its value
    const mappedValue = options
      .filter(({ value }) => fieldValue.indexOf(value) > -1)
      .sort(
        (a, b) => fieldValue.indexOf(a.value) - fieldValue.indexOf(b.value),
      );
    return (
      <MuiClickAwayListener onClickAway={this.setBlurInput}>
        <MuiFormControl
          error={error}
          fullWidth
          variant="filled"
          margin="normal"
        >
          {fieldLabel && (
            <MuiInputLabel
              error={error}
              required={required}
              variant="filled"
              shrink={mappedValue.length > 0 || focusing || !!placeholder}
            >
              {fieldLabel}
            </MuiInputLabel>
          )}
          <InputWrapper error={error} isLabeled={!!fieldLabel}>
            {mappedValue.length > 0 && (
              <ChipWrapper>
                {mappedValue.map(({ key, label, primary }, index) => (
                  <MuiChip
                    key={`${label}-${key}`}
                    label={label}
                    onDelete={this.handleOnMenuItemRemove(index)}
                    color={primary ? "primary" : "default"}
                    size="small"
                  />
                ))}
              </ChipWrapper>
            )}
            <MuiInputBase
              name="input-filter"
              {...rest}
              placeholder={mappedValue.length === 0 ? placeholder : ""}
              fullWidth
              value={filteredText}
              onFocus={this.handleOnFocus}
              onChange={this.handleOnInputChange}
              onKeyDown={this.handleOnKeyDown}
              error={error}
              required={required}
              inputRef={this.setInputRef}
            />
            <PopperWrapper
              open={focusing}
              anchorEl={anchorEl}
              placement={"bottom-start"}
            >
              <MuiPaper elevation={2} innerRef={popupRef}>
                <MuiList>
                  {selectableMenu.map((option, index) => (
                    <MuiListItem
                      key={option.key}
                      onClick={this.handleOnMenuItemClick(option.value)}
                      selected={keyIndex === index}
                      button
                    >
                      <MuiListItemText
                        primary={option.label}
                        primaryTypographyProps={{
                          color: option.primary ? "primary" : "inherit",
                        }}
                      />
                    </MuiListItem>
                  ))}
                </MuiList>
              </MuiPaper>
            </PopperWrapper>
          </InputWrapper>
          {helperText && <MuiFormHelperText>{helperText}</MuiFormHelperText>}
        </MuiFormControl>
      </MuiClickAwayListener>
    );
  }

  private setInputRef = (node: HTMLInputElement) => {
    this.inputRef = node;
    if (!!node) {
      const getBoundingClientRect = () => node.getBoundingClientRect();
      this.setState({
        anchorEl: {
          clientWidth: getBoundingClientRect().width,
          clientHeight: getBoundingClientRect().height,
          getBoundingClientRect,
        },
      });
    }
  };

  private setFocusInput = () => {
    if (this.inputRef) {
      this.inputRef.focus();
    }
    this.setState({
      focusing: true,
    });
  };

  private setBlurInput = () => {
    if (this.inputRef) {
      this.inputRef.blur();
    }
    this.setState({
      focusing: false,
      filteredText: "",
      keyIndex: undefined,
    });
  };

  // Handles changes to internal filter text
  private handleOnInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.setState({
      filteredText: event.target.value,
      keyIndex: undefined,
    });
  };

  /**
   * Keyboard event for convenience
   *
   * @private
   * @memberof Autocomplete
   */
  private handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;
    const { value } = this.props;
    const { keyIndex, filteredText, selectableMenu } = this.state;
    switch (key) {
      case KeyboardKey.Escape:
      case KeyboardKey.Esc:
        event.stopPropagation();
        event.preventDefault();
        return this.setBlurInput();
      case KeyboardKey.Backspace:
      case KeyboardKey.Delete:
        if (!filteredText) {
          const index = value.length - 1;
          this.handleOnRemove(index);
          this.setInputRef(event.currentTarget);
          return;
        }
        return;
      case KeyboardKey.Enter:
        if (keyIndex !== undefined) {
          const option = selectableMenu.find((_, i) => i === keyIndex);
          if (!!option) {
            this.handleOnAdd(option.value);
            this.setInputRef(event.currentTarget);
          }
          return null;
        }
        return null;
      case KeyboardKey.ArrowUp:
        if (keyIndex !== undefined && keyIndex !== 0) {
          return this.setKeyIndex(keyIndex! - 1);
        }
        return this.setKeyIndex(0);
      case KeyboardKey.ArrowDown:
        if (keyIndex === undefined) {
          return this.setKeyIndex(0);
        }
        return this.setKeyIndex(keyIndex! + 1);
    }
  };

  // When the user selects an option with the keyboard,
  // it should show which option the current user is focusing.
  private setKeyIndex = (keyIndex: number | undefined) => {
    this.setState({
      keyIndex,
    });
  };

  // Sets focus on input element
  private handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const { onFocus } = this.props;
    if (!!onFocus) {
      onFocus(event);
    }
    this.setFocusInput();
  };

  // Pass variable to handleOnAdd
  // Because MuiListItem's onClick event is not allowed function with variable
  private handleOnMenuItemClick = (optionValue: any) => (
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    event.preventDefault();
    this.handleOnAdd(optionValue);
    this.setBlurInput();
  };

  // Pass variable to handleOnRemove
  // Because MuiChip's onRemove event is not allowed function with variable
  private handleOnMenuItemRemove = (index: number) => () => {
    this.handleOnRemove(index);
  };

  // Handles when user decides to add an element
  private handleOnAdd = (optionValue: any) => {
    const { value, onInputChange } = this.props;
    const updateValue = value.slice(0);
    updateValue.push(optionValue);
    onInputChange(updateValue);
    this.setState({
      keyIndex: undefined,
      filteredText: "",
    });
  };

  // Handles when user decides to remove an element
  private handleOnRemove = (index: number) => {
    const { value, onInputChange } = this.props;
    const updateValue = value.slice(0);
    updateValue.splice(index, 1);
    onInputChange(updateValue);
  };
}
