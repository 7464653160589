import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "react";
import { ThemeContext } from "styled-components";
import ReactCrop, { PercentCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import MuiBox from "@material-ui/core/Box";
import IconInfo from "@material-ui/icons/InfoRounded";

import { useStores } from "app";
import Modal, {
  ModalTitle,
  ModalContent,
  ModalActions,
} from "components/Modal";
import { Button } from "components/Button";
import { Spinner } from "components/Spinner";
import { cropImage, toDataURL } from "lib/image";
import { ToastType } from "lib/enums";
import { ThemeInterface } from "lib/styled";

interface Props {
  file?: File | null;
  onUpload: () => void;
  onClose: () => void;
}

/**
 * Renders profile-picture crop/upload component.
 */
export default function AvatarCropUploadModal({
  file,
  onUpload,
  onClose,
}: Props) {
  const { profileStore, pageStore } = useStores();
  // State. Is modal open?
  const [open, setOpen] = useState(false);
  // State. Data URL of given image file.
  const [data, setData] = useState<string>("");
  // Reference. Image element for crop component.
  const ref = useRef<HTMLImageElement>();
  // State. Crop data. Updated whenever crop-area changes.
  const [crop, setCrop] = useState<PercentCrop>({
    unit: "%",
    width: 100,
    aspect: 1,
  });
  // State. Is image being cropped/uplaoded?
  const [isUploading, setIsUploading] = useState(false);
  /**
   * Handles image-loaded. Center the crop area.
   */
  const handleOnImageLoaded = useCallback(
    (image: HTMLImageElement) => {
      ref.current = image;
      const size = Math.min(image.width, image.height);
      const x = ((image.width - size) / 2 / image.width) * 100;
      const y = ((image.height - size) / 2 / image.height) * 100;
      setCrop({
        unit: "%",
        aspect: 1,
        width: (size / image.width) * 100,
        height: (size / image.height) * 100,
        x,
        y,
      });
      return false;
    },
    [file],
  );
  /**
   * Handles crop & upload.
   */
  const handleOnUpload = useCallback(async () => {
    if (!file || !ref.current) return;
    setIsUploading(true);
    try {
      const cropped = await cropImage(ref.current, crop, file.name, file.type);
      await profileStore.uploadProfileImage(cropped);
      pageStore.openToast("Your profile picture has been updated!", {
        type: ToastType.Success,
        duration: 3000,
      });
      setIsUploading(false);
      onUpload();
      handleOnClose();
    } catch (err) {
      // CONTINUE REGARDLESS OF ERROR
    } finally {
      setIsUploading(false);
    }
  }, [crop, file]);
  /**
   * Handles crop-area change.
   */
  const handleOnChange = useCallback((_, percentCrop: PercentCrop) => {
    setCrop(percentCrop);
  }, []);
  /**
   * Handles close.
   */
  const handleOnClose = useCallback(() => {
    setOpen(false);
    setIsUploading(false);
    setData("");
    onClose();
  }, []);
  /**
   * Sets up image for crop component if it's valid.
   * @param {File} file Image file
   */
  const setupImage = async (file: File) => {
    const dataURL = await toDataURL(file);
    setData(dataURL);
    setOpen(true);
  };

  // Convert the given image file to data URL for crop component.
  useEffect(() => {
    if (file) {
      setupImage(file);
    }
  }, [file]);

  return (
    <Modal open={open} onClose={handleOnClose}>
      <ModalTitle>Crop your new profile picture</ModalTitle>
      <ModalContent>
        <Guidelines />
        {!data ? (
          <MuiBox
            flex={1}
            height={350}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner />
          </MuiBox>
        ) : (
          <ReactCrop
            src={data}
            keepSelection
            circularCrop
            crop={crop}
            disabled={isUploading}
            style={{ width: "100%", opacity: isUploading ? 0.7 : 1 }}
            imageStyle={{ width: "100%" }}
            onChange={handleOnChange}
            onImageLoaded={handleOnImageLoaded}
          />
        )}
      </ModalContent>
      <ModalActions>
        <Button width={130} variant="text" neutral onClick={handleOnClose}>
          Cancel
        </Button>
        <Button width={160} disabled={isUploading} onClick={handleOnUpload}>
          Upload
        </Button>
      </ModalActions>
    </Modal>
  );
}

const Guidelines = () => {
  const theme = useContext<ThemeInterface>(ThemeContext);

  return (
    <MuiBox mt={-2} mb={4} display="flex">
      <MuiBox
        display="flex"
        flex={1}
        mr={2}
        pr={2}
        borderRight={`1px solid ${theme.gray1}`}
      >
        <IconInfo />
        <MuiBox ml={1.5} fontSize={13}>
          We recommend head-and-shoulders photo where you’re smiling and facing
          the camera. Make adjustments using the cropping tool if necessary.
        </MuiBox>
      </MuiBox>
      <MuiBox mr={-3} aria-hidden>
        <MuiBox mt={1} display="flex">
          <MuiBox
            boxSizing="border-box"
            overflow="hidden"
            width={60}
            height={60}
            border={`2px solid ${theme.gray1}`}
            borderRadius={30}
            bgcolor="#f1f3f5"
          >
            <img
              src="/assets/profile-example-1.jpg"
              alt=""
              style={{ width: "100%" }}
            />
          </MuiBox>
          <MuiBox
            boxSizing="border-box"
            position="relative"
            left="-15px"
            overflow="hidden"
            width={60}
            height={60}
            border={`2px solid ${theme.gray1}`}
            borderRadius={30}
            bgcolor="#f1f3f5"
          >
            <img
              src="/assets/profile-example-2.jpg"
              alt=""
              style={{ width: "100%" }}
            />
          </MuiBox>
        </MuiBox>
      </MuiBox>
    </MuiBox>
  );
};
