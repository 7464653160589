import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { PageLoading } from "components/Layout";

const Profile = React.lazy(() => import("./views/Profile"));

const ProfileRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <React.Suspense fallback={<PageLoading />}>
      <Switch>
        <Route path={path}>
          <Profile />
        </Route>
      </Switch>
    </React.Suspense>
  );
};

export default ProfileRoutes;
