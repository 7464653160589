import Service from "lib/models/Service";
import config from "config";
import { PaginationConfigRequest } from "lib/models/LoadablePageableData";

export default class ProfileService extends Service {
  /**
   * Get a list of reviews.
   *
   * @param {PaginationConfigRequest} config
   * @returns {Promise<PageableData<any[]>>}
   * @memberof ProfileService
   */
  public async getListReview(
    config: PaginationConfigRequest,
  ): Promise<ResReviewList> {
    const res = await this.get("/lawyers/reviews", config);
    return res;
  }

  /**
   * Retrieves user's profile.
   *
   * @returns {Promise<ResProfile>} User profile
   * @memberof ProfileService
   */
  public async readSingleProfile(): Promise<ResProfile> {
    const res = this.get("/lawyers/lawyer-detail");
    return res;
  }

  /**
   * Updates user's profile.
   *
   * @param {Partial<ReqProfile>} data
   * @returns {Promise<ResProfile>} User profile
   * @memberof ProfileService
   */
  public async updateUserProfile(
    data: Partial<ReqProfile>,
  ): Promise<ResProfile> {
    const res = this.put("/lawyers/lawyer-detail", data);
    return res;
  }

  /**
   * Starts phone verification process. Users will receive verification code via SMS.
   *
   * @param {string} mobileNumber "+821030304040"
   * @memberof ProfileService
   */
  public async requestPhoneVerificationCode(mobileNumber: string) {
    try {
      const res = await this.post("/lawyers/start-verification", {
        mobileNumber,
      });
      return res;
    } catch (e) {
      throw typeof e.json === "function"
        ? new Error((await e.json()).description)
        : e;
    }
  }

  /**
   * Submits phone verification code.
   *
   * @param {string} mobileNumber
   * @param {string} code
   * @returns {Promise<boolean>}
   * @memberof ProfileService
   */
  public async checkPhoneVerificationCode(
    mobileNumber: string,
    code: string,
  ): Promise<boolean> {
    const res = await this.post("/lawyers/check-verification", {
      mobileNumber,
      code,
    });
    return res.valid;
  }

  /**
   * Uploads profile image.
   *
   * @param {File} file
   * @returns {Promise<ResProfile>}
   * @memberof ProfileService
   */
  public async createProfileImage(file: File): Promise<ResProfile> {
    const formData = new FormData();
    formData.append("profileImage", file);
    const res: ResProfile = await this.upload(
      "/lawyers/upload-profile-image",
      formData,
    );
    return res;
  }

  /**
   * Creates bank account.
   *
   * @param {string} code Stripe Connect authorization code.
   * @returns {string} Stripe connected account ID
   * @memberof ProfileService
   */
  public async createBankAccount(code: string) {
    const res = await this.post("/lawyers/bank-accounts", {
      stripeAccountCode: code,
    });
    return res.stripeAccountId;
  }

  /**
   * Given an email address, invites a lawyer.
   *
   * @param email
   * @memberof ProfileService
   */
  public inviteLaywer(email: string) {
    return this.post("/lawyers", { email });
  }

  /**
   * Retrieves universities that matches given name.
   *
   * @param {string} name Search keyword
   * @returns {Promise<{ name: string }[]>} List of universities
   * @memberof ProfileService
   */
  public async searchUniversity(name: string): Promise<{ name: string }[]> {
    try {
      const res = await fetch(`${config.baseHost}/university?name=${name}`);
      return res.json();
    } catch (err) {
      return [];
    }
  }
}
