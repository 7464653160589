import { action, computed, observable, runInAction } from "mobx";
import sortBy from "lodash/sortBy";

import { SelectOption } from "components/Form";
import { LoadingState } from "lib/enums";
import Store from "lib/models/Store";

import EventType, { EventTypeSource } from "./models/EventType";
import FormType, { FormTypeSource } from "./models/FormType";
import Process, { ProcessSource } from "./models/Process";

import FormProcess from "./commands/FormProcess";

import EventTypeService from "./services/EventTypeService";
import FormTypeService from "./services/FormTypeService";
import ProcessService from "./services/ProcessService";
import PracticeAreaService from "./services/PracticeAreaService";
import PracticeArea from "./models/PracticeArea";
import RootStore from "app/RootStore";

export default class CommonStore extends Store {
  @observable public formCreateProcess: FormProcess;
  @observable public formUpdateProcess: FormProcess;

  @observable private eventTypeEntities: EventType[] = [];
  @observable private formTypeEntities: FormType[] = [];
  @observable private processEntities: Process[] = [];
  @observable private practiceAreaEntities: PracticeArea[] = [];

  private eventTypeService: EventTypeService;
  private formTypeService: FormTypeService;
  private processService: ProcessService;
  private practiceAreaService: PracticeAreaService;

  constructor(
    rootStore: RootStore,
    eventTypeService: EventTypeService,
    formTypeService: FormTypeService,
    processService: ProcessService,
    practiceAreaService: PracticeAreaService,
  ) {
    super(rootStore);
    this.eventTypeService = eventTypeService;
    this.formTypeService = formTypeService;
    this.processService = processService;
    this.practiceAreaService = practiceAreaService;
    this.formCreateProcess = new FormProcess();
    this.formUpdateProcess = new FormProcess();
  }

  /**
   * Get list of process
   *
   * @readonly
   * @type {Process[]}
   * @memberof CommonStore
   */
  @computed
  get dataListProcess(): Process[] {
    return this.processEntities;
  }

  /**
   * Get mapped list of process for select component
   *
   * @readonly
   * @type {SelectOption[]}
   * @memberof CommonStore
   */
  @computed
  get dataListProcessOption(): SelectOption[] {
    return this.processEntities.map(({ id, processName }) => ({
      key: id,
      value: id,
      label: processName,
    }));
  }

  /**
   * Get list of practice areas (sorted alphabetically)
   *
   * @readonly
   * @type {PracticeArea[]}
   * @memberof CommonStore
   */
  @computed
  get dataListPracticeArea(): PracticeArea[] {
    return sortBy(this.practiceAreaEntities, x => x.typeName);
  }

  /**
   * Get a single process by id
   *
   * @param {string} id
   * @returns
   * @memberof CommonStore
   */
  @action.bound
  public dataSingleProcessById(id: number): Process | undefined {
    return this.processEntities.find(x => id === Number(x.id));
  }

  /**
   * Get list of Process
   *
   * @memberof CommonStore
   */
  @action.bound
  public async loadListProcess() {
    try {
      this.updateLoadingState(LoadingState.Loading);
      const res = await this.processService.readListProcess();
      this.updateLoadingState(LoadingState.Loaded);
      if (res) {
        runInAction(() => {
          this.processEntities = res.map(
            (x: ProcessSource) => new Process(x.processId, x),
          );
        });
      }
    } catch (e) {
      this.catchError(e);
    }
  }

  /**
   * Create a single Process
   *
   * @memberof CommonStore
   */
  @action.bound
  public async submitAddSingleProcess() {
    try {
      this.updateLoadingState(LoadingState.Loading);
      const res = await this.processService.createSingleProcess(
        this.formCreateProcess,
      );
      this.updateLoadingState(LoadingState.Loaded);
      if (res) {
        this.rootStore.pageStore.openAlert({
          message: "Process Successfully Added",
          onConfirm: () => {
            this.loadListProcess();
          },
        });
      }
    } catch (e) {
      this.catchError(e);
    }
  }

  /**
   * Update a single Process
   *
   * @returns
   * @memberof CommonStore
   */
  @action.bound
  public async submitEditSingleProcess(id: string) {
    try {
      this.updateLoadingState(LoadingState.Loading);
      const res = await this.processService.updateSingleProcess(
        id,
        this.formUpdateProcess,
      );
      this.updateLoadingState(LoadingState.Loaded);
      if (res) {
        this.rootStore.pageStore.openAlert({
          message: "Process Successfully Edited",
        });
      }
    } catch (e) {
      this.catchError(e);
    }
  }

  /**
   * Get list of form type
   *
   * @readonly
   * @type {FormType[]}
   * @memberof CommonStore
   */
  @computed
  public get dataListFormType(): FormType[] {
    return this.formTypeEntities;
  }

  /**
   * Get mapped list of form type for select component
   *
   * @readonly
   * @type {SelectOption[]}
   * @memberof CommonStore
   */
  @computed
  get dataListFormTypeOption(): SelectOption[] {
    return this.formTypeEntities.map(({ id, formName }) => ({
      key: id,
      value: id,
      label: formName,
    }));
  }

  /**
   * Get a single form type by id
   *
   * @param {string} id
   * @returns
   * @memberof CommonStore
   */
  public dataSingleFormTypeById(id: string): FormType | undefined {
    return this.formTypeEntities[id] ? this.formTypeEntities[id] : undefined;
  }

  @action.bound
  public async loadListFormType() {
    try {
      this.updateLoadingState(LoadingState.Loading);
      const res = await this.formTypeService.readListFormType();
      this.updateLoadingState(LoadingState.Loaded);
      if (res) {
        runInAction(() => {
          this.formTypeEntities = res.map(
            (x: FormTypeSource) => new FormType(x.formTypeId, x),
          );
        });
      }
    } catch (e) {
      this.catchError(e);
    }
  }

  /**
   * Get list of event type
   *
   * @readonly
   * @type {EventType[]}
   * @memberof CommonStore
   */
  @computed
  get dataListEventType(): EventType[] {
    return this.eventTypeEntities;
  }

  /**
   * Get mapped list of event type for select component
   *
   * @readonly
   * @type {SelectOption[]}
   * @memberof CommonStore
   */
  @computed
  get dataListEventTypeOption(): SelectOption[] {
    return this.eventTypeEntities.map(({ id, name }) => ({
      key: id,
      value: id,
      label: name,
    }));
  }

  /**
   * Get a single event type by id
   *
   * @param {string} id
   * @returns
   * @memberof CommonStore
   */
  public dataSingleEventTypeById(id: string): EventType | undefined {
    return this.eventTypeEntities[id] ? this.eventTypeEntities[id] : undefined;
  }

  /**
   * Get list of event type
   *
   * @memberof CommonStore
   */
  @action.bound
  public async loadListEventType() {
    try {
      this.updateLoadingState(LoadingState.Loading);
      const res = await this.eventTypeService.readListEventType();
      this.updateLoadingState(LoadingState.Loaded);
      if (res) {
        runInAction(() => {
          this.eventTypeEntities = res.map(
            (x: EventTypeSource) => new EventType(x.id, x),
          );
        });
      }
    } catch (e) {
      this.catchError(e);
    }
  }

  /**
   * Get list of practice areas
   *
   * @memberof CommonStore
   */
  public async loadListPracticeArea() {
    try {
      this.updateLoadingState(LoadingState.Loading);
      const res = await this.practiceAreaService.readListPracticeArea();
      runInAction(() => {
        this.practiceAreaEntities = res.map(x => new PracticeArea(x.typeId, x));
        this.updateLoadingState(LoadingState.Loaded);
      });
    } catch (e) {
      this.catchError(e);
    }
  }
}
