import styled from "styled-components";
import React from "react";

import MuiChip, { ChipProps as MuiChipProps } from "@material-ui/core/Chip";

export interface TagProps extends MuiChipProps {
  onIconClick?: React.EventHandler<any>;
}

/**
 * If you want to use `Tag` component on Table List (and Table Row),
 * Please use `LinkButton` instead of this
 */
const Tag = styled(({ icon, onIconClick, ...props }: TagProps) => (
  <MuiChip
    {...props}
    icon={undefined}
    deleteIcon={icon}
    onDelete={onIconClick}
    classes={{
      outlined: "tag-outlined",
      label: "tag-label",
      sizeSmall: "tag-small",
      deleteIconSmall: "tag-delete-icon-small",
    }}
  />
))`
  &&& {
    background-color: ${({ theme }) => theme.blueGray2};
    cursor: inherit;
    height: 28px;

    &.tag-outlined {
      border-color: ${({ theme }) => theme.black};
      background-color: transparent;
    }

    & .tag-label {
      text-transform: ${({ size }) =>
        size === "small" ? "none" : "uppercase"};
      font-weight: 600;
    }

    & .tag-delete-icon-small {
      margin-right: 8px;
    }
  }
`;

export default Tag;
