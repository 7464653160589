import { inject, observer } from "mobx-react";
import styled from "styled-components";
import * as React from "react";

import MuiButton from "@material-ui/core/Button";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";

import { PageStore } from "app";

const ContentWrapper = styled(MuiDialogContent)`
  &&,
  &&:first-child {
    min-width: 200px;
    padding: 31px 22px;
    font-size: 14px;
    line-height: 1.57;
    text-align: center;
    color: ${({ theme }) => theme.black};
    white-space: pre-line;
    border-bottom: 1px solid ${({ theme }) => theme.gray1};
  }
`;

const ButtonWrapper = styled(MuiDialogActions)`
  && {
    padding: 0;
  }
`;

const AlertButton = styled(MuiButton)`
  && {
    box-sizing: border-box;
    width: 100%;
    min-height: 58px;
    margin: 0;
    padding: 20px;
    border-radius: 0;
    border-left: 1px solid ${({ theme }) => theme.gray1};

    :first-child {
      border: 0;
    }
  }
`;

interface InjectedProps {
  pageStore: PageStore;
}

@inject("pageStore")
@observer
export default class Alert extends React.Component {
  get injectedProps() {
    return this.props as InjectedProps;
  }

  public render() {
    const { alert } = this.injectedProps.pageStore;

    // since Alert component is meant to get the user's attention,
    // we set the following props to true by default
    const { disableBackdropClick = true, disableEscapeKeyDown = true } = alert;

    return (
      <MuiDialog
        open
        onClose={this.handleOnCancel}
        disableBackdropClick={disableBackdropClick}
        disableEscapeKeyDown={disableEscapeKeyDown}
      >
        <ContentWrapper>{alert.message}</ContentWrapper>
        <ButtonWrapper>{this.renderFooter()}</ButtonWrapper>
      </MuiDialog>
    );
  }

  private renderFooter = () => {
    const { alert } = this.injectedProps.pageStore;
    if (alert.noButtons) {
      return null;
    }
    const buttons: any[] = [];
    if (alert.onCancel) {
      buttons.push(
        <AlertButton
          key="modal-cancel-button"
          color="primary"
          onClick={this.handleOnCancel}
          variant="text"
        >
          {alert.cancelMessage || "No"}
        </AlertButton>,
      );
    }

    if (alert.onCustom) {
      buttons.push(
        <AlertButton
          key="modal-custom-button"
          color="primary"
          onClick={this.handleOnCustom}
          variant="text"
        >
          {alert.customMessage}
        </AlertButton>,
      );
    }

    buttons.push(
      <AlertButton
        key="modal-confirm-button"
        color={alert.isNegative ? "secondary" : "primary"}
        onClick={this.handleOnConfirm}
        variant="text"
      >
        {alert.confirmMessage
          ? alert.confirmMessage
          : !!alert.onCancel
          ? "Yes"
          : "OK"}
      </AlertButton>,
    );
    return buttons;
  };

  private handleOnCustom = () => {
    const { closeAlert, alert } = this.injectedProps.pageStore;
    closeAlert();
    if (alert.onCustom) {
      alert.onCustom!();
    }
  };

  private handleOnCancel = () => {
    const { closeAlert, alert } = this.injectedProps.pageStore;
    closeAlert();
    if (alert.onCancel) {
      alert.onCancel!();
    }
  };

  private handleOnConfirm = () => {
    const { closeAlert, alert } = this.injectedProps.pageStore;
    closeAlert();
    if (alert.onConfirm) {
      alert.onConfirm!();
    }
  };
}
