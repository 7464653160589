import React from "react";

export enum IconName {
  Home,
  My,
  Smile,
  Exit,
  Folder,
  User,
  ToastClose,
  ToastInfo,
  ToastSuccess,
  ToastWarn,
  Search,
  Delete,
  Arrow,
  ModalClose,
  More,
  Star,
  StarEmpty,
  StarFilled,
  Calendar,
  ArrowUp,
  ArrowDown,
  Close,
  Check,
  Null,
  Bell,
  Plus,
  Settings,
  Review,
  Hyperlink,
  Globe,
  Memo,
  Case,
  NotFound,
  Chrome,
  Chat,
  Megaphone,
  CancelMessage,
  Message,
  Videocam,
  Hourglass,
  Payout,
}

interface IconProps {
  name: IconName;
  color?: string;
  width?: number;
  height?: number;
  className?: string;
  onClick?: React.EventHandler<any>;
}

export default function Icon({
  name,
  color,
  width = 20,
  height = 20,
  className,
  onClick,
}: IconProps): React.FunctionComponentElement<IconProps> {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g fill={color || "inherit"}>{getSvgPath(name)}</g>
    </svg>
  );
}

const getSvgPath = (name: IconName) => {
  switch (name) {
    case IconName.Home:
      return (
        <>
          <title>Home Icon</title>
          <g fillRule="evenodd">
            <path fillOpacity="0" d="M0 0h20v20H0z" />
            <path
              fillRule="nonzero"
              d="M9 16v-5h2v5h5V9.414a1 1 0 0 0-.293-.707l-5-5a1 1 0 0 0-1.414 0l-5 5A1 1 0 0 0 4 9.414V16h5z"
            />
          </g>
        </>
      );
    case IconName.My:
      return (
        <>
          <title>My Icon</title>
          <g fillRule="evenodd">
            <path fill="inherit" fillOpacity="0" d="M0 0h20v20H0z" />
            <path
              fill="inherit"
              fillRule="nonzero"
              d="M10 11c1.652 0 3.305.194 4.957.582a2 2 0 0 1 1.543 1.947V17h-13v-3.471a2 2 0 0 1 1.543-1.947A21.634 21.634 0 0 1 10 11zm0-7.25a3.25 3.25 0 1 1 0 6.5 3.25 3.25 0 1 1 0-6.5z"
            />
          </g>
        </>
      );
    case IconName.Exit:
      return (
        <>
          <title>Exit Icon</title>
          <g fillRule="evenodd">
            <path fill="none" d="M0 0h20v20H0z" />
            <path
              fill="inherit"
              d="M9.414 10.742l-1.828 1.83L9 13.984l4.243-4.242L9 5.5 7.586 6.914l1.828 1.828H3V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-5.257l6.414-.001z"
            />
          </g>
        </>
      );
    case IconName.Folder:
      return (
        <>
          <title>Folder Icon</title>
          <g fillRule="evenodd">
            <path fill="inherit" fillOpacity="0" d="M0 0h20v20H0z" />
            <path
              fill="inherit"
              d="M4 4h4l1 2h7a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1z"
            />
          </g>
        </>
      );
    case IconName.Smile:
      return (
        <>
          <title>Smile Icon</title>
          <g fillRule="evenodd">
            <path fill="inherit" fillOpacity="0" d="M0 0h20v20H0z" />
            <path
              fill="inherit"
              d="M10 3a7 7 0 1 1 0 14 7 7 0 0 1 0-14zm2.984 7.927a.75.75 0 0 0-1.057.089c-.567.67-1.195.984-1.927.984s-1.36-.314-1.927-.984a.75.75 0 1 0-1.146.968C7.77 12.98 8.81 13.5 10 13.5c1.19 0 2.23-.52 3.073-1.516a.75.75 0 0 0-.089-1.057zM7.5 7.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
            />
          </g>
        </>
      );
    case IconName.User:
      return (
        <>
          <title>User Icon</title>
          <g fillRule="evenodd">
            <path fill="none" d="M0 0h20v20H0z" />
            <path
              fill="inherit"
              fillRule="nonzero"
              d="M13.896 11.083c.834.073 1.669.195 2.503.365A2 2 0 0 1 18 13.408V16h-3v-2.592c0-.927-.424-1.77-1.104-2.325zM8 11c1.466 0 2.933.15 4.4.448a2 2 0 0 1 1.6 1.96V16H2v-2.592a2 2 0 0 1 1.6-1.96A21.992 21.992 0 0 1 8 11zm4-6.75a3 3 0 1 1 0 6c-.398 0-.778-.078-1.126-.218A3.983 3.983 0 0 0 12 7.25c0-1.08-.428-2.06-1.124-2.78.346-.142.726-.22 1.124-.22zm-4 0a3 3 0 1 1 0 6 3 3 0 1 1 0-6z"
            />
          </g>
        </>
      );
    case IconName.Search:
      return (
        <>
          <title>Search Icon</title>
          <g fillRule="nonzero">
            <path fill="none" d="M0 0h20v20H0z" />
            <path
              fill="inherit"
              d="M9 3a6 6 0 0 1 4.891 9.476l3.352 3.352-1.415 1.415-3.352-3.352A6 6 0 1 1 9 3zm0 2a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"
            />
          </g>
        </>
      );
    case IconName.ToastClose:
      return (
        <>
          <title>Toast Close Icon</title>
          <g fill="none" fillRule="evenodd">
            <path fill="#FFF" fillOpacity="0" d="M0 0h24v24H0z" />
            <path
              fill="#BBB"
              fillRule="nonzero"
              d="M16.596 7.197l.707.707-4.596 4.596 4.596 4.596-.707.707L12 13.207l-4.596 4.596-.707-.707 4.596-4.596-4.596-4.596.707-.707L12 11.793l4.596-4.596z"
            />
          </g>
        </>
      );
    case IconName.ToastInfo:
      return (
        <>
          <title>Toast Info Icon</title>
          <path
            fill="#222"
            fillRule="evenodd"
            d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm1.315 9.308H9.5v1.23h.708c.446 0 .554.231.554.662v3.415c0 .431-.108.662-.554.662h-.662v1.23H14.5v-1.23h-.63c-.447 0-.555-.23-.555-.662V9.308zM11.977 6a1.32 1.32 0 0 0-1.339 1.338 1.32 1.32 0 0 0 1.339 1.339 1.32 1.32 0 0 0 1.338-1.339A1.32 1.32 0 0 0 11.977 6z"
          />
        </>
      );
    case IconName.ToastSuccess:
      return (
        <>
          <title>Toast Success Icon</title>
          <path
            fill="#195DEE"
            fillRule="evenodd"
            d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm4.4 7.828l-5.657 5.657-2.829-2.828L6.5 12.07l4.243 4.243 7.07-7.071L16.4 7.828z"
          />
        </>
      );
    case IconName.ToastWarn:
      return (
        <>
          <title>Toast Warn Icon</title>
          <path
            fill="#F33"
            fillRule="evenodd"
            d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm-.016 15c-.792 0-1.418.643-1.418 1.434 0 .775.626 1.418 1.418 1.418.79 0 1.434-.643 1.434-1.418 0-.791-.643-1.434-1.434-1.434zM13.5 6h-3l.577 8.027h1.846L13.5 6z"
          />
        </>
      );
    case IconName.Delete:
      return (
        <>
          <title>Delete Icon</title>
          <g fill="inherit" fillRule="evenodd">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill="inherit"
              d="M20 6v2h-1v12a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V8H4V6h16zm-3 2H7v11h10V8zm-6 3v5H9v-5h2zm4 0v5h-2v-5h2zm2-9v2H7V2h10z"
            />
          </g>
        </>
      );
    case IconName.Arrow:
      return (
        <>
          <title>Arrow Icon</title>
          <g
            fillRule="evenodd"
            style={{ transform: "rotate(-90deg)", transformOrigin: "center" }}
          >
            <path fill="none" d="M0 0h20v20H0z" />
            <path
              fill="inherit"
              fillRule="nonzero"
              d="M13.446 8L14.5 9.054l-4.5 4.5-4.5-4.5L6.554 8 10 11.446z"
            />
          </g>
        </>
      );
    case IconName.ModalClose:
      return (
        <>
          <title>Modal Close Icon</title>
          <g fillRule="evenodd">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill="#000"
              fillRule="nonzero"
              d="M17.293 5.293l1.414 1.414L13.414 12l5.293 5.293-1.414 1.414L12 13.414l-5.293 5.293-1.414-1.414L10.586 12 5.293 6.707l1.414-1.414L12 10.586l5.293-5.293z"
            />
          </g>
        </>
      );
    case IconName.More:
      return (
        <>
          <title>More Icon</title>
          <g fillRule="nonzero">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill="inherit"
              d="M4 10a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm8 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm8 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
            />
          </g>
        </>
      );
    case IconName.Star:
      return (
        <g fillRule="evenodd">
          <path fill="none" fillOpacity="0" d="M0 0H24V24H0z" />
          <path
            fill="inherit"
            d="M12 17.4l-3.976 2.428c-.707.432-1.63.21-2.062-.498-.206-.338-.27-.744-.179-1.13l1.081-4.531-3.538-3.031c-.629-.539-.702-1.486-.163-2.115.258-.301.624-.488 1.02-.52l4.643-.372 1.789-4.301c.318-.765 1.196-1.127 1.961-.809.366.152.657.443.809.809l1.789 4.301 4.644.373c.826.066 1.441.789 1.375 1.615-.032.395-.218.761-.52 1.019l-3.537 3.03 1.08 4.532c.193.806-.305 1.615-1.11 1.807-.386.092-.792.028-1.13-.179L12 17.4z"
          />
        </g>
      );
    case IconName.StarEmpty:
      return (
        <g fillRule="evenodd">
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            fill="#000"
            fillRule="nonzero"
            d="M12.885 1.726a2 2 0 0 1 .908.908l2.028 4.107 4.532.659a2 2 0 0 1 1.109 3.411l-3.28 3.198.774 4.514a2 2 0 0 1-2.902 2.108L12 18.5l-4.054 2.131a2 2 0 0 1-2.902-2.108l.774-4.514-3.28-3.198A2 2 0 0 1 3.647 7.4l4.532-.659 2.028-4.107a2 2 0 0 1 2.678-.908zM9.508 8.569l-5.574.81 4.033 3.931-.952 5.551L12 16.241l4.985 2.62-.952-5.55 4.033-3.932-5.574-.81L12 3.52 9.508 8.57z"
          />
        </g>
      );
    case IconName.StarFilled:
      return (
        <g fillRule="evenodd">
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            fill="inherit"
            d="M12 18.5l-4.054 2.131a2 2 0 0 1-2.902-2.108l.774-4.514-3.28-3.198A2 2 0 0 1 3.647 7.4l4.532-.659 2.028-4.107a2 2 0 0 1 3.586 0l2.028 4.107 4.532.659a2 2 0 0 1 1.109 3.411l-3.28 3.198.774 4.514a2 2 0 0 1-2.902 2.108L12 18.5z"
          />
        </g>
      );
    case IconName.Calendar:
      return (
        <>
          <title>Calendar Icon</title>
          <g fill="none" fillRule="nonzero">
            <path d="M0 0h20v20H0z" />
            <path
              fill="#000"
              d="M8 2v2h4V2h2v2a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3V2h2zm7 7H5v6a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V9z"
            />
          </g>
        </>
      );
    case IconName.ArrowUp:
      return (
        <>
          <title>Arrow Up Icon</title>
          <g fill="none" fillRule="evenodd">
            <path fill="#FFF" fillOpacity="0" d="M0 20h20V0H0z" />
            <path
              fill="#000"
              fillRule="nonzero"
              d="M13.446 12l1.054-1.054-4.5-4.5-4.5 4.5L6.554 12 10 8.554z"
            />
          </g>
        </>
      );
    case IconName.ArrowDown:
      return (
        <>
          <title>Arrow Down Icon</title>
          <g fill="none" fillRule="evenodd">
            <path fill="none" d="M0 0h20v20H0z" />
            <path
              fill="#000"
              fillRule="nonzero"
              d="M13.446 8L14.5 9.054l-4.5 4.5-4.5-4.5L6.554 8 10 11.446z"
            />
          </g>
        </>
      );
    case IconName.Close:
      return (
        <>
          <title>Close Icon</title>
          <g fillRule="evenodd">
            <path fill="none" d="M0 0h20v20H0z" />
            <path
              fill="inherit"
              fillRule="nonzero"
              d="M13.47 5.47l1.06 1.06L11.06 10l3.47 3.47-1.06 1.06L10 11.06l-3.47 3.47-1.06-1.06L8.94 10 5.47 6.53l1.06-1.06L10 8.94l3.47-3.47z"
            />
          </g>
        </>
      );
    case IconName.Check:
      return (
        <>
          <title>Check Icon</title>
          <g fillRule="evenodd">
            <path fill="none" d="M0 0h20v20H0z" />
            <path
              fill="inherit"
              fillRule="nonzero"
              d="M8.379 12.743L4.843 9.207 3.43 10.621l4.95 4.95L17.57 6.38l-1.414-1.415z"
            />
          </g>
        </>
      );
    case IconName.Bell:
      return (
        <>
          <title>Bell Icon</title>
          <g fillRule="evenodd">
            <path
              fill="inherit"
              d="M3.803 15A.965.965 0 0 1 3 13.5c.652-.978 1-2.127 1-3.303V6.5A5.5 5.5 0 0 1 9.5 1h1A5.5 5.5 0 0 1 16 6.5v3.697c0 1.176.348 2.325 1 3.303a.965.965 0 0 1-.803 1.5H13.5a3.5 3.5 0 0 1-7 0H3.803zM8.5 15a1.5 1.5 0 0 0 3 0z"
            />
          </g>
        </>
      );
    case IconName.Plus:
      return (
        <>
          <g fill="none" fillRule="evenodd">
            <path fill="none" d="M0 0h24v24H0z" />
            <path fill="#000" d="M13 5v6h6v2h-6v6h-2v-6H5v-2h6V5h2z" />
          </g>
        </>
      );
    case IconName.Settings:
      return (
        <>
          <g fill="none" fillRule="evenodd">
            <path fill="#FFF" fillOpacity="0" d="M0 0L20 0 20 20 0 20z" />
            <path
              fill="#000"
              d="M11.076 3c.245 0 .453.177.494.418l.232 1.384c.667.231 1.275.587 1.798 1.04l1.316-.492c.228-.086.486.006.608.218L16.6 7.432c.123.212.074.48-.115.636l-1.083.893c.064.337.098.684.098 1.039 0 .355-.034.702-.098 1.039l1.083.893c.189.155.238.424.115.636l-1.076 1.864c-.122.212-.38.304-.608.218L13.6 14.16c-.523.452-1.131.808-1.798 1.04l-.232 1.383c-.04.241-.25.418-.494.418H8.924c-.245 0-.453-.177-.494-.418l-.232-1.384c-.667-.231-1.276-.587-1.798-1.04l-1.316.492c-.228.086-.486-.006-.608-.218L3.4 12.568c-.123-.212-.074-.48.115-.636l1.083-.892c-.064-.337-.098-.685-.098-1.04s.034-.703.098-1.04l-1.083-.892c-.189-.155-.238-.424-.115-.636l1.076-1.864c.122-.212.38-.304.608-.218l1.316.492c.522-.453 1.131-.809 1.798-1.04l.232-1.384c.04-.241.25-.418.494-.418h2.152zM10 8c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2z"
            />
          </g>
        </>
      );
    case IconName.Review:
      return (
        <g fill="inherit" fillRule="evenodd">
          <path fillOpacity="0" d="M0 0L20 0 20 20 0 20z" />
          <path d="M15 3c.552 0 1 .448 1 1v12c0 .552-.448 1-1 1H5c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h10zm-2 9H7v1h6v-1zm0-3H7v1h6V9zm0-3H7v1h6V6z" />
        </g>
      );
    case IconName.Hyperlink:
      return (
        <g fill="none" fillRule="evenodd">
          <path d="M0 0H20V20H0z" />
          <path
            fill="#000"
            fillRule="nonzero"
            d="M9.857 4v1.5H5.5v9h9v-2.357H16V16H4V4h5.857zM16 4v4.5h-1.5V6.56l-4.45 4.45-1.06-1.06 4.448-4.45H11.5V4H16z"
          />
        </g>
      );
    case IconName.Globe:
      return (
        <path
          fill="#999"
          d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm3.558 14.001H6.442C7.138 16.986 8.56 19 10 19c1.441 0 2.862-2.014 3.558-4.999zm-8.142 0h-3.48c1.109 2.23 3.116 3.936 5.549 4.643-.913-1.065-1.642-2.691-2.069-4.643zm12.648 0h-3.48c-.427 1.952-1.156 3.578-2.068 4.643 2.432-.707 4.439-2.412 5.548-4.643zm-12.835-7H1.512C1.18 7.938 1 8.947 1 10s.18 2.062.512 3H5.23C5.08 12.053 5 11.045 5 10s.08-2.053.229-3zM13.757 7H6.243C6.087 7.935 6 8.944 6 10c0 1.057.087 2.066.243 3.001h7.514c.156-.935.243-1.944.243-3.001 0-1.056-.087-2.065-.243-3zm4.731 0h-3.717c.149.947.229 1.955.229 3s-.08 2.053-.229 3h3.717c.331-.938.512-1.948.512-3s-.18-2.061-.512-3zM7.484 1.356l-.04.012C5.03 2.082 3.039 3.78 1.937 6h3.48c.426-1.952 1.155-3.579 2.068-4.644zM10 1C8.558 1 7.138 3.015 6.442 6h7.116c-.676-2.898-2.034-4.882-3.432-4.995L10 1zm2.515.356l.05.059c.89 1.066 1.6 2.669 2.019 4.585h3.48c-1.108-2.231-3.116-3.937-5.549-4.644z"
        />
      );
    case IconName.Memo:
      return (
        <>
          <defs>
            <linearGradient
              id="memo__a"
              x1="48.867%"
              x2="54.391%"
              y1="100%"
              y2="0%"
            >
              <stop offset="0%" stopColor="#98B9FF" />
              <stop offset="100%" stopColor="#195DEE" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <rect
              width="120"
              height="155"
              x="30"
              y="10"
              fill="#F3F4F8"
              rx="5"
            />
            <path
              fill="#C7CADC"
              d="M128.5 78a1.5 1.5 0 010 3h-77a1.5 1.5 0 010-3h77zm0-21a1.5 1.5 0 010 3h-77a1.5 1.5 0 010-3h77zm0-21a1.5 1.5 0 010 3h-77a1.5 1.5 0 010-3h77z"
            />
            <path
              fill="#FFF"
              d="M96.187 140.643l-5.495-33.336 44.275-90.778c2.421-4.964 8.408-7.025 13.372-4.604l21.57 10.52c4.965 2.422 7.026 8.409 4.605 13.373l-44.275 90.778-29.653 16.193a3.001 3.001 0 01-4.398-2.145z"
            />
            <path
              fill="url(#memo__a)"
              d="M37 22a7 7 0 017 7v86H30V29a7 7 0 017-7z"
              transform="rotate(-154 91.731 64.727)"
            />
            <path
              fill="url(#memo__a)"
              d="M7 22a7 7 0 017 7v86H0V29a7 7 0 017-7z"
              transform="rotate(-154 61.731 57.8)"
            />
            <path
              fill="#195DEE"
              d="M108.712 120.547a8 8 0 01-3.684-10.698l37.262-76.397 14.38 7.014-37.261 76.397a8 8 0 01-10.697 3.684z"
            />
            <path
              fill="#0A41B5"
              d="M171.445 42.11L131.9 22.82l3.068-6.29c2.421-4.965 8.408-7.026 13.372-4.605l21.57 10.52c4.965 2.422 7.026 8.409 4.605 13.373l-3.069 6.291z"
            />
            <path
              fill="#195DEE"
              d="M100.568 142.812L112 136.615l-17.976-8.768 2.155 12.825a3 3 0 004.388 2.14z"
            />
          </g>
        </>
      );
    case IconName.Null:
      return <title>Null</title>;
    case IconName.Case:
      return (
        <>
          <defs>
            <linearGradient id="a" x1="0%" y1="7.68%" y2="92.32%">
              <stop offset="0%" stopColor="#98B9FF" />
              <stop offset="100%" stopColor="#195DEE" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <path
              fill="url(#a)"
              d="M21.26 115V5a5 5 0 0 1 5-5h25.049a5 5 0 0 1 3.952 1.937L63.832 13h77.428a5 5 0 0 1 5 5v92a5 5 0 0 1-5 5h-120z"
              transform="translate(17 33)"
            />
            <path
              fill="#F1F3F8"
              d="M17.034 50.579A5 5 0 0 1 22 45h24.432c1.547 0 3.006.716 3.954 1.938L58.956 58h78.051a5 5 0 0 1 4.956 4.339l10.843 81.322a5 5 0 0 0 4.956 4.339H32.835a5 5 0 0 1-4.966-4.421l-10.835-93z"
            />
            <path
              fill="#195DEE"
              fillRule="nonzero"
              d="M88.85 104.037a1.5 1.5 0 1 1 2.3 1.926C89.466 107.974 87.21 109 84.5 109c-2.71 0-4.966-1.026-6.65-3.037a1.5 1.5 0 1 1 2.3-1.926C81.257 105.36 82.667 106 84.5 106c1.833 0 3.243-.641 4.35-1.963zM73 96a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm23 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
            />
          </g>
        </>
      );
    case IconName.Chrome:
      return (
        <>
          <defs>
            <linearGradient
              id="chrome_a"
              x1="100%"
              x2="-6.29%"
              y1="16.223%"
              y2="83.777%"
            >
              <stop offset="0%" stopColor="#98B9FF" />
              <stop offset="100%" stopColor="#195DEE" />
            </linearGradient>
            <rect id="chrome_b" width="146" height="120" x="0" y="0" rx="10" />
          </defs>
          <g fill="none" fillRule="evenodd">
            <path fill="#FFF" fillOpacity="0" d="M0 0h180v180H0z" />
            <g transform="translate(14 30)">
              <rect
                width="146"
                height="120"
                x="10"
                y="10"
                fill="url(#chrome_a)"
                rx="10"
              />
              <mask id="chrome_c" fill="#fff">
                <use xlinkHref="#chrome_b" />
              </mask>
              <use fill="#FFF" xlinkHref="#chrome_b" />
              <path
                fill="#195DEE"
                fillRule="nonzero"
                d="M72.5 61.5c2.71 0 4.966 1.026 6.65 3.037a1.5 1.5 0 01-2.3 1.926C75.743 65.14 74.333 64.5 72.5 64.5c-1.833 0-3.243.641-4.35 1.963a1.5 1.5 0 01-2.3-1.926c1.684-2.011 3.94-3.037 6.65-3.037zM61 54a2 2 0 11.001 3.999A2 2 0 0161 54zm23 0a2 2 0 11.001 3.999A2 2 0 0184 54z"
                mask="url(#chrome_c)"
              />
              <path fill="#195DEE" d="M0 0h146v20H0z" mask="url(#chrome_c)" />
              <circle cx="13" cy="10" r="3" fill="#FFF" mask="url(#chrome_c)" />
              <circle
                cx="24"
                cy="10"
                r="3"
                fill="#C5D7FF"
                mask="url(#chrome_c)"
              />
              <circle
                cx="35"
                cy="10"
                r="3"
                fill="#93B6FF"
                mask="url(#chrome_c)"
              />
            </g>
          </g>
        </>
      );
    case IconName.NotFound:
      return (
        <>
          <defs>
            <linearGradient
              id="a"
              x1="90.95%"
              x2="10.435%"
              y1="20.843%"
              y2="66.868%"
            >
              <stop offset="0%" stopColor="#F3F4F8" />
              <stop offset="100%" stopColor="#A8BAE0" />
            </linearGradient>
            <linearGradient
              id="b"
              x1="79.256%"
              x2="22.964%"
              y1="82.884%"
              y2="11.047%"
            >
              <stop offset="0%" stopColor="#F3F4F8" />
              <stop offset="100%" stopColor="#A8BAE0" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <path
              fill="url(#a)"
              d="M89.205 31.63a28.038 28.038 0 01-22.02 15.983l-23.59 2.876C12.389 53.226 0 78.452 0 96.329c0 11.92 4.13 20.21 12.388 24.871h130.14c6.247 0 7.657-2.486 8.49-5.973 4.516-18.897 4.516-39.596-1.118-57.2a21.66 21.66 0 007.111-25.857L143.976 1.254c-.558-1.326-2.336-1.56-3.218-.423l-5.092 6.574a6.196 6.196 0 01-4.897 2.399H116.62a6.195 6.195 0 01-4.927-2.44L106.648.74a1.88 1.88 0 00-3.192.329L89.205 31.63z"
              transform="translate(41 18)"
            />
            <path
              fill="url(#b)"
              fillRule="nonzero"
              d="M0 98.2c0 7.457 2.72 13.752 7.705 19.08 3.449 3.685 7.065 6.27 13.881 10.447.342.209 1.945 1.188 2.382 1.457 11.774 7.221 15.38 11.283 15.38 18.985a9 9 0 0018 0c0-8.788-2.945-16.137-8.417-22.303-3.896-4.388-8.044-7.421-15.552-12.026-.454-.279-2.062-1.26-2.388-1.46C20.818 106.144 18 103.132 18 98.2a9 9 0 00-18 0z"
              transform="translate(41 18)"
            />
            <path
              stroke="#98B9FF"
              strokeLinecap="square"
              strokeWidth="4"
              d="M161 76v-9a9 9 0 0118 0v41c0 16.569 13.431 30 30 30h91"
            />
            <path
              fill="#98B9FF"
              d="M157 76h8a2 2 0 012 2v8h-12v-8a2 2 0 012-2z"
            />
            <path
              fill="#195DEE"
              d="M159 86v3a1 1 0 01-2 0v-3h2zm6 0v3a1 1 0 01-2 0v-3h2z"
            />
            <path
              fill="#195DEE"
              fillRule="nonzero"
              d="M153.647 124.745c3.936 0 6.898 2.031 8.353 5.218 1.414-3.098 4.252-5.104 8.026-5.213l.326-.005H177a1 1 0 01.116 1.994l-.116.007-6.907.003c-4.28.121-6.984 3.256-7.09 7.825l-.003.282v4.344h-2v-4.344c0-4.721-2.726-7.983-7.094-8.107l-6.906-.003a1 1 0 110-2h6.647zM169 134.2a1 1 0 011 1v4h-2v-4a1 1 0 011-1zm7 0a1 1 0 011 1v4h-2v-4a1 1 0 011-1zm-28 0a1 1 0 011 1v4h-2v-4a1 1 0 011-1zm7 0a1 1 0 011 1v4h-2v-4a1 1 0 011-1z"
            />
            <path
              stroke="#195DEE"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M153.837 44.757s1.49 3.074 6.21 3.074 6.207-3.074 6.207-3.074m-2.401 10.982s.745 1.538 3.105 1.538 3.106-1.538 3.106-1.538.743 1.538 3.103 1.538c2.36 0 3.106-1.538 3.106-1.538m-2.402-10.982s1.489 3.074 6.209 3.074 6.209-3.074 6.209-3.074"
            />
            <g
              stroke="#195DEE"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            >
              <path d="M192.393 49.511l10.127-1.628m-10.146 8.925l10.166 1.37m-58.125-8.667l-10.127-1.628m10.146 8.925l-10.166 1.37" />
            </g>
          </g>
        </>
      );
    case IconName.Chat:
      return (
        <>
          <defs>
            <linearGradient
              id="icon_chat_a"
              x1="57.826%"
              x2="125.558%"
              y1="87.381%"
              y2="-36.77%"
            >
              <stop offset="0%" stopColor="#98B9FF" />
              <stop offset="100%" stopColor="#195DEE" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <path fill="#FFF" fillOpacity="0" d="M0 0H180V180H0z" />
            <path
              fill="url(#icon_chat_a)"
              d="M20 154c-5.523 0-10-4.477-10-10v-40c0-5.523 4.477-10 10-10h60c5.523 0 10 4.477 10 10v40c0 5.523-4.477 10-10 10H56v7.172c0 .53-.21 1.039-.586 1.414-.78.78-2.047.78-2.828 0L44 154H20z"
            />
            <path
              fill="#F3F4F8"
              d="M60 127c-5.523 0-10-4.477-10-10V47c0-5.523 4.477-10 10-10h100c5.523 0 10 4.477 10 10v70c0 5.523-4.477 10-10 10h-41l-14.586 14.586c-.78.78-2.047.78-2.828 0-.375-.375-.586-.884-.586-1.414V127H60z"
            />
            <path
              fill="#195DEE"
              fillRule="nonzero"
              d="M114.85 84.037c.532-.635 1.478-.719 2.113-.187s.719 1.478.187 2.113C115.466 87.974 113.21 89 110.5 89c-2.71 0-4.966-1.026-6.65-3.037-.532-.635-.448-1.581.187-2.113s1.581-.448 2.113.187C107.257 85.36 108.667 86 110.5 86c1.833 0 3.243-.641 4.35-1.963zM99 76c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2zm23 0c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2z"
            />
          </g>
        </>
      );
    case IconName.Megaphone:
      return (
        <g fill="none" fillRule="evenodd">
          <path
            stroke="#FFF"
            strokeWidth="2"
            d="M20 9h-2v6h2c.552 0 1.052-.224 1.414-.586.362-.362.586-.862.586-1.414v-2c0-.552-.224-1.052-.586-1.414C21.052 9.224 20.552 9 20 9zM18 3.804l-2.47 1.544C12.762 7.078 9.565 8 6 8c-1.105 0-2.105.448-2.828 1.172C2.448 9.895 2 10.895 2 12c0 1.11.454 2.116 1.217 2.838C3.984 15.563 5.056 16 6.283 16c3.27 0 6.474.919 9.247 2.652L18 20.196V3.804z"
          />
        </g>
      );
    case IconName.Message:
      return (
        <g fill="none" fillRule="evenodd">
          <path
            fill="#FFF"
            fillRule="nonzero"
            d="M19 4c1.657 0 3 1.343 3 3v9c0 1.657-1.343 3-3 3H5c-1.657 0-3-1.343-3-3V7c0-1.657 1.343-3 3-3h14zm1 4.566l-8 6.223-8-6.223V16c0 .513.386.936.883.993L5 17h14c.513 0 .936-.386.993-.883L20 16V8.566zM19 6H5c-.267 0-.51.105-.69.276L12 12.256l7.69-5.98c-.153-.146-.352-.244-.572-.27L19 6z"
          />
        </g>
      );
    case IconName.CancelMessage:
      return (
        <g fill="none" fillRule="evenodd">
          <path
            fill="#FFF"
            d="M19 3c1.657 0 3 1.343 3 3v10c0 1.657-1.343 3-3 3h-4l-3 3-3-3H5c-1.657 0-3-1.343-3-3V6c0-1.657 1.343-3 3-3h14zm0 2H5c-.513 0-.936.386-.993.883L4 6v10c0 .513.386.936.883.993L5 17h4.828L12 19.171 14.172 17H19c.513 0 .936-.386.993-.883L20 16V6c0-.513-.386-.936-.883-.993L19 5zm-5.232 2.818l1.414 1.414L13.414 11l1.768 1.768-1.414 1.414L12 12.414l-1.768 1.768-1.414-1.414L10.586 11 8.818 9.232l1.414-1.414L12 9.586l1.768-1.768z"
          />
        </g>
      );
    case IconName.Videocam:
      return (
        <g fill="none" fillRule="evenodd">
          <path
            fill="#FFF"
            fillRule="nonzero"
            d="M17.955 16.52l.019-.132 1.465 1.466c.282.28.663.439 1.061.439.828 0 1.5-.672 1.5-1.5V7.207c0-.398-.158-.78-.44-1.06l-.113-.104c-.59-.48-1.458-.446-2.008.103l-1.465 1.465-.019-.131C17.709 6.07 16.479 5 15 5H5C3.343 5 2 6.343 2 8v8c0 1.657 1.343 3 3 3h10c1.48 0 2.709-1.07 2.955-2.48zM5 7h10c.552 0 1 .448 1 1v8c0 .552-.448 1-1 1H5c-.552 0-1-.448-1-1V8c0-.552.448-1 1-1zm15 1.413v7.173L16.415 12 20 8.413z"
          />
        </g>
      );
    case IconName.Hourglass:
      return (
        <g fill="none" fillRule="evenodd">
          <path
            fill="#FFF"
            fillRule="nonzero"
            d="M5 22v-2h1.5v-3.115c0-.501.188-.981.522-1.348l.133-.132L10.9 12 7.155 8.595c-.417-.379-.655-.916-.655-1.48V4H5V2h14v2h-1.5v3.115c0 .564-.238 1.101-.655 1.48L13.1 12l3.745 3.405c.371.337.6.799.646 1.293l.009.187V20H19v2H5zm7-8.297l-3.5 3.182V20h7v-3.115L12 13.703zM15.5 4h-7v3.115l3.5 3.182 3.5-3.182V4z"
          />
        </g>
      );
    case IconName.Payout:
      return (
        <g fill="inherit" fillRule="evenodd">
          <path fill="none" d="M0 0h20v20H0z" />
          <path d="M10 2a8 8 0 110 16 8 8 0 010-16zm.5 3.5h-1v1h-1c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1h3v2h-4v1h2v1h1v-1h1c.55 0 1-.45 1-1v-2c0-.55-.45-1-1-1h-3v-2h4v-1h-2v-1z" />
        </g>
      );
    default:
      return null;
  }
};
