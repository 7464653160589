/**
 * Determines if the current browser is supported.
 *
 * Supported browsers are:
 *   - Google Chrome
 *
 * @returns {boolean} Supprted browser?
 */
export function isSupportedBrowser() {
  return isChrome();
}

/**
 * Determines if the current browser is Chrome.
 *   - https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser/9851769#9851769
 *
 * @returns {boolean} Chrome?
 */
function isChrome() {
  return !!(
    navigator.userAgent.match(
      /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9\.]+)(:?\s|$)/,
    ) &&
    // @ts-ignore
    window.chrome
  );
}
