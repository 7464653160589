import { computed } from "mobx";

import type { EventType as EventTypeSource } from "apis/common/models";
import Entity from "lib/models/Entity";

export { EventTypeSource };

export default class EventType extends Entity<EventTypeSource> {
  @computed
  get name(): string {
    return this.source.name || "";
  }
}
