import { inject, observer } from "mobx-react";
import {
  Redirect,
  Route,
  RouteProps,
  RouteComponentProps,
} from "react-router-dom";
import * as React from "react";

import NotFound from "components/NotFound";
import { PermissionDomain, PermissionType } from "lib/enums";

import { AuthStore } from ".";

interface PrivateRouteProps extends RouteProps {
  /**
   * The conditional permission domain for accessing this private route
   * If not provided, simply check for login
   *
   * @type {PermissionDomain}
   * @memberof PrivateRouteProps
   */
  permissionDomain?: PermissionDomain;
  /**
   * The conditional permission type for accessing this private route
   * defaults to PermissionType.Read
   *
   * @type {PermissionType}
   * @memberof PrivateRouteProps
   */
  permissionType?: PermissionType | PermissionType[];
  authStore?: AuthStore;
}

const PrivateRoute = inject("authStore")(
  observer(
    ({
      permissionDomain,
      permissionType = PermissionType.Read,
      authStore,
      component: Component,
      render: routeRender = () => <></>,
      ...rest
    }: PrivateRouteProps) => {
      const { isLoggedIn, checkPermissionAt } = authStore!;
      const render = Component
        ? (props: any) => <Component {...props} />
        : routeRender;

      // LOGGED IN BUT NO PERMISSION
      if (
        isLoggedIn &&
        permissionDomain &&
        !checkPermissionAt(permissionDomain, permissionType)
      ) {
        return <Route {...rest} component={NotFound} />;
      }

      // NOT LOGGED IN
      const renderFallback = (props: RouteComponentProps) => (
        <Redirect
          to={{
            pathname: "/login",
            state: {
              redirect: props.location.pathname + props.location.search,
            },
          }}
        />
      );

      return <Route {...rest} render={isLoggedIn ? render : renderFallback} />;
    },
  ),
);

export default PrivateRoute;
