import styled from "styled-components";
import * as Color from "color";
import * as React from "react";

import { Spinner as DotSpinner } from "components/Spinner";

const Wrapper = styled.div`
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${props =>
    Color(props.theme.gray100)
      .alpha(0.07)
      .toString()};
  backdrop-filter: blur(10px);
`;

const Message = styled.p`
  color: ${props => props.theme.white};
`;

interface SpinnerProps {
  message?: React.ReactNode | string;
}

const Spinner: React.SFC<SpinnerProps> = ({ message }) => (
  <Wrapper>
    <DotSpinner />
    {message && <Message>{message}</Message>}
  </Wrapper>
);

export default Spinner;
